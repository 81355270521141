import React from 'react';

import { MenuAction } from '../common';
import { MENU_ACTIONS_DIALOGS_CONFIG } from './MENU_ACTIONS_DIALOGS_CONFIG';

export function PurchaseMenuAction() {
  return (
    <MenuAction {...MENU_ACTIONS_DIALOGS_CONFIG.purchaseMenu} />
  );
}
