import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addCategory } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';

import CategoryFormContainer from './CategoryFormContainer';

const mapStateToProps = state => ({
  errors: state.categories.errors,
  category: {
    id: 0,
    name: '',
    position: 0,
  },
});

const mapDispatchToProps = {
  save: addCategory,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    CategoryFormContainer,
  ),
);
