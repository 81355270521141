import i18n from '../../i18n';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en';

/**
 * @param date {LocalDate}
 * @returns {Date}
 */
const _toJsDate = date => new Date(date.toString());

/**
 * @param {String} date
 * @return {string}
 */
const _formatUkrainianStringDate = date => {
  const [day, month, year] = date.split(' ');

  return `${day} ${month} ${year}`;
};

const _formatToLocalizedDate = (date, format, locale) => _toJsDate(date).toLocaleDateString(locale, format);

/**
 * @param {Date} date
 * @param {Object} options
 * @return {string}
 */
const _formatDate = (date, options) => date.toLocaleDateString(i18n.language, options);

// __________________________________________________________________________________________

export const formatDateForStickerReport = localDate => {
  const jsDate = _toJsDate(localDate);
  const options = {
    day: 'numeric',
    month: 'long',
  };

  return _formatDate(jsDate, options);
};

export const formatDateForMenuReportPrint = textDate => {
  return LocalDate.parse(textDate)
                  .format(DateTimeFormatter
                            .ofPattern('EEEE, d')
                            .withLocale(Locale.UK));
};

/**
 *
 * @param localDate {LocalDate} - date to format
 * @param type {('long'|'number')} - formatting type.
 *
 * @return {string} - formatted date
 */
export const formatDateForWeekPicker = (localDate, type) => {

  const options = {
    day: '2-digit',
    month: 'numeric',
    year: 'numeric',
  };
  const jsDate = _toJsDate(localDate);

  let result;
  if (type === 'long') {
    options.month = 'long';
    result = _formatDate(jsDate, options);

    if (i18n.language === 'uk') {
      result = _formatUkrainianStringDate(result);
    }
  }

  if (type === 'number') {
    result = _formatDate(jsDate, options);
  }

  return result;
};

export const getDateLabelForWeekTab = date => {
  const formatOptions = {
    weekday: 'short',
    month: 'short',
    day: '2-digit',
  };

  return _formatDate(_toJsDate(date), formatOptions);
};

export const formatDateForDailyOrderItem = date => {
  const DATE_FORMAT = {
    weekday: 'long',
    month: 'long',
    day: '2-digit',
  };

  return _formatToLocalizedDate(date, DATE_FORMAT, i18n.language);
};
