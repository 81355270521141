import { connect } from 'react-redux';
import SuccessNotifications from './SuccessNotifications';
import { clearSuccessStatus } from '../../../actions/luncherActions/successActions/successActions';

const mapStateToProps = state => ({
  success: state.success,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clearSuccessStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuccessNotifications);
