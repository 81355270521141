import React from 'react';
import { bool, func, number } from 'prop-types';
import { Box, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { ItemToolbar } from '../../ListItems/Templates';

import './Counter.scss';

function Counter(props) {
  return (
    <Box
      role="button"
      aria-disabled="true"
      tabIndex={0}
      onClick={e => e.stopPropagation()}
      onKeyPress={e => e.stopPropagation()}
      sx={props.count ? {} : { display: 'none' }}
      className="counter-toolbar">
      <ItemToolbar isFixed>
        <IconButton onClick={props.onDecrease} disabled={props.disabled} size="large">
          <RemoveIcon />
        </IconButton>
        <Typography>
          {props.count}
        </Typography>
        <IconButton onClick={props.onIncrease} disabled={props.disabled} size="large">
          <AddIcon />
        </IconButton>
      </ItemToolbar>
    </Box>
  );
}

Counter.propTypes = {
  count: number.isRequired,
  onDecrease: func.isRequired,
  onIncrease: func.isRequired,
  disabled: bool.isRequired,
};

export default Counter;
