import { connect } from 'react-redux';
import DeleteCategory from './DeleteCategoryButton';
import { selectDishesByCategory } from '../../../utils/selectors/dishSelectors';

const mapStateToProps = (state, ownProps) => ({
  hasCategoryDishes: selectDishesByCategory(state, ownProps.category).length !== 0,
});

export default connect(mapStateToProps)(DeleteCategory);

