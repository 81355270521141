/* eslint-disable */
import { connect } from 'react-redux';
import AuthenticationController from './AuthenticationController';
import { hasLoginError } from '../../utils/selectors';
import { clearLoginError } from '../../actions/luncherActions/errorActions/errorActions';
import { checkAuthentication } from '../../store/auth';

const mapStateToProps = state => ({
  tryingToLogIn: state.authentication.tryingToLogIn,
  isLoadingFailed: hasLoginError(state),
});

const mapDispatchToProps = dispatch => {
  const startLogInProcedures = () => {
    dispatch(clearLoginError());
    dispatch(checkAuthentication());
  };
  return {
    startLogInProcedures,
    load: startLogInProcedures,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationController);
