import { connect } from 'react-redux';

import SideMenu from './SideMenu';

import { closeSideMenu, openSideMenu, saveSideMenuStateToLocalStorage, } from '../../store/components';
import { hasAnyRole, hasEaterRole, hasFoodlordRole } from '../../store/auth';
import { selectCurrentPath, selectIsNotFoundPage } from '../../utils/selectors/locationSelectors';

const mapStateToProps = state => ({
  hasAnyRole: hasAnyRole(state),
  hasFoodlordRole: hasFoodlordRole(state),
  hasEaterRole: hasEaterRole(state),
  isNotFoundPage: selectIsNotFoundPage(state),
  currentPath: selectCurrentPath(state),
  // TODO move to selector
  isSideMenuOpen: state.components.sideMenu.isSideMenuOpen,
});

const mapDispatchToProps = dispatch => ({
  openSideMenu: () => dispatch(openSideMenu()),
  closeSideMenu: () => dispatch(closeSideMenu()),
  saveSideMenuState: isOpen => dispatch(saveSideMenuStateToLocalStorage(isOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
