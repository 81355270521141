const eaterRouteMask = ['/order*'];

const eaterPath = {
  ORDER: '/order',
};

const eaterRoutes = {
  LAST_ORDER: `${eaterPath.ORDER}`,
  ORDER: `${eaterPath.ORDER}/:date`,
};

export {
  eaterRouteMask,
  eaterPath,
  eaterRoutes,
};
