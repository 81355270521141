import { successActionTypes } from '../actions/luncherActions/successActions/successActions';

const initialState = {
  status: null,
};

export default function successReducer(state = initialState, action) {
  switch (action.type) {
    case successActionTypes.SET_SUCCESS_STATUS:
      return {
        ...state,
        status: action.payload,
      };

    case successActionTypes.CLEAR_SUCCESS_STATUS:
      return {
        ...state,
        status: null,
      };

    default:
      return state;
  }
}
