import React from 'react';
import { string } from 'prop-types';

import { ItemCaption } from '../../Templates';

export default function DishDescription({ text, ...rest }) {
  return (
    <ItemCaption id="dish-description" {...rest}>
      {text}
    </ItemCaption>
  );
}

DishDescription.propTypes = {
  text: string.isRequired,
};
