import React from 'react';

import { Grid } from '@mui/material';

const styles = {
  root: {
    flexGrow: 1,
    height: 'inherit',
    minHeight: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

export function MessageContainer(props) {
  return (
    <Grid
      container
      sx={styles.root}>
      {props.children}
    </Grid>
  );
}

export default MessageContainer;
