import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';

import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const styles = {
  fab: {
    position: 'fixed',
    bottom: 1,
    right: theme => theme.spacing(1),
  },
};

function AddButton(props) {
  const { label, href } = props;

  return (
    <Link to={href}>
      <Tooltip title={label} aria-label={label}>
        <Fab
          color="primary"
          sx={styles.fab}>
          <AddIcon />
        </Fab>
      </Tooltip>
    </Link>
  );
}

AddButton.propTypes = {
  label: string.isRequired,
  href: string.isRequired,
};

export default AddButton;
