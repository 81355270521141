import { compareOrderItemsByPosition, lastDateComparator } from '../../utils/comparators';
import { areDateRangesEqual, getDateRangeFromEntity, isDateFromDateRange } from '../../utils/dates';
import { getOrderItemForMatchingMenuItem } from './orderSelectors';

// ___SUM_REDUCER_______________________________________________________________________________________

const _dailyOrderSumReducer = (sum, dayOrderItem) => (sum + dayOrderItem.count * dayOrderItem.menuItem.food.price);
export const getDailyOrderTotal = dailyOrder => dailyOrder.orderItems.reduce(_dailyOrderSumReducer, 0);

const _periodOrderSumReducer = (sum, dailyOrder) => (sum + getDailyOrderTotal(dailyOrder));
export const getPeriodOrderTotal = periodOrder => periodOrder.dailyOrders.reduce(_periodOrderSumReducer, 0);

// ___SORTING_______________________________________________________________________________________

export const sortDailyOrderItemsByPosition = dailyOrders => {
  return dailyOrders.map(({ orderItems = [], ...restDO }) => ({
                           ...restDO,
                           orderItems: [...orderItems].sort(compareOrderItemsByPosition),
                         })
  );
};

export const sortPeriodOrdersByLastDate = periodOrders => [...periodOrders].sort(lastDateComparator);

// ___FINDING_______________________________________________________________________________________

export const findOrderByDateRange = (orders, { firstDate, lastDate }) => {
  return orders.find(order => areDateRangesEqual(getDateRangeFromEntity(order), { firstDate, lastDate }));
};

export const findDailyOrderById = (dailyOrders, DO) => {
  return dailyOrders.find(order => order.id === DO.id);
};

/**
 * @param periodOrders {PeriodOrder[]}
 * @param date {LocalDate}
 * @return {PeriodOrder || undefined}
 */
export const findPeriodOrderByDate = (periodOrders, date) => {
  return periodOrders.find(order => isDateFromDateRange(date, getDateRangeFromEntity(order)));
};

/**
 * @param menuItems {MenuItem[]}
 * @param dailyOrder {DailyOrder}
 * @return {boolean}
 */
export const findIsAnyItemForCategoryOrdered = (menuItems, dailyOrder) => {
  return Boolean(
    menuItems
      .reduce((acc, menuItem) => {
        acc = acc + Boolean(getOrderItemForMatchingMenuItem(dailyOrder, menuItem));
        return acc;
      }, 0)
  );
};
