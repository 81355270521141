import React from 'react';
import { func, object, oneOfType, string } from 'prop-types';

import Notifications from '../Notifications';

import { errorsByStatusCode } from '../../../constants';

function ErrorNotifications({ status, clear }) {
  return status && (
      <Notifications
        clear={clear}
        text={`service-error.${status}` || `snackbar-errors.${errorsByStatusCode[status]}`}
        variant="error" />
    )
}

ErrorNotifications.propTypes = {
  status: oneOfType([string, object]),
  clear: func
};

export default ErrorNotifications;
