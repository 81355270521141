import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';

import { Button, Grid, Typography, } from '@mui/material';
import { DailyOrderItems } from '../DailyOrderItems';

import { asPriceWithTwoDecimals } from '../../../../../../../utils/numberUtils';
import { getPeriodOrderTotal } from '../../../../../../../store/order';
import { periodOrderType } from '../../../../../../../constants';

const styles = {
  totalPrice: {
    margin: 2,
    marginTop: 0,
    fontSize: '18px',
    textAlign: 'center',
    cursor: 'default',
  },
  orderButton: {
    width: 'calc(100% - 20px)',
    margin: '5px auto',
  },
  buttonContainer: {
    marginTop: 1,
  },
};

// TODO move to Buttons/Mobile/MobileOrderButton module
function OrderButton({ buttonText, isDisabled, onClick }) {
  return (
    <Grid container sx={styles.buttonContainer}>
      <Button
        color="primary"
        onClick={onClick}
        variant="contained"
        disabled={isDisabled}
        sx={styles.orderButton}>
        {buttonText}
      </Button>
    </Grid>
  );
}

OrderButton.propTypes = {
  buttonText: string.isRequired,
  onClick: func,
  isDisabled: bool,
};

OrderButton.defaultProps = {
  onClick: () => '',
  isDisabled: false,
};

function PeriodOrderBillPanel(props) {
  const { order, isDisabled, isModified, isOrdered, orderMenu, reorderMenu, } = props;
  const { t: localize } = useTranslation();

  function localizeLabel(label) {
    return localize(`label.${label}`);
  }

  function renderButton() {
    if (isOrdered && isModified) {
      return (
        <OrderButton
          buttonText={localizeLabel('reorder')}
          onClick={reorderMenu}
          isDisabled={isDisabled} />
      );
    }
    if (isOrdered && !isModified) {
      return (
        <OrderButton
          buttonText={localizeLabel('ordered')}
          isDisabled />
      );
    }

    return (
      <OrderButton
        buttonText={localizeLabel('order')}
        onClick={orderMenu}
        isDisabled={isDisabled} />
    );
  }

  const periodTotalPrice = getPeriodOrderTotal(order);

  return (
    <>
      <Typography id="total-period-order-price" sx={styles.totalPrice}>
        {`${localize('period-order-total')}: ₴
          ${asPriceWithTwoDecimals(periodTotalPrice)}`}
      </Typography>

      <DailyOrderItems />

      {renderButton()}
    </>
  );
}

PeriodOrderBillPanel.propTypes = {
  order: periodOrderType.isRequired,
  isDisabled: bool.isRequired,
  isOrdered: bool.isRequired,
  isModified: bool.isRequired,
  orderMenu: func.isRequired,
  reorderMenu: func.isRequired,
};

export default PeriodOrderBillPanel;
