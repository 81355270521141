import { connect } from 'react-redux';

import Header from './Header';

import { selectCurrentPageTitle } from '../../utils/selectors/locationSelectors';

const mapStateToProps = state => ({
  pageTitle: selectCurrentPageTitle(state),
});

const mergeProps = propsFromState => ({
  ...propsFromState,
  onHeaderClick: event => {
    event.preventDefault();
    window.location.href = '/';
  },
});

export default connect(mapStateToProps, null, mergeProps)(Header);
