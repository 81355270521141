import { connect } from 'react-redux';
import ReportsSubmenu from './ReportsSubmenu';
import { hasAnyRole, hasFoodlordRole } from '../../../store/auth';

const mapStateToProps = state => ({
  hasAnyRole: hasAnyRole(state),
  hasFoodlordRole: hasFoodlordRole(state),
});

export default connect(mapStateToProps)(ReportsSubmenu);
