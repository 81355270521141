import React from 'react';

import { Box } from '@mui/material';

function ItemCaption({ children, ...rest }) {
  return (
    <Box id="item-caption"
         className="l-item-caption" {...rest}>
      {children}
    </Box>
  );
}

export default ItemCaption;
