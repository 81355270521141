import React from 'react';
import { bool } from 'prop-types';

import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

const getStyles = theme => ({
  drawerOpen: {
    width: '280px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: '60px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
  },
});

const StyledDesktopDrawer = styled(Drawer)(({ theme, ...props }) => {
  const styles = getStyles(theme);

  return ({
    display: 'flex',
    height: 'inherit',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    ...(props.open ? styles.drawerOpen : styles.drawerClose),
    '& .MuiDrawer-paper': {
      display: 'flex',
      height: 'inherit',
      top: 'auto',
      paddingBottom: 0,
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      borderRadius: 4,
      ...(props.open ? styles.drawerOpen : styles.drawerClose),
    },
  });
});

export default function DesktopDrawer({ children, open }) {
  return (
    <StyledDesktopDrawer
      variant="permanent"
      open={open}
      elevation={1}>
      {children}
    </StyledDesktopDrawer>
  );
}

DesktopDrawer.propTypes = {
  open: bool.isRequired,
};
