import { connect } from 'react-redux';

import EaterRouter from './EaterRouter';
import { hasEaterRole } from '../../store/auth';

const mapStateToProps = state => ({
  isEater: hasEaterRole(state),
});

export default connect(mapStateToProps)(EaterRouter);
