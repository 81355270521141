import React, { Component } from 'react';
import { arrayOf, bool, func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { Paper } from '@mui/material';

import { NotFoundPage } from '../../Errors/NotFoundPage';
import { AddButton } from '../../../Buttons';
import { LoadingSpinnerProvider } from '../../../LoadingSpinnerProvider';
import { CategoriesList } from '../../../Lists/CategoriesList';
import { AddCategoryForm, EditCategoryForm } from '../../../Forms/CategoryForm';

import NotFoundPageRoute from '../../../../routes/NotFoundPageRoute';
import { foodlordRoutes } from '../../../../routes/foodlord/foodlordRoutes';
import { withConnectionErrorHandler } from '../../../../utils/HOCs';
import { categoryType } from '../../../../constants';

class Categories extends Component {
  componentDidMount() {
    this.props.load();
  }

  isValidId(id) {
    return this.props.categories.some(category => category.id === Number(id));
  }

  renderAddCategoryPage = () => (
    <>
      {this.renderCategoriesPage()}
      <AddCategoryForm href={foodlordRoutes.CATEGORIES_LIST} />
    </>
  );

  renderCategoriesPage = () => {
    const { t: localize } = this.props;

    return (
      <Paper className="flex-container scrollable">
        <CategoriesList categories={this.props.categories} />
        <AddButton
          href={foodlordRoutes.ADD_CATEGORY}
          label={localize('label.add-category')} />
      </Paper>
    );
  };

  renderEditPage = () => (
    <>
      {this.renderCategoriesPage()}
      <EditCategoryForm href={foodlordRoutes.CATEGORIES_LIST} />
    </>
  );

  render() {
    // TODO refactor EDIT_CATEGORY route (remove direct dependency on Not Found Page)
    return (
      <LoadingSpinnerProvider isLoading={this.props.isLoading}>
        <Switch>
          <Route
            exact
            path={foodlordRoutes.ADD_CATEGORY}
            render={this.renderAddCategoryPage} />
          <Route
            exaсt
            path={foodlordRoutes.EDIT_CATEGORY}
            render={({ match }) => (
              this.isValidId(match.params.id)
              ? this.renderEditPage()
              : <NotFoundPage />
            )} />
          <Route
            exact
            path={foodlordRoutes.CATEGORIES_LIST}
            render={this.renderCategoriesPage} />
          <NotFoundPageRoute />
        </Switch>
      </LoadingSpinnerProvider>
    );
  }
}

Categories.propTypes = {
  categories: arrayOf(categoryType).isRequired,
  isLoading: bool.isRequired,
  load: func.isRequired,
};

export default withConnectionErrorHandler(withTranslation()(Categories));
