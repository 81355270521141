const foodlordRoot = '/foodlord';

const foodlordPath = {
  EDIT_DISH: `${foodlordRoot}/dishes/edit`,
  EDIT_CATEGORY: `${foodlordRoot}/categories/edit`,
  MENU: `${foodlordRoot}/menu`,
  VENDOR_REPORT: `${foodlordRoot}/report/vendor`,
  SLOWPOKE_REPORT: `${foodlordRoot}/report/slowpoke`,
};

const foodlordParams = {
  CATEGORY: 'category',
};

const foodlordRoutes = {
  DISH_LIST: `${foodlordRoot}/dishes`,
  ADD_DISH: `${foodlordRoot}/dishes/new`,
  EDIT_DISH: `${foodlordPath.EDIT_DISH}/:id`,
  CATEGORIES_LIST: `${foodlordRoot}/categories`,
  ADD_CATEGORY: `${foodlordRoot}/categories/new`,
  EDIT_CATEGORY: `${foodlordPath.EDIT_CATEGORY}/:id`,
  MENU: `${foodlordPath.MENU}/:date`,
  LAST_MENU: `${foodlordPath.MENU}`,
  VENDOR_LIST: `${foodlordRoot}/vendors`,
  VENDOR_REPORT: `${foodlordPath.VENDOR_REPORT}/:date`,
  LAST_VENDOR_REPORT: `${foodlordPath.VENDOR_REPORT}`,
  SLOWPOKE_REPORT: `${foodlordPath.SLOWPOKE_REPORT}/:date`,
  LAST_SLOWPOKE_REPORT: `${foodlordPath.SLOWPOKE_REPORT}`,
};

export {
  foodlordRoot,
  foodlordPath,
  foodlordRoutes,
  foodlordParams,
};
