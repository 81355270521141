import React from 'react';
import { string } from 'prop-types';

import {
  MenuStatusWithIconAndLabel,
  StatusSubheaderUpperDivider,
  TransparentStatusBottomDivider
} from './MenuStatusComponents';

export function PeriodMenuStatus({ status }) {
  return (
    <>
      <StatusSubheaderUpperDivider />

      <MenuStatusWithIconAndLabel status={status} />

      <TransparentStatusBottomDivider status={status} />
    </>
  );
}

PeriodMenuStatus.propTypes = { status: string.isRequired };
