import { connect } from 'react-redux';

import UsersAutocompleteSearch from './UsersAutocompleteSearch';

import {
  fetchUserNames,
  saveUserFilter,
  selectUsersFilter,
  selectUsersIsLoading,
  selectUsersNames
} from '../../../store/users';

const mapStateToProps = state => ({
  users: selectUsersNames(state),
  isLoading: selectUsersIsLoading(state),
  filteredUsers: selectUsersFilter(state),
});

const mapDispatchToProps = {
  load: fetchUserNames,
  saveFilter: saveUserFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAutocompleteSearch);
