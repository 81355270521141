import React from 'react';
import { bool } from 'prop-types';
import { Switch } from 'react-router-dom';

import NotFoundPageRoute from '../NotFoundPageRoute';

import { generateEaterRoutes } from '../../utils/routeUtils/RouteGenerators';

function EaterRouter(props) {
  return props.isEater ? (
    <Switch>
      {generateEaterRoutes()}
      <NotFoundPageRoute />
    </Switch>
  ) : (<NotFoundPageRoute />);
}

EaterRouter.propTypes = {
  isEater: bool.isRequired,
};

export default EaterRouter;
