import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';

import { LoadingSpinnerProvider } from '../LoadingSpinnerProvider';
import AuthorizationController from '../Authorization/AuthorizationControllerContainer';
import { withConnectionErrorHandler } from '../../utils/HOCs';

function AuthenticationController(props) {
  const { startLogInProcedures, tryingToLogIn } = props;

  useEffect(() => {
    if (!tryingToLogIn) {
      startLogInProcedures();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpinnerProvider isLoading={tryingToLogIn}>
      <AuthorizationController />
    </LoadingSpinnerProvider>
  );
}

AuthenticationController.propTypes = {
  tryingToLogIn: bool.isRequired,
  startLogInProcedures: func.isRequired,
};

export default withConnectionErrorHandler(AuthenticationController);
