import React, { useState } from 'react';

import { Grid, Typography } from '@mui/material';

import Tooltip from '../../Tooltip/Tooltip';

import { dailyOrderItemType } from '../../../constants';
import { asPriceWithTwoDecimals } from '../../../utils/numberUtils';

const styles = {
  grid: {
    // todo test if it works ok
    margin: theme => `${theme.spacing(1)} 0`,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  cursorDefault: {
    cursor: 'default',
  },
};

function DailyOrderDishItem(props) {
  const [open, setOpen] = useState(false);
  const closeTooltip = () => setOpen(false);
  const openTooltip = () => setOpen(true);

  const { orderItem } = props;
  const price = asPriceWithTwoDecimals(orderItem.count * orderItem.menuItem.food.price);

  return (
    <Grid container
          direction="row"
          sx={styles.grid}>
      <Grid item xs={7}>
        <Tooltip onClose={closeTooltip}
                 open={open}
                 title={orderItem.menuItem.food.name}>
          <Typography noWrap
                      color="textSecondary"
                      sx={styles.cursorPointer}
                      onClick={openTooltip}
                      onMouseEnter={openTooltip}>
            {orderItem.menuItem.food.name}
          </Typography>
        </Tooltip>
      </Grid>
      <Grid item xs={1}>
        <Typography color="textSecondary"
                    sx={styles.cursorDefault}>
          {orderItem.count > 1 ? ` x ${orderItem.count} ` : ''}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography noWrap
                    align="right"
                    color="textSecondary"
                    sx={styles.cursorDefault}>
          {`₴ ${price}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

DailyOrderDishItem.propTypes = {
  orderItem: dailyOrderItemType.isRequired,
};

export default DailyOrderDishItem;
