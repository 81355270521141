import React from 'react';
import { func } from 'prop-types';
import { Grid, Paper, } from '@mui/material';

import { WeekPicker } from './WeekPicker';

import { componentType, dateType } from '../../constants';
import { weekPickerStyles } from './weekPickerStyles';

export default function WeekPickerToolbar({ RightControl, date, onDateChange }) {
  return (
    <Paper id="week-picker-paper-root" sx={weekPickerStyles.weekPickerPaperRoot}>
      <Grid container direction="row" justifyContent="space-between" wrap="nowrap" alignItems="center">
        <Grid item />
        <Grid item>
          <WeekPicker date={date} onDateChange={onDateChange} />
        </Grid>
        <Grid item>
          <Grid container>
            <RightControl />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

WeekPickerToolbar.defaultProps = {
  RightControl: () => '',
};

WeekPickerToolbar.propTypes = {
  date: dateType.isRequired,
  onDateChange: func.isRequired,
  RightControl: componentType,
};

