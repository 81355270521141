import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DishesPage from './Dishes';

import { fetchDishes } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import { fetchCategories } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';
import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';

import { isAnyOfEntitiesLoading, isLoadingEntitiesFailedDueToConnectionError } from '../../../../utils/selectors';
import { getCategoryFromQueryParams } from '../../../../utils/selectors/categorySelectors';
import { selectDishes } from '../../../../utils/selectors/dishSelectors';
import { generateUrlWithCategoryFilter } from '../../../../utils/UrlUtils';
import { foodlordRoutes } from '../../../../routes/foodlord/foodlordRoutes';

const mapStateToProps = (state, ownProps) => ({
  dishes: selectDishes(state),
  selectedCategoryId: getCategoryFromQueryParams(state, ownProps.location.search).id,
  // TODO move to selector
  isLoading: isAnyOfEntitiesLoading(state, 'dishes', 'categories'),
  isLoadingFailed: isLoadingEntitiesFailedDueToConnectionError(state, 'dishes', 'categories'),
});

const mapDispatchToProps = dispatch => ({
  load: () => {
    dispatch(clearGeneralError());
    dispatch(fetchCategories());
    dispatch(fetchDishes());
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { dishes, selectedCategoryId } = propsFromState;

  return ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps,
    isValidDishId: id => dishes.some(dish => dish.id === Number(id)),
    dishListUrl: (
      generateUrlWithCategoryFilter(
        selectedCategoryId,
        foodlordRoutes.DISH_LIST,
      )
    ),
    addNewDishUrl: (
      generateUrlWithCategoryFilter(
        selectedCategoryId,
        foodlordRoutes.ADD_DISH,
      )
    ),
  });
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(DishesPage),
);
