import React from 'react';
import { bool, func } from 'prop-types';

import { Grid, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

import { MenuReportPanel } from './MenuReportPanel';
import { MenuReportCards } from './MenuReportCards';
import { DayPicker } from '../../../DayPicker';

import { getPagesResponsiveHeights, themePresets } from '../../../../constants';

const FlexColumnGrid = styled(Grid)(() => ({
  ...themePresets.flexColumnContainer,
  height: 'inherit',
  width: 'inherit'
}));

const MenuReportRootGrid = styled(Grid)(({ theme }) => ({
  ...getPagesResponsiveHeights(theme),
  display: 'flex',
  flex: '1',
  minHeight: 0,
}));

function MenuReportPanelWrapper(props) {
  return props.isWideScreen ? (
    <FlexColumnGrid
      id="menu-report-panel-wrapper-grid"
      item
      md={3} sm={3}>
      {props.children}
    </FlexColumnGrid>
  ) : (<>{props.children}</>);
}

MenuReportPanelWrapper.propTypes = {
  isWideScreen: bool.isRequired,
};

export default function MenuReport(props) {
  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const { isOpen, handleDateChange } = props;

  return (
    <MenuReportRootGrid
      id="menu-report-grid-root"
      container
      spacing={isWideScreen ? 1 : 0}
      direction={isWideScreen ? 'row' : 'column-reverse'}>

      <FlexColumnGrid
        id="day-picker-grid-wrapper"
        item
        md={isOpen ? 9 : 12}
        sm={isOpen ? 9 : 12}
        xs={12}>

        <DayPicker handleDateChange={handleDateChange}>

          <MenuReportCards />
        </DayPicker>
      </FlexColumnGrid>
      {
        isOpen && (
          <MenuReportPanelWrapper isWideScreen={isWideScreen}>
            <MenuReportPanel isWideScreen={isWideScreen} />
          </MenuReportPanelWrapper>
        )
      }
    </MenuReportRootGrid>
  );
}

MenuReport.propTypes = {
  handleDateChange: func.isRequired,
  isOpen: bool.isRequired,
};
