import React, { useCallback, useEffect } from 'react';
import { bool, func, string } from 'prop-types';

import { List, useMediaQuery } from '@mui/material';

import { MenuLink } from './MenuLink';
import { ReportsSubmenu } from './ReportsSubmenu';
import { Drawer } from '../Drawers';
import { Divider } from '../Divider';
import { EmptyComponent } from '../../utils/HOCs';

import {
  getEaterSideMenuRoutes,
  getFoodlordSideMenuRoutes,
  getOpenSideMenuRoutes,
} from '../../utils/selectors/routeSelectors';

const menuItems = {
  foodlord: getFoodlordSideMenuRoutes(),
  eater: getEaterSideMenuRoutes(),
  open: getOpenSideMenuRoutes(),
};

/** Creates context with currentPath & isNotFoundPage for render method
 *
 * @param {String} currentPath
 * @param {Boolean} isNotFoundPage
 * @return {function(*=, *): JSX.Element[]}
 */
const makeRenderMenuLinksWithLocationContext = (currentPath, isNotFoundPage) => (routeConfigs, onClick) => (
  Object
    .entries(routeConfigs)
    .map(
      ([link, routeConfig]) => {
        const isActiveMenuLink = !isNotFoundPage && currentPath.includes(link);

        return (
          <MenuLink
            key={link}
            isActive={isActiveMenuLink}
            routeConfig={routeConfig}
            onClick={onClick} />
        );
      },
    ));

function SideMenu(props) {
  const {
    hasAnyRole,
    hasEaterRole,
    hasFoodlordRole,
    closeSideMenu,
    openSideMenu,
    isSideMenuOpen,
    saveSideMenuState,
    currentPath,
    isNotFoundPage,
  } = props;
  const matchesBigScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const handleMobileDrawerClose = !matchesBigScreen ? closeSideMenu : EmptyComponent;

  // FIXME fix warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderMenuLinks = useCallback(makeRenderMenuLinksWithLocationContext(currentPath, isNotFoundPage),
                                      [currentPath, isNotFoundPage]);

  function renderList(items, withDivider) {
    return (
      <>
        <List>
          {renderMenuLinks(items, handleMobileDrawerClose)}
        </List>
        {withDivider && <Divider />}
      </>
    );
  }

  useEffect(() => {
    matchesBigScreen && saveSideMenuState(isSideMenuOpen);
  }, [saveSideMenuState, matchesBigScreen, isSideMenuOpen]);

  return (
    <Drawer
      isBigScreen={matchesBigScreen}
      closeDrawer={closeSideMenu}
      isOpen={isSideMenuOpen}
      openDrawer={openSideMenu}>

      {hasFoodlordRole && renderList(menuItems.foodlord, true)}
      {hasEaterRole && renderList(menuItems.eater, true)}
      {
        hasAnyRole && (
          <ReportsSubmenu renderMenuLinks={items => renderMenuLinks(items, handleMobileDrawerClose)} />
        )
      }
      <Divider />
      {renderList(menuItems.open)}
    </Drawer>
  );
}

SideMenu.propTypes = {
  hasFoodlordRole: bool.isRequired,
  hasAnyRole: bool.isRequired,
  hasEaterRole: bool.isRequired,
  isSideMenuOpen: bool.isRequired,
  openSideMenu: func.isRequired,
  closeSideMenu: func.isRequired,
  saveSideMenuState: func.isRequired,
  isNotFoundPage: bool.isRequired,
  currentPath: string.isRequired,
};

export default SideMenu;
