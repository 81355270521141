import SettingsPage from '../../components/pages/Common/Settings';

import SettingsIconSvg from '../../images/menuIcons/settings-figma.svg';

import { openRoutes } from '../openRoutes';

const OpenPagesConfig = {
  [openRoutes.SETTINGS]: {
    link: openRoutes.SETTINGS,
    component: SettingsPage,
    title: 'routes.settings',
    icon: SettingsIconSvg,
    displayInSideMenu: true,
  },
};

export default OpenPagesConfig;
