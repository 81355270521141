import { connect } from 'react-redux';

import { MenuActions } from './MenuActions';
import { CreateMenuAction } from './period';

import {
  isDayAddableToMenu,
  isDayRemovableFromMenu,
  isMenuDraft,
  isMenuPublished,
  isMenuPurchased
} from '../../../../../../store/menu';
import { noMenu, withAlternative } from '../../../../../../utils/HOCs';
import { selectActiveDate } from '../../../../../../store/activeState';

const mapStateToProps = (state, { menu }) => ({
  isDraft: () => isMenuDraft(menu),
  isPublished: () => isMenuPublished(menu),
  isPurchased: () => isMenuPurchased(menu),
  isDayAddable: () => isDayAddableToMenu(menu, selectActiveDate(state)),
  isDayRemovable: () => isDayRemovableFromMenu(menu, selectActiveDate(state)),
});

export default connect(mapStateToProps)(
  withAlternative(noMenu, CreateMenuAction)(
    MenuActions
  )
);

