import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import translationEng from './locales/en/translation.json';
import translationUk from './locales/uk/translation.json';
import { FALLBACK_LANGUAGE, getDefaultLanguage } from './utils/localizationUtils';

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
          // debug: true,
          resources: {
            en: {
              translations: translationEng,
            },
            uk: {
              translations: translationUk,
            },
          },

          lng: getDefaultLanguage(),
          fallbackLng: FALLBACK_LANGUAGE,

          ns: ['translations'],
          defaultNS: 'translations',

          keySeparator: '.',

          interpolation: {
            escapeValue: false,
            formatSeparator: ',',
          },

          react: {
            bindI18n: 'languageChanged',
            bindI18nStore: '',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
            useSuspense: true,
          },
        });

export default i18n;
