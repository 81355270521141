import { authenticationActionTypes } from './actions/authentication/authenticationActions';

const initialState = {
  tryingToLogIn: false,
};

export default function loginReducer(state = initialState, action) {
  switch (action.type) {
    case authenticationActionTypes.START_LOG_IN_PROCEDURE:
      return {
        ...state,
        tryingToLogIn: true,
      };
    case authenticationActionTypes.STOP_LOG_IN_PROCEDURE:
      return {
        ...state,
        tryingToLogIn: false,
      };
    default:
      return state;
  }
}
