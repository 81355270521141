import React from 'react';
import { bool, shape, string } from 'prop-types';

import { Box } from '@mui/material';

function defineStyleClasses(isSelectable) {
  const styles = ['l-item-content'];

  if (isSelectable) {
    styles.push('selectable');
  }

  return styles.join(' ');
}

function ItemContent({
                       children,
                       isSelectable,
                       renderOptionProps,
                       id,
                       ...restProps
                     }) {
  return (
    <Box id={id || 'item-content'}
         data-test-id={id}
         className={defineStyleClasses(isSelectable)}
         {...restProps}
         {...renderOptionProps} >
      {children}
    </Box>
  );
}

ItemContent.propTypes = {
  isSelectable: bool,
  /**
   * used for correct Option rendering in MUI MenuItemAutosuggest
   * (check `renderOptionProps` prop API of MenuItemAutosuggest)
   */
  renderOptionProps: shape({}),
  id: string
};

ItemContent.defaultProps = {
  isSelectable: false,
  renderOptionProps: {}
};

export default ItemContent;
