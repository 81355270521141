import React from 'react';

import { useMediaQuery } from '@mui/material';

import { DayPicker } from '../../../DayPicker';
import { PeriodMenuToolbar } from './components/PeriodMenuToolbar';
import {
  StyledContentGrid,
  StyledRootContainer,
  StyledToolbarContainer
} from './components/PeriodMenuStyledComponents';

import { dateToUrlSetter } from '../../../../utils/dates';

import { foodlordPath } from '../../../../routes/foodlord/foodlordRoutes';
import { DailyMenuContent } from './components/DailyMenuContent';

function PeriodMenu() {
  const isWideScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <StyledRootContainer id="period-menu-root"
                         container spacing={isWideScreen ? 1 : 0} direction={isWideScreen ? 'row' : 'column-reverse'}>

      <StyledContentGrid id="day-picker-and-daily-menu-container"
                         item xs={12} sm={9}>

        <DayPicker id="period-menu-day-picker"
                   handleDateChange={dateToUrlSetter(foodlordPath.MENU)}>

          <DailyMenuContent id="period-menu-daily-menu-content" />
        </DayPicker>
      </StyledContentGrid>

      <StyledToolbarContainer id="period-menu-toolbar-container"
                              item xs={12} sm={3}>
        <PeriodMenuToolbar />
      </StyledToolbarContainer>
    </StyledRootContainer>
  );
}

PeriodMenu.props = {};

export default PeriodMenu;
