import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { AppBar, CssBaseline, Grid, Toolbar, } from '@mui/material';

import { ServiceSwitcher } from '@intelliarts/react-components';

import { UserMenu } from '../UserMenu';
import { AppName, PageTitle } from './HeaderComponents';
import { SideMenuButton } from '../SideMenu/SideMenuButton';
import axiosInstance from '../../axiosInstance';

const styles = {
  appBar: {
    backgroundColor: theme => theme.palette.primary.main,
    color: theme => theme.palette.primary.contrastText,
    height: 'inherit',
    maxHeight: 'inherit',
    display: 'flex',
    padding: 0,
    zIndex: theme => theme.zIndex.drawer + 1,
    transition: theme => theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
};

function Header(props) {
  const { pageTitle, onHeaderClick } = props;
  const { t: localize } = useTranslation();

  return (
    <AppBar position="fixed" sx={styles.appBar}>
      <CssBaseline />
      <Toolbar variant="dense">
        <SideMenuButton />

        <Grid
          id="page-and-app-title-grid-container"
          container>
          <PageTitle title={localize(pageTitle)} />
          <AppName
            onClick={onHeaderClick}
            appName={localize('application-name')} />
        </Grid>
        <ServiceSwitcher axiosInstance={axiosInstance} />
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  pageTitle: string.isRequired,
  onHeaderClick: func.isRequired,
};

export default Header;
