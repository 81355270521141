import React from 'react';
import { Route } from 'react-router-dom';

import NotFoundPageConfig from './applicationPages/notFoundPageConfig';

export default function NotFoundPageRoute() {
  return (
    <Route component={NotFoundPageConfig.component} />
  );
}
