import React, { useEffect, useState } from 'react';
import { Redirect, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bool, func } from 'prop-types';

import { LoadingSpinnerProvider } from '../../LoadingSpinnerProvider';
import { dateType } from '../../../constants';
import { selectActiveDateRange } from '../../../store/activeState';

export default function LatestVersionLoader(props) {
  const { date, isLoading, load } = props;
  const activeDateRange = useSelector(selectActiveDateRange);
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    load(activeDateRange, date);
    setInitializing(false);
    // eslint-disable-next-line
  }, [load, activeDateRange]);

  const { path } = useRouteMatch();

  return (
    <LoadingSpinnerProvider isLoading={initializing || isLoading}>
      <Redirect to={`${path}/${date.toString()}`} />
    </LoadingSpinnerProvider>
  );
}

LatestVersionLoader.propTypes = {
  isLoading: bool.isRequired,
  date: dateType.isRequired,
  load: func.isRequired,
};
