import { connect } from 'react-redux';

import MenuReport from './MenuReport';
import { withWeekPickerPage } from '../WeekPickerPage';

import {
  fetchMenuReport,
  selectMenuReportAndDepsLoading,
  selectMenuReportAndDepsLoadingFailed,
  selectMenuReportWithLoggedUserOnTop
} from '../../../../store/report';
import { fetchCategories } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';
import { selectMenuReportPanelControl, selectReportPanelIsOpen } from '../../../../store/components';
import { fetchPeriodMenu } from '../../../../store/menu';

import { commonPath } from '../../../../routes/common/commonRoutes';
import { dateToUrlSetter, getWeekBounds } from '../../../../utils/dates';
import { withActiveDate } from '../../../../utils/HOCs';

const mapStateToProps = state => ({
  menuReport: selectMenuReportWithLoggedUserOnTop(state),
  isLoading: selectMenuReportAndDepsLoading(state),
  isLoadingFailed: selectMenuReportAndDepsLoadingFailed(state),
  button: selectMenuReportPanelControl(state),
  isOpen: selectReportPanelIsOpen(state),
});

const mapDispatchToProps = dispatch => ({
  load: (dateRange, activeDate) => {
    const activeWeekDateRange = getWeekBounds(activeDate);
    dispatch(fetchCategories());
    dispatch(fetchPeriodMenu(dateRange));
    dispatch(fetchMenuReport(activeWeekDateRange));
  },
  onWeekChange: activeWeekDateRange => {
    dispatch(fetchMenuReport(activeWeekDateRange));
  },
  handleDateChange: dateToUrlSetter(commonPath.MENU_REPORT),
});

export default withActiveDate(
  connect(mapStateToProps, mapDispatchToProps)(
    withWeekPickerPage(commonPath.MENU_REPORT)(
      MenuReport
    ),
  ),
);
