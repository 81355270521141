import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, func } from 'prop-types';

import { MenuItemAutosuggest } from '../../../../../Inputs/MenuItemAutosuggest';
import { MenuItem } from '../../../../../ListItems/DishItems';
import { ListGroup } from '../../../../../ListItems/ListGroup';
import { CategoryTitle } from '../../../../../ListItems/CategoryTitle';
import { Item } from '../../../../../ListItems/Templates';

import { categoryType, menuItemType, suggestionsType } from '../../../../../../constants';

const styles = {
  nothingSelected: {
    fontWeight: 200,
    fontStyle: 'italic',
  },
  menuItem: {
    '& :hover > p': {
      transition: theme => theme.transitions.create(['border-color', 'box-shadow']),
      borderLeft: '2px solid orange',
      paddingLeft: 1
    }
  }
};

function SelectedDishes(props) {
  const { menuItems, isDisabled, onDelete } = props;
  const { t: localize } = useTranslation();

  if (!menuItems.length) {
    return (
      <Item sx={styles.nothingSelected}>
        {localize('nothing-selected')}
      </Item>
    );
  }

  return (
    menuItems.map(item => (
      <MenuItem
        key={item.id}
        sx={isDisabled ? {} : styles.menuItem}
        item={item}
        isDisabled={isDisabled}
        onDelete={onDelete} />
    ))
  );
}

SelectedDishes.propTypes = {
  menuItems: arrayOf(menuItemType).isRequired,
  isDisabled: bool,
  onDelete: func
};

export function MenuCategoryItems(props) {
  const { t: localize } = useTranslation();
  const {
    addFoodToMenuItemForActiveDate, category, dishSuggestions,
    isDisabled, menuItems, removeMenuItem,
  } = props;
  const autosuggestRenderCondition = !Boolean(isDisabled || !dishSuggestions.length);

  return (
    <ListGroup data-test-id={`${category.name}-group`}>
      <CategoryTitle category={category}
                     color="primary" />
      <SelectedDishes menuItems={menuItems}
                      isDisabled={isDisabled}
                      onDelete={item => removeMenuItem(item)} />
      {
        autosuggestRenderCondition ? (
          <MenuItemAutosuggest suggestions={dishSuggestions}
                               id={`${category.name}-autosuggest`}
                               saveSelectedOption={food => addFoodToMenuItemForActiveDate(food)}
                               openPopperButtonPlaceholder={localize('label.dish-autosuggestion')}
                               blurOnSelect
                               noOptions={localize('label.no-dishes')} />
        ) : ''
      }
    </ListGroup>
  );
}

MenuCategoryItems.propTypes = {
  category: categoryType.isRequired,
  dishSuggestions: suggestionsType.isRequired,
  menuItems: arrayOf(menuItemType).isRequired,
  addFoodToMenuItemForActiveDate: func.isRequired,
  isDisabled: bool,
  removeMenuItem: func.isRequired,
};

MenuCategoryItems.defaultProps = {
  isDisabled: false,
};
