import React from 'react';

import { MenuAction } from '../common';

import { DAILY_ACTIONS_DIALOGS_CONFIG } from './DAILY_ACTIONS_DIALOGS_CONFIG';

export function RemoveDailyMenuAction() {
  return (
    <MenuAction {...DAILY_ACTIONS_DIALOGS_CONFIG.removeDailyMenu} />
  );
}
