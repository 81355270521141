import React from 'react';
import { bool, func } from 'prop-types';

import MobileDrawer from './MobileDrawer';
import DesktopDrawer from './DesktopDrawer';

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
const withWidth = () => WrappedComponent => props => <WrappedComponent {...props} width="xs" />;

/**
 * @description
 * <pre>
 *      `withWidth()` is required for proper behavior of Drawer Component.
 *    Without this HOF:
 *    1) Drawer is not waiting until full render of all parent components and starts to render children.
 *    2) Due to not finished render prop `isBigScreen` is being set to `false` on any screen.
 *    3) If `isBigScreen` is `false` - Mobile Drawer renders and removes record from Local Storage.
 *
 *    With this HOF - everything works OK. Don't touch! Or find better solution..
 * </pre>
 */
function Drawer(props) {
  const { isOpen, isBigScreen } = props;
  const DrawerContainer = isBigScreen ? DesktopDrawer : MobileDrawer;

  return (
    <DrawerContainer open={isOpen} {...props} />
  );
}

Drawer.propTypes = {
  isOpen: bool.isRequired,
  isBigScreen: bool.isRequired,
  openDrawer: func.isRequired,
  closeDrawer: func.isRequired,
};

export default withWidth()(Drawer);
