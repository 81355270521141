import { getReducedDishesNames } from './reportUtils';
import { selectMenuReport } from './reportSelectors';

const _stickersSortingComparator = (orderA, orderB) => {
  const isSameCategory = orderA.categoryPosition === orderB.categoryPosition;

  if (isSameCategory) {
    return orderA.dishName.localeCompare(orderB.dishName);
  }

  return orderA.categoryPosition - orderB.categoryPosition;
};

const _mapSingleOrderToSticker = ({
                                    category, dishes, user, date, categoryPosition,
                                  }) => ({
  customerName: user.name,
  dishName: getReducedDishesNames(dishes),
  date,
  category,
  categoryPosition,
});

const _nonEmptyDishesArray = ({ dishes }) => dishes.length !== 0;

const _mapUserOrdersToStickers = (order, user, date) => order
  .filter(_nonEmptyDishesArray)
  .map(({ category, dishes, position }) => _mapSingleOrderToSticker({
                                                                      category,
                                                                      dishes,
                                                                      user,
                                                                      date,
                                                                      categoryPosition: position,
                                                                    }));

const _generateSingleDayStickers = (date, dayOrders) => dayOrders
  .map(({ order, user }) => _mapUserOrdersToStickers(order, user, date))
  .flat()
  .sort(_stickersSortingComparator);

export const selectStickersData = state => Object
  .entries(selectMenuReport(state))
  .map(([date, customerOrders]) => _generateSingleDayStickers(date, customerOrders))
  .filter(array => array.length !== 0);
