import store from '../../store';
import { withAlternative } from './withAlternative';
import ConnectionErrorPage from '../../components/pages/Errors/ConnectionErrorPage';

// TODO cover not only connection errors
export const withConnectionErrorHandler = withAlternative(
  props => props.isLoadingFailed,
  ConnectionErrorPage,
);

export const withInternalServerError = (error, errorActionCreator) => {
  if (error && error.response && error.response.status === 500) {
    store.dispatch(errorActionCreator(error.response.data.key));
  }

  return error;
};
