import { createPeriodMenu, deletePeriodMenu, updatePeriodMenuStatus } from '../../../../../../../store/menu';
import { PERIOD_MENU_STATUSES } from '../../../../../../../constants';

export const MENU_ACTIONS_DIALOGS_CONFIG = {
  createMenu: {
    title: 'toolbar.should-period-menu-be-created',
    description: 'toolbar.you-are-going-to-create-period-menu',
    action: (menu, date) => createPeriodMenu(date),
    label: 'label.create-period-menu',
    variant: 'contained',
  },
  publishMenu: {
    title: 'toolbar.should-this-menu-be-published',
    description: 'toolbar.you-are-going-to-publish-period-menu',
    action: menu => updatePeriodMenuStatus(menu, PERIOD_MENU_STATUSES.PUBLISHED),
    label: 'label.publish',
    variant: 'contained',
  },
  deleteMenu: {
    title: 'toolbar.should-this-menu-be-deleted',
    description: 'toolbar.you-are-going-to-delete-period-menu',
    action: menu => deletePeriodMenu(menu),
    label: 'label.delete-menu',
    variant: 'outlined',
  },
  unpublishMenu: {
    title: 'toolbar.should-this-menu-be-unpublished',
    description: 'toolbar.you-are-going-to-unpublish-period-menu',
    action: menu => updatePeriodMenuStatus(menu, PERIOD_MENU_STATUSES.DRAFT),
    label: 'label.unpublish',
    variant: 'outlined',
  },
  purchaseMenu: {
    title: 'toolbar.should-this-menu-be-purchased',
    description: 'toolbar.you-are-going-to-purchase-period-menu',
    action: menu => updatePeriodMenuStatus(menu, PERIOD_MENU_STATUSES.PURCHASED),
    label: 'label.purchase',
    variant: 'contained',
  },
};
