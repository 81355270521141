import React, { useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Item, ItemContent } from '../../ListItems/Templates';
import { CloseButton } from '../../Buttons';

import { suggestionsType } from '../../../constants';
import {
  autosuggestStylesAndComponents,
  StyledAutocomplete,
  StyledAutocompleteInputBase,
  StyledAutosuggestPopper,
  StyledOpenPopperButton
} from './AutosuggestStylesAndComponents';

export function Autosuggest({
                              blurOnSelect,
                              getOptionLabel,
                              isDisabled,
                              noOptionsText,
                              onChange,
                              openPopperButtonPlaceholder,
                              options,
                              renderOption,
                              id: autosuggestId
                            }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const openPopperHandler = event => {
    setAnchorEl(event.currentTarget);
  };

  const closePopperHandler = () => {
    anchorEl && anchorEl.focus();
    setAnchorEl(null);
  };

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? 'autosuggest' : '';

  const { t: localize } = useTranslation();

  return (
    <Item sx={autosuggestStylesAndComponents.item}>
      <ItemContent>
        <StyledOpenPopperButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          variant={'outlined'}
          disabled={isDisabled}
          aria-describedby={id}
          data-test-id={autosuggestId}
          onClick={openPopperHandler}>
          <span>{openPopperButtonPlaceholder}</span>
        </StyledOpenPopperButton>

        <StyledAutosuggestPopper
          id={id}
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-start">

          <StyledAutocomplete
            open
            onClose={closePopperHandler}
            multiple
            value={[]}
            onChange={onChange}
            disableCloseOnSelect
            disablePortal
            blurOnSelect={blurOnSelect}
            noOptionsText={noOptionsText}
            renderOption={renderOption}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionDisabled={option => option.isDisabled === true}
            groupBy={option => localize(option.group)}
            renderInput={params => (
              <StyledAutocompleteInputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus />
            )} />

          <Box sx={autosuggestStylesAndComponents.actionZone}>
            <CloseButton onClick={closePopperHandler} />
          </Box>

        </StyledAutosuggestPopper>
      </ItemContent>
    </Item>
  );
}

Autosuggest.propTypes = {
  onChange: func.isRequired,
  noOptionsText: string.isRequired,
  renderOption: func.isRequired,
  options: suggestionsType.isRequired,
  getOptionLabel: func.isRequired,
  openPopperButtonPlaceholder: string.isRequired,
  isDisabled: bool,
  blurOnSelect: bool,
  id: string
};

Autosuggest.defaultProps = {
  isDisabled: false,
  blurOnSelect: false,
};
