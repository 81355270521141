export const orderActionTypes = {
  FETCH_PERIOD_ORDERS_START: '[PERIOD_ORDER] Fetch period orders started',
  CREATE_PERIOD_ORDER_START: '[PERIOD_ORDER] Create period order started',

  FETCH_PERIOD_ORDERS_SUCCESS: '[PERIOD_ORDER] Fetch period order success',
  CREATE_PERIOD_ORDER_SUCCESS: '[PERIOD_ORDER] Create period order success',
  UPDATE_PERIOD_ORDER_SUCCESS: '[PERIOD_ORDER] Period order update success',

  UPDATE_DAILY_ORDER_SUCCESS: '[DAILY_ORDER] Update daily order success',

  SET_DRAFT_DAILY_ORDER: '[DAILY_ORDER] Set draft daily order for active period order',

  CREATE_ORDER_ERROR: '[ORDER] Create order error',
  VALIDATE_ORDER_ERROR: '[ORDER] Validate order error',
};
