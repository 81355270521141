import { getDateRangeFromEntity, iterateOverDateRangeItemsAndPerformAct } from '../../utils/dates';
import { isAnyOfEntitiesLoading, isLoadingEntitiesFailedDueToConnectionError } from '../../utils/selectors';
import { selectActiveDate } from '../activeState';
import {
  compareByPosition,
  compareMenuItemsByFoodName,
  compareMenuItemsByIsEverydayField
} from '../../utils/comparators';
import {
  _selectMenuItemsByDate,
  isPeriodMenuPurchased,
  selectMenuItemsByDateAndCategory,
  selectOrderedMenuItemsByDateAndCategory
} from '../menu';
import { findPeriodOrderByDate } from './orderUtils';

export const ORDER_STATE_KEY = 'order';

export const selectActivePeriodOrderIsOrdered = state => selectOrderForActiveDate(state)?.ordered;
export const selectActivePeriodOrderIsModified = state => state[ORDER_STATE_KEY].modified;

const selectAllPeriodOrders = state => state[ORDER_STATE_KEY].dataArr;

export const selectOrderForActiveDate = state => {
  const activeDate = selectActiveDate(state);
  const orders = selectAllPeriodOrders(state);

  return findPeriodOrderByDate(orders, activeDate);
};

export const selectIsOrderWithDepsLoading = state => {
  return isAnyOfEntitiesLoading(state, 'menu', ORDER_STATE_KEY);
};
export const selectIsOrderWithDepsLoadingFailed = state => {
  return isLoadingEntitiesFailedDueToConnectionError(state, 'menu', ORDER_STATE_KEY);
};

export const selectDailyOrders = state => {
  const orders = [];

  const order = selectOrderForActiveDate(state);
  if (order) {
    const dateRange = getDateRangeFromEntity(order);

    iterateOverDateRangeItemsAndPerformAct(dateRange)(date => {
      const existingDailyOrder = order?.dailyOrders.find(dailyOrder => date.equals(dailyOrder.date));

      orders.push(existingDailyOrder);
    });
  }

  return orders;
};

/**
 *
 * @param state
 * @returns {DailyOrder || undefined}
 */
export const selectDailyOrderForActiveDate = state => {
  const date = selectActiveDate(state);

  return selectDailyOrders(state).find(dailyOrder => dailyOrder.date.equals(date));
};

/**
 * @param dailyOrder {DailyOrder}
 * @param menuItem {MenuItem}
 * @return {OrderItem}
 */
export const getOrderItemForMatchingMenuItem = (dailyOrder, menuItem) => {
  return dailyOrder?.orderItems
                   .find(orderItem => orderItem.menuItem.id === menuItem.id,);
};

/**
 * @param state
 * @return {MenuItem[] | undefined}
 */
export const _selectOrderedMenuItemsByDate = state => {
  return selectDailyOrderForActiveDate(state)?.orderItems
                                             .map(item => item.menuItem);
};

const _uniqueCategoriesReducer = (categoriesAcc, menuItem) => {
  const { category } = menuItem.food;
  const isCategoryAdded = categoriesAcc.find(item => item.name === category.name);
  isCategoryAdded || categoriesAcc.push(category);

  return categoriesAcc;
};
export const selectCategoriesFromMenuItems = state => {
  const menuItems = (isPeriodMenuPurchased(state)
                     ? _selectOrderedMenuItemsByDate(state)
                     : _selectMenuItemsByDate(state)) || [];

  return menuItems
    .reduce(_uniqueCategoriesReducer, [])
    .sort(compareByPosition);
};

export const selectMenuItemsForCategoryBasedOnMenuStatus = (state, category) => {
  const isPurchased = isPeriodMenuPurchased(state);
  const menuItems = isPurchased
                    ? selectOrderedMenuItemsByDateAndCategory(state, category)
                    : selectMenuItemsByDateAndCategory(state, category);
  return menuItems
    .sort(compareMenuItemsByFoodName)
    .sort(compareMenuItemsByIsEverydayField);
};
