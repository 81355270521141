import React, { useEffect } from 'react';
import { func, shape, string } from 'prop-types';
import { connect } from 'react-redux';

import {
  setCurrentLocationPath,
  setCurrentPageConfig,
} from '../../actions/luncherActions/locationActions/locationActions';
import { pageConfigType } from '../../constants';

function RouteContainer(props) {
  const {
    location, pageConfig, children, setLocationPath, setPageConfig,
  } = props;
  const { pathname } = location;

  useEffect(() => {
    setLocationPath(pathname);
    setPageConfig(pageConfig);
  }, [pathname, pageConfig, setLocationPath, setPageConfig]);

  return (
    <>
      {children}
    </>
  );
}

RouteContainer.propTypes = {
  location: shape({
                    pathname: string.isRequired,
                  }).isRequired,
  pageConfig: pageConfigType.isRequired,
  setLocationPath: func.isRequired,
  setPageConfig: func.isRequired,
};

const mapDispatchToProps = {
  setLocationPath: setCurrentLocationPath,
  setPageConfig: setCurrentPageConfig,
};

export default connect(null, mapDispatchToProps)(RouteContainer);
