import React from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';

export default function PrintButton(props) {
  const { t: localize } = useTranslation();

  return (
    <Button
      sx={{ margin: '1em' }}
      color="primary"
      variant="outlined"
      startIcon={<PrintIcon />}
      onClick={props.onClick}>
      {localize(props.labelName)}
    </Button>
  );
}

PrintButton.defaultProps = {
  onClick: () => {},
};

PrintButton.propTypes = {
  onClick: func,
  labelName: string.isRequired,
};
