import { connect } from 'react-redux';

import NotFoundPage from './NotFoundPage';

import { setNotFoundPageConfig } from '../../../../actions/luncherActions/locationActions/locationActions';
import { selectCurrentPageTitle, selectIsNotFoundPage } from '../../../../utils/selectors/locationSelectors';

const mapStateToProps = state => ({
  isNotFoundPageConfigInState: selectIsNotFoundPage(state),
  currentPageConfigTitle: selectCurrentPageTitle(state),
});

const mapDispatchToProps = dispatch => ({
  setNotFoundPageConfig: () => dispatch(setNotFoundPageConfig()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundPage);
