import { toLocalDate } from '../../../utils/dates/datesUtils';

export class MenuItemDto {
  id;
  /**
   * @type {string}
   */
  date;
  /**
   * @type {Food}
   */
  food;
  position;

  /**
   * @param menuItem {MenuItem}
   */
  constructor(menuItem) {
    Object.assign(this, {
      ...menuItem,
      date: menuItem.date.toString()
    });
  }
}

/**
 * @namespace MenuItem
 * @property {number} MenuItem.id
 * @property {LocalDate} MenuItem.date
 * @property {Food} MenuItem.food
 * @property {number} MenuItem.position
 */
export class MenuItem {
  id;
  date;
  food;
  position;

  /**
   * @param menuItemDto {MenuItemDto}
   */
  constructor(menuItemDto = {}) {
    Object.assign(this, {
      ...menuItemDto,
      date: toLocalDate(menuItemDto.date),
    });
  }
}
