import { connect } from 'react-redux';

import DailyOrderItems from './DailyOrderItems';

import { getDateToIsInMenuDictionary, selectPeriodMenuForActiveDate } from '../../../../../../../store/menu';
import { selectDailyOrders } from '../../../../../../../store/order';
import { selectActiveDate, selectActiveDateRange } from '../../../../../../../store/activeState';

const mapStateToProps = state => ({
  date: selectActiveDate(state),
  dailyOrders: selectDailyOrders(state),
  // TODO rename holidayStatuses to DateToIsHolidayDictionary
  holidayStatuses: getDateToIsInMenuDictionary(
    selectPeriodMenuForActiveDate(state),
    selectActiveDateRange(state)
  ),
});

export default connect(mapStateToProps)(DailyOrderItems);
