import { ActiveStateActionsTypes } from './activeStateActionsTypes';

export const setActiveDate = date => ({
  type: ActiveStateActionsTypes.SET_ACTIVE_DATE,
  payload: date,
});

export const setActiveDateRange = dateRange => ({
  type: ActiveStateActionsTypes.SET_ACTIVE_DATE_RANGE,
  payload: dateRange,
});
