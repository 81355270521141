import React from 'react';
import { arrayOf, bool, elementType } from 'prop-types';

import { categoryType, dateType } from '../../../constants';
import { hasNoCategories, withAlternative } from '../../../utils/HOCs';
import { NoDailyMenuMessage } from '../../Messages';

function DailyCategories(props) {
  const { categories, isDisabled, Content, date } = props;

  return (
    <React.Fragment>
      {
        categories.map(category => (
          <Content key={`${category.id}_${date}`}
                   isDisabled={isDisabled}
                   category={category} />
        ))
      }
    </React.Fragment>
  );
}

DailyCategories.propTypes = {
  categories: arrayOf(categoryType).isRequired,
  isDisabled: bool,
  Content: elementType.isRequired,
  date: dateType,
};

DailyCategories.defaultProps = {
  isDisabled: false,
};

export default withAlternative(hasNoCategories, NoDailyMenuMessage)(
  DailyCategories
);
