import { reportActionsTypes } from './reportActionsTypes';

export const fetchVendorReportStart = () => ({
  type: reportActionsTypes.FETCH_VENDOR_REPORT_START,
});

export const fetchVendorReportSuccess = report => ({
  type: reportActionsTypes.FETCH_VENDOR_REPORT_SUCCESS,
  payload: report,
});

export const noVendorReportAvailable = () => ({
  type: reportActionsTypes.NO_VENDOR_REPORT_AVAILABLE,
});

export const fetchMenuReportStart = () => ({
  type: reportActionsTypes.FETCH_MENU_REPORT_START,
});

export const fetchMenuReportSuccess = report => ({
  type: reportActionsTypes.FETCH_MENU_REPORT_SUCCESS,
  payload: report,
});

export const noMenuReportAvailable = () => ({
  type: reportActionsTypes.NO_MENU_REPORT_AVAILABLE,
});

export const fetchSlowpokeReportStart = () => ({
  type: reportActionsTypes.FETCH_SLOWPOKE_REPORT_START,
});

export const fetchSlowpokeReportSuccess = report => ({
  type: reportActionsTypes.FETCH_SLOWPOKE_REPORT_SUCCESS,
  payload: report,
});

export const noSlowpokeReportAvailable = () => ({
  type: reportActionsTypes.NO_SLOWPOKE_REPORT_AVAILABLE,
});
