import NotFoundPageConfig from '../../../routes/applicationPages/notFoundPageConfig';

export const locationActionTypes = {
  SET_CURRENT_PATH: 'Set current location path',
  SET_CURRENT_PAGE_CONFIG: 'Set current page config',
  SET_NOT_FOUND_PAGE_CONFIG: 'Set not found page config',
};

export const setCurrentLocationPath = locationPath => ({
  type: locationActionTypes.SET_CURRENT_PATH,
  payload: locationPath,
});

export const setCurrentPageConfig = pageConfig => ({
  type: locationActionTypes.SET_CURRENT_PAGE_CONFIG,
  payload: pageConfig,
});

export const setNotFoundPageConfig = () => ({
  type: locationActionTypes.SET_NOT_FOUND_PAGE_CONFIG,
  payload: NotFoundPageConfig,
});
