import React, { forwardRef } from 'react';
import { bool, element, func, string, } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Tooltip as MuiTooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTooltip = styled(MuiTooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    maxWidth: '300px',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  }
}));

const Tooltip = forwardRef((props, ref) => {
  const {
    placement, onClose, children, open, title,
  } = props;
  const { t: localize } = useTranslation();

  return (
    <StyledTooltip
      ref={ref}
      arrow
      placement={placement}
      open={open}
      onClose={onClose}
      onClick={e => e.stopPropagation()}
      onKeyPress={e => e.stopPropagation()}
      title={(
        <Typography>
          {localize(title)}
        </Typography>
      )}>
      {children}
    </StyledTooltip>
  );
});

Tooltip.defaultProps = {
  onClose: () => {},
  placement: 'bottom',
};

Tooltip.propTypes = {
  onClose: func,
  open: bool.isRequired,
  title: string.isRequired,
  children: element.isRequired,
  placement: string,
};

export default Tooltip;
