import { menusActionTypes } from './menuActions/menusActionTypes';

const initialState = {
  data: [],
  isLoading: false,
};

function insertItemWithoutDuplications(menu, item) {
  const isItemInMenu = menu.items.map(item => item.id).includes(item.id);

  return isItemInMenu ? menu.items : menu.items.concat(item);
}

export default function menusReducer(state = initialState, action) {
  switch (action.type) {
    case menusActionTypes.LOADING_PERIOD_MENUS_START:
      return {
        ...state,
        isLoading: true,
      };

    case menusActionTypes.FETCH_PERIOD_MENUS_FOR_RANGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case menusActionTypes.CREATE_PERIOD_MENU_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload),
        isLoading: false,
      };

    case menusActionTypes.DELETE_PERIOD_MENU_SUCCESS:
      return {
        ...state,
        data: state.data.reduce((acc, menu) => {
          if (menu.id !== action.payload.id) {
            acc.push(menu);
          }
          return acc;
        }, []),
        isLoading: false,
      };

    case menusActionTypes.CREATE_DAILY_MENU_SUCCESS:
    case menusActionTypes.REMOVE_DAILY_MENU_SUCCESS:
    case menusActionTypes.UPDATE_PERIOD_MENU_STATUS_SUCCESS:
      return {
        ...state,
        data: state.data.map(menu => menu.id === action.payload.id ? action.payload : menu),
      };

    case menusActionTypes.ADD_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        data: state.data.map(menu => {
          return menu.id === action.payload.menu.id ?
                 ({
                   ...menu,
                   items: insertItemWithoutDuplications(menu, action.payload.item)
                 }) :
                 menu;

        }),
      };
    }

    case menusActionTypes.REMOVE_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        data: state.data.map(menu => {
          return menu.id === action.payload.menu.id ?
                 ({
                   ...menu,
                   items: menu.items.filter(item => item.id !== action.payload.item.id),
                 }) :
                 menu;
        }),
      };
    }

    default:
      return state;
  }
}
