import { useEffect } from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export default function Notifications({ clear, text, variant }) {
  const { t: localize } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const localizedText = localize(text, localize('oops'));

  useEffect(() => {
    enqueueSnackbar(localizedText, {
      variant,
      onClose: clear,
    });
  }, [localizedText, enqueueSnackbar, variant, clear]);

  return '';
}

Notifications.propTypes = {
  text: string.isRequired,
  variant: string.isRequired,
  clear: func,
};

Notifications.defaultProps = {
  clear: () => {},
};
