import React from 'react';

import { Grid, Paper } from '@mui/material';
import AgricultureIcon from '@mui/icons-material/Agriculture';

import { PeriodMenuDetails } from '../PeriodMenuDetails';
import { MenuActions } from '../MenuActions';
import { Divider } from '../../../../../Divider';

import { periodMenuType } from '../../../../../../constants';

import './PeriodMenuToolbar.scss';

export function PeriodMenuToolbar(props) {
  return (
    <Paper id="period-menu-toolbar-paper-root"
           elevation={1}
           className="l-tool-bar-background">

      <Grid id="period-menu-toolbar-grid-root"
            container
            sx={{ width: 'inherit', flexDirection: 'column', padding: 0 }}
            className="l-tool-bar">
        <Divider sx={{ pt: 2.15, pb: 1 }} icon> <AgricultureIcon /> </Divider>

        <PeriodMenuDetails menu={props.menu} />

        <MenuActions menu={props.menu} />

      </Grid>
    </Paper>
  );
}

PeriodMenuToolbar.propTypes = {
  menu: periodMenuType
};

PeriodMenuToolbar.defaultProps = {
  menu: null,
};
