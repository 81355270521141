import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chip, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { CategoryIcon } from '../../../../../../Icons';

import { isNothingOrdered } from '../../../../../../../store/report';
import { categoryOrderType } from '../../../../../../../constants';

const styles = {
  container: {
    padding: '0.2rem',
    borderRadius: '4px',
  },
  dishLabel: {
    textAlign: 'start',
  },
  nothingOrderedLabel: {
    textAlign: 'start',
    fontStyle: 'italic',
    fontWeight: 'lighter',
    fontSize: '0.9rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

const StyledChip = styled(Chip)(() => ({
  '& .MuiChip-outlined': {
    border: '1px solid #ff6800',
    marginLeft: '0.5rem',
  }
}));

export default function CategoryOrderItem(props) {
  const { dishName, categoryName, categoryIcon } = props.categoryOrder;
  const { t: localize } = useTranslation();

  return (
    <Grid
      id="category-order-item-grid-root"
      container
      direction="row"
      justifyContent="space-between"
      wrap="nowrap"
      alignItems="center"
      sx={styles.container}>

      <Typography
        aria-label="dish"
        data-test-id={`user-daily-order-item-${categoryName}-${dishName}`}
        sx={isNothingOrdered(dishName) ? styles.nothingOrderedLabel : styles.dishLabel}>
        {localize(dishName)}
      </Typography>

      <StyledChip
        label={categoryName}
        icon={(<CategoryIcon icon={categoryIcon} />)}
        variant="outlined"
        color="primary" />
    </Grid>
  );
}

CategoryOrderItem.propTypes = {
  categoryOrder: categoryOrderType.isRequired,
};
