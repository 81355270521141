import { connect } from 'react-redux';

import DishesList from './DishesList';

import { selectDishesByCriteria, selectFilteredDishes } from '../../../utils/selectors/dishSelectors';
import { clearNewDish } from '../../../store/components';

const mapStateToProps = (state, ownProps) => ({
  dishes: selectFilteredDishes(state, ownProps.selectedCategoryId),
  newDish: state.components.dishesPage.newDish,
});

const mapDispatchToProps = {
  clearNewDish,
};

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  filterDishes: criteria => selectDishesByCriteria(propsFromState.dishes, criteria),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(DishesList);
