/* eslint-disable */
import { DayOfWeek, LocalDate, TemporalAdjusters, } from '@js-joda/core';
// noinspection ES6PreferShortImport
import { DateRange } from '../../models/DateRange'; // NEVER collapse import!! Circular dependency error

/**
 * @param date {LocalDate || string || Date}
 * @returns {LocalDate}
 */
export const toLocalDate = date => (date instanceof LocalDate) ? date : LocalDate.parse(date);

export const dateFromUrl = props => LocalDate.parse(props.match.params.date);

// __________________________________________________________________________________________

export const getDateRangeFromEntity = ({ firstDate, lastDate } = {}) => new DateRange({ firstDate, lastDate });

export const getNowDate = () => LocalDate.now();

export const getNextMonday = () => getNowDate().with(TemporalAdjusters.next(DayOfWeek.MONDAY));

/**
 * @param currentDay {LocalDate}
 * @return {DateRange}
 */
export const getWeekBounds = currentDay => {
  return new DateRange({
                         firstDate: currentDay.with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY)),
                         lastDate: currentDay.with(TemporalAdjusters.nextOrSame(DayOfWeek.FRIDAY)),
                       });
};

export const getDateRangeWithPrevAndNextTwoWeeks = date => {
  const { firstDate: firstDateOfCurrentWeek, lastDate: lastDateOfCurrentWeek } = getWeekBounds(date);

  const firstDate = firstDateOfCurrentWeek
    .with(TemporalAdjusters.previous(DayOfWeek.MONDAY))
    .with(TemporalAdjusters.previous(DayOfWeek.MONDAY));

  const lastDate = lastDateOfCurrentWeek
    .with(TemporalAdjusters.next(DayOfWeek.FRIDAY))
    .with(TemporalAdjusters.next(DayOfWeek.FRIDAY));

  return new DateRange({ firstDate, lastDate });
};

// __________________________________________________________________________________________

/**
 * @param dateA {LocalDate}
 * @param dateB {LocalDate}
 * @returns {boolean}
 */
export const areDatesFromSameWeek = (dateA, dateB) => {
  return dateA.isoWeekOfWeekyear() === dateB.isoWeekOfWeekyear();
};

export const isDateFromDateRange = (date, dateRange) => {
  const { firstDate, lastDate } = dateRange;
  return (!date.isBefore(firstDate) && !date.isAfter(lastDate));
};

export const areDateRangesEqual = (dr1, dr2) => {
  return dr1.firstDate.equals(dr2.firstDate) && dr1.lastDate.equals(dr2.lastDate);
};

export const areDatesEqual = (d1, d2) => d1.equals(d2);

// __________________________________________________________________________________________

export const iterateOverDateRangeItemsAndPerformAct = dateRange => callback => {
  const { firstDate, lastDate } = dateRange;

  for (let date = firstDate; !date.isAfter(lastDate); date = date.plusDays(1)) {
    callback(date);
  }
};
