import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectActiveDate, selectActiveDateRange, setActiveDate, setActiveDateRange } from '../../../store/activeState';
import { areDatesEqual, dateFromUrl, getDateRangeWithPrevAndNextTwoWeeks, isDateFromDateRange } from '../../dates';

export const useActiveDateFromUrlToStateSetter = props => {
  const dispatch = useDispatch();

  const dateFromProps = dateFromUrl(props);

  const dateFromState = useSelector(selectActiveDate);
  const dateRangeFromState = useSelector(selectActiveDateRange);

  useEffect(() => {
    if (!areDatesEqual(dateFromProps, dateFromState)) {
      dispatch(setActiveDate(dateFromProps));
    }

    if (!isDateFromDateRange(dateFromState, dateRangeFromState)) {
      const dateRangeForThreeWeeks = getDateRangeWithPrevAndNextTwoWeeks(dateFromState);
      dispatch(setActiveDateRange(dateRangeForThreeWeeks));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFromProps, dateFromState]);

  return null;
};
