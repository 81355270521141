import React, { useCallback, useEffect, useState } from 'react';
import { bool, string } from 'prop-types';

import { Box, Drawer, Paper, useTheme } from '@mui/material';
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth';

import { MobileOrderButton } from '../../../../../Buttons';
import { PeriodOrderBillPanel } from './PeriodOrderBillPanel';
import { PeriodMenuDetails } from '../../../../Foodlord/PeriodMenu/components/PeriodMenuDetails';

let onPopstateEventHandler;
const OPEN_DRAWER_HISTORY_STATE = 'drawer-opened';

const registerBackButtonListener = callback => {
  if (window.history && window.history.pushState) {
    window.history.pushState(OPEN_DRAWER_HISTORY_STATE, '');

    onPopstateEventHandler = callback;

    window.addEventListener('popstate', onPopstateEventHandler);
  }
};

const unregisterBackButtonListener = () => {
  if (window.history && window.history.pushState) {
    const isDrawerInHistoryStack = window.history.state === OPEN_DRAWER_HISTORY_STATE;
    isDrawerInHistoryStack && window.history.back();

    window.removeEventListener('popstate', onPopstateEventHandler);
  }
};

const styles = {
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  billContainer: theme => ({
    height: 'inherit',
    minHeight: 0,
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  }),
  paperRoot: {
    marginBottom: 0,
    height: 'inherit',
  },
  paperDrawer: {
    maxHeight: '80vh',
    overflowY: 'hidden',
    marginBottom: 0
  },
};

function MobileBillLayoutWrapper() {
  const [isOpen, setOpen] = useState(false);

  const toggleDrawer = useCallback(() => setOpen(!isOpen), [isOpen]);

  useEffect(() => {
    isOpen
      ? registerBackButtonListener(toggleDrawer)
      : unregisterBackButtonListener();
  }, [isOpen, toggleDrawer]);

  return (
    <>
      <MobileOrderButton onClickListener={toggleDrawer} />
      <Drawer open={isOpen} onClose={toggleDrawer}
              anchor="bottom" sx={{ '& .MuiDrawer-paper': styles.paperDrawer }}>
        <OrderBill isMobileView />
      </Drawer>
    </>
  );
}

MobileBillLayoutWrapper.propTypes = {};

function OrderBill(props) {
  const theme = useTheme();
  return (
    <Box id="bill-root-container"
         sx={{ ...styles.flexContainer, ...styles.billContainer(theme) }}>
      <Paper id="bill-paper-container"
             sx={{ ...styles.flexContainer, ...styles.paperRoot }}>
        {
          props.isMobileView ? '' : (
            <>
              <PeriodMenuDetails />
            </>
          )
        }
        <PeriodOrderBillPanel />
      </Paper>
    </Box>
  );
}

OrderBill.propTypes = {
  isMobileView: bool,
};

OrderBill.defaultProps = {
  isMobileView: false,
};

function OrderResponsiveToolbar(props) {
  const isMobileView = isWidthUp('md', props.width);

  return isMobileView
    ? <OrderBill />
    : <MobileBillLayoutWrapper />;
}

OrderResponsiveToolbar.propTypes = {
  width: string.isRequired, // 'md' or other MUI breakpoint will be passed
};

export default withWidth()(
  OrderResponsiveToolbar
);
