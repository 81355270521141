import { createValidator } from '../validations';

export const checkIsFormValid = formProps => {
  const {
    hasValidationErrors,
    dirtyFieldsSinceLastSubmit,
    submitErrors,
    submitting,
    pristine,
  } = formProps;

  const isAllFieldsWithErrorModifiedSinceLastSubmit = submitErrors
    ? Object.keys(submitErrors).reduce((res, fieldName) =>
      res && Object.keys(dirtyFieldsSinceLastSubmit).includes(fieldName), true)
    : true;

  return (hasValidationErrors || !isAllFieldsWithErrorModifiedSinceLastSubmit || submitting || pristine);
};

export const createValidatorFor = entity => (field, ...validators) => (
  createValidator(`${entity}.${field}`, ...validators)
);
