import React from 'react';

import UnicornImageSvg from '../../images/unicorn.svg';
import { GLOBALS } from '../../constants/globals';
import { Message } from './Message';

export function NothingToShowMessage() {
  return (
    <Message
      image={UnicornImageSvg}
      message="sorry-nothing-to-show-right-now"
      imageBox={GLOBALS.images.viewBox.unicorn} />
  );
}
