import React, { useEffect } from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Paper, Typography } from '@mui/material';

import { GLOBALS } from '../../../../constants';
import PandaSleepingImageSvg from '../../../../images/pandaSleeping.svg';
import { MessageContainer, MessageImage, MessageText } from '../../../Messages/Templates';

import '../pageStyles.scss';

export default function NotFoundPage(props) {
  const { t: localize } = useTranslation();
  const { setNotFoundPageConfig, isNotFoundPageConfigInState, currentPageConfigTitle } = props;

  useEffect(() => {
    if (!isNotFoundPageConfigInState) {
      setNotFoundPageConfig();
    }
  }, [isNotFoundPageConfigInState, setNotFoundPageConfig, currentPageConfigTitle]);

  return (
    <Paper className="paper-no-content-page">
      <MessageContainer>
        <MessageImage
          src={PandaSleepingImageSvg}
          alt={localize('page-not-found')}
          viewBox={GLOBALS.images.viewBox.pandaSleeping} />
        <MessageText>
          <Typography variant="h2" component="h2">
            {localize('oops')}
          </Typography>
        </MessageText>
        <MessageText>
          <Typography variant="h3" component="p">
            {localize('page-not-found')}
          </Typography>
        </MessageText>
      </MessageContainer>
    </Paper>
  );
}

NotFoundPage.propTypes = {
  setNotFoundPageConfig: func.isRequired,
  isNotFoundPageConfigInState: bool.isRequired,
  currentPageConfigTitle: string.isRequired,
};
