import React from 'react';

import {
  DayPickerOrderPage,
  OrderBillResponsiveToolbar,
  DatePickerOrderGridContainer,
  OrderBillGridContainer,
  OrderGridRootContainer
} from './components';

function Order() {
  return (
      <OrderGridRootContainer id="order-page-grid-container"
                              container>

        <DatePickerOrderGridContainer id="period-menu-grid-item"
                                      item xs={12} sm={12} md={8}>
          <DayPickerOrderPage />
        </DatePickerOrderGridContainer>

        <OrderBillGridContainer id="order-panel-grid-item"
                                item md={4}>
          <OrderBillResponsiveToolbar />
        </OrderBillGridContainer>
      </OrderGridRootContainer>
  );
}

Order.propTypes = {};

Order.defaultProps = {};

export default Order;
