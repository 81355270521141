import React, { useEffect } from 'react';
import { bool, func, shape, string, } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextField } from 'mui-rff';

import {
  allowOnlyDigits,
  CATEGORY_FORM_CONSTRAINTS,
  maxLength,
  maxValue,
  required,
  trimValue,
} from '../../../utils/validations/validations';
import { checkIsFormValid, createValidatorFor } from '../../../utils/componentsUtils/formUtils';

const getValidator = createValidatorFor('category');

// TODO extract inputs in separate components.
export default function CategoryFormContent(props) {
  const {
    formProps, setFormValidationStatus, formId,
  } = props;

  useEffect(() => {
    setFormValidationStatus(checkIsFormValid(formProps));
  }, [formProps, setFormValidationStatus]);

  const { t: localize } = useTranslation();

  // TODO add localization for helper texts!
  return (
    <form
      id={formId}
      onSubmit={formProps.handleSubmit}
      noValidate>

      <TextField
        id={`${formId}-text-field-name`}
        name="name"
        label={localize('label.name')}
        autoFocus
        required
        helperText={"Should be less than 80 characters!"}
        margin="dense"
        fieldProps={{
          validateFields: [],
          parse: trimValue,
          validate: (
            getValidator('name',
                         required,
                         maxLength(CATEGORY_FORM_CONSTRAINTS.name.length))
          ),
        }} />

      <TextField
        id={`${formId}-text-field-position`}
        name="position"
        label={localize('label.position')}
        required
        helperText={"Only digits allowed!"}
        margin="dense"
        fieldProps={{
          validateFields: [],
          parse: allowOnlyDigits,
          validate: (
            getValidator('position',
                         required,
                         maxValue(1000))
          ),
        }} />
    </form>
  );
}

CategoryFormContent.propTypes = {
  formId: string.isRequired,
  formProps: shape({
                     handleSubmit: func.isRequired,
                     hasSubmitErrors: bool.isRequired,
                     hasValidationErrors: bool.isRequired,
                     dirtySinceLastSubmit: bool.isRequired,
                     submitting: bool.isRequired,
                     pristine: bool.isRequired,
                   }).isRequired,
  setFormValidationStatus: func.isRequired,
};
