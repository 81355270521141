import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';

import { changeAppLanguage, getDefaultLanguage } from '../../../utils/localizationUtils/localizationUtils';

const styles = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 2,
  },
  options: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default function Settings() {
  const { t: localize } = useTranslation();

  const defaultLanguage = getDefaultLanguage();
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  useEffect(() => changeAppLanguage(currentLanguage), [currentLanguage]);

  return (
    <Paper sx={styles.paper}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{localize('settings.language')}</FormLabel>
        <RadioGroup value={currentLanguage} aria-label="language">
          <Box sx={styles.options}>
            <FormControlLabel
              value="uk"
              control={<Radio color="primary" />}
              label={localize('settings.ukrainian')}
              onChange={() => setCurrentLanguage('uk')} />
            <FormControlLabel
              value="en"
              control={<Radio color="primary" />}
              label={localize('settings.english')}
              onChange={() => setCurrentLanguage('en')} />
          </Box>
        </RadioGroup>
      </FormControl>
      <Divider />
    </Paper>
  );
}
