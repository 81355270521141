import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DishFormContainer from './DishFormContainer';

import { updateDish } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import { selectCategories } from '../../../../utils/selectors/categorySelectors';
import { selectDishById } from '../../../../utils/selectors/dishSelectors';

const mapStateToProps = (state, ownProps) => {
  const dishFromUrl = selectDishById(state, ownProps.match.params.id);

  return ({
    dish: {
      ...dishFromUrl,
      blockedCategories: (
        selectCategories(state)
          .filter(
            category => dishFromUrl.blockedCategories
                                   .map(blockedCategory => blockedCategory.id)
                                   .includes(category.id),
          )
      ),
    },
  });
};

const mapDispatchToProps = {
  save: updateDish,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    DishFormContainer,
  ),
);
