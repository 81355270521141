import { connect } from 'react-redux';

import PeriodOrderBillPanel from './PeriodOrderBillPanel';

import { isPeriodMenuPurchased } from '../../../../../../../store/menu';
import {
  selectActivePeriodOrderIsModified,
  selectActivePeriodOrderIsOrdered,
  selectOrderForActiveDate,
  updateAllDailyOrdersInPeriodOrder,
  updatePeriodOrder,
} from '../../../../../../../store/order';
import { EmptyComponent, noOrder, withAlternative } from '../../../../../../../utils/HOCs';

const mapStateToProps = state => ({
  order: selectOrderForActiveDate(state),
  isOrdered: selectActivePeriodOrderIsOrdered(state),
  isModified: selectActivePeriodOrderIsModified(state),
  isDisabled: isPeriodMenuPurchased(state),
});

const mapDispatchToProps = dispatch => ({
  // NOTE that payload of dispatched action is modified inside action: {ordered: true} is added on HTTP call
  orderMenu: periodOrder => dispatch(updatePeriodOrder(periodOrder)),
  reorderMenu: periodOrder => dispatch(updateAllDailyOrdersInPeriodOrder(periodOrder)),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  return ({
    ...propsFromState,
    ...ownProps,
    orderMenu: () => propsFromDispatch.orderMenu(propsFromState.order),
    reorderMenu: () => propsFromDispatch.reorderMenu(propsFromState.order),
  });
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  withAlternative(noOrder, EmptyComponent)(
    PeriodOrderBillPanel
  )
);
