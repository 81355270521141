import axiosInstance from '../../../../axiosInstance';
import { startLoginProcedure, stopLoginProcedure } from './authenticationActions';

export const checkAuthentication = () => dispatch => {
  dispatch(startLoginProcedure());
  axiosInstance.get('/login-check', {
    withCredentials: true,
    mode: 'cors',
  })
               .then(res => {
                 if (res && res.status === 200) {
                   dispatch(stopLoginProcedure());
                 }
               });
};
