import React from 'react';
import { Switch } from 'react-router-dom';
import { bool } from 'prop-types';

import NotFoundPageRoute from '../NotFoundPageRoute';

import { generateFoodlordRoutes } from '../../utils/routeUtils';

function FoodlordRouter(props) {
  return props.isFoodlord ? (
    <Switch>
      {generateFoodlordRoutes()}
      <NotFoundPageRoute />
    </Switch>
  ) : (<NotFoundPageRoute />);
}

FoodlordRouter.propTypes = {
  isFoodlord: bool.isRequired,
};

export default FoodlordRouter;
