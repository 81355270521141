import React from 'react';

import {
  formatDateForMenuReportPrint,
  getDateRangeFromEntity,
  isDateFromDateRange,
  toLocalDate
} from '../../../../../../utils/dates';
import { MenuReportPrintItem } from '../MenuReportPrintItem';

export const wrapInRow = items => (
  <div className="row-container" key={Math.random()}>
    {[...items]}
  </div>
);

const createItem = (item, isGray) => <MenuReportPrintItem data={item} isGray={isGray} key={Math.random()} />;

const generateSingleDayReport = orderItems => {
  let isGray = false;
  const rowsAcc = [];

  const createRow = (left, right) => {
    const leftItem = left && createItem(left, isGray);
    isGray = !isGray;
    const rightItem = right && createItem(right, isGray);

    return wrapInRow([leftItem, rightItem]);
  };

  for (let i = 0; i < orderItems.length; i += 2) {
    rowsAcc.push(createRow(orderItems[i], orderItems[i + 1]));
  }

  return rowsAcc;
};

const generateMenuReportHeader = date => (
  <h1 key={date.toString()}>
    {formatDateForMenuReportPrint(date)}
  </h1>
);

const nonEmptyReportsFilter = ([, report]) => report.length;

const nullishReports = item => Boolean(item);

export const generatePrintMenuReport = (menuPrintReport, menu) => {
  const menuDateRange = getDateRangeFromEntity(menu);

  return Object
    .entries(menuPrintReport)
    .map(([date, orderItems]) => {
      return isDateFromDateRange(toLocalDate(date), menuDateRange) ? [
        generateMenuReportHeader(date),
        generateSingleDayReport(orderItems)
      ] : null;
    })
    .filter(nullishReports)
    .filter(nonEmptyReportsFilter)
    .flat();
};
