import React from 'react';
import { bool, func, string } from 'prop-types';

import { ListItemIcon as MuiListItemIcon, SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';

import { TooltipApplier } from '../../Tooltip';

import { themeConstants } from '../../../constants/themeConstants';
import { iconType } from '../../../constants/types';

export const isMuiIcon = icon => Object.prototype.hasOwnProperty.call(icon, 'muiName');

const StyledMuiListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primaryLight?.main || themeConstants.primaryLight.main,
  },
  '& path': {
    fill: 'currentColor',
  },
  '& rect': {
    fill: 'none',
    stroke: 'currentColor',
  },
  color: 'inherit',
}));

export default function ListItemIconWithTooltip(props) {
  const {
    Icon, title, onIconClick, isActive,
  } = props;

  return (
    <TooltipApplier
      title={title}
      shouldClose
      closeTimeout={1500}
      placement="bottom-end">

      <StyledMuiListItemIcon
        sx={{ color: theme => isActive ? theme.palette.primary.main : 'inherit' }}
        onClick={onIconClick}>

        {isMuiIcon(Icon) ? <Icon /> : <SvgIcon component={Icon} />}
      </StyledMuiListItemIcon>
    </TooltipApplier>
  );
}

ListItemIconWithTooltip.propTypes = {
  Icon: iconType.isRequired,
  isActive: bool.isRequired,
  title: string.isRequired,
  onIconClick: func.isRequired,
};
