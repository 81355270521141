import {
  addDishSuccess,
  deleteDishSuccess,
  fetchDishesStart,
  fetchDishesSuccess,
  updateDishSuccess,
} from './dishesActions';
import { setGeneralError } from '../errorActions/errorActions';
import { setSuccessStatus } from '../successActions/successActions';

import axiosInstance from '../../../axiosInstance';
import httpCodes from '../../../constants/httpCodes';

export const fetchDishes = () => dispatch => {
  dispatch(fetchDishesStart());
  return axiosInstance.get('/dishes')
                      .then(response => response && dispatch(fetchDishesSuccess(response.data)))
                      .catch(error => dispatch(setGeneralError(error)));
};
// FIXME: id should not be in the creation model but is required in the Form
/* eslint-disable-next-line */
export const addDish = ({ id, ...dish }) => dispatch => {
  return axiosInstance.post('/dishes', dish)
                      .then(response => response && dispatch(
                        addDishSuccess(response.data)))
                      .then(
                        () => dispatch(setSuccessStatus('dish-created-successfully')))
                      .catch(error => {
                        throw error.response.data;
                      });
};

export const updateDish = dish => dispatch => {
  return axiosInstance.put(`/dishes/${dish.id}`, dish)
                      .then(response => response && dispatch(
                        updateDishSuccess(response.data)))
                      .then(() => dispatch(
                        setSuccessStatus('dish-updated-successfully')))
                      .catch(error => {
                        throw error.response.data;
                      });
};

export const deleteDish = id => dispatch => {
  return axiosInstance.delete(`/dishes/${id}`)
                      .then(
                        response => response && response.status === httpCodes.NO_CONTENT && dispatch(
                          deleteDishSuccess(id)))
                      .then(() => dispatch(
                        setSuccessStatus('dish-deleted-successfully')))
                      .catch(error => dispatch(setGeneralError(error)));
};
