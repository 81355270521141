import { connect } from 'react-redux';
import CategoryForm from '../CategoryForm';

const mapStateToProps = (state, ownProps) => ({
  close: () => ownProps.history.push(ownProps.href),
});

const mapDispatchToProps = () => ({
  getInitialValues: category => ({
    ...category,
    position: category.position || '',
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  CategoryForm,
);
