export const categoriesActionTypes = {
  FETCH_CATEGORIES_START: '[CATEGORIES] Fetch categories start',
  FETCH_CATEGORIES_SUCCESS: '[CATEGORIES] Fetch categories success',
  ADD_CATEGORY_SUCCESS: '[CATEGORIES] Add category success,',
  CLEAR_CATEGORY_ERROR: '[CATEGORIES] Clear category error',
  UPDATE_CATEGORY_SUCCESS: '[CATEGORIES] Update category success',
  CATEGORIES_VALIDATION_ERROR: '[CATEGORIES] Categories validation error',
  DELETE_CATEGORY_SUCCESS: '[CATEGORIES] Delete category success',
};

export const fetchCategoriesStart = () => ({
  type: categoriesActionTypes.FETCH_CATEGORIES_START,
});

export const fetchCategoriesSuccess = categories => ({
  type: categoriesActionTypes.FETCH_CATEGORIES_SUCCESS,
  payload: categories,
});

export const categoryValidationError = error => ({
  type: categoriesActionTypes.CATEGORIES_VALIDATION_ERROR,
  payload: error,
});

export const clearCategoryError = () => ({
  type: categoriesActionTypes.CLEAR_CATEGORY_ERROR,
});

export const addCategorySuccess = category => ({
  type: categoriesActionTypes.ADD_CATEGORY_SUCCESS,
  payload: category,
});

export const updateCategorySuccess = category => ({
  type: categoriesActionTypes.UPDATE_CATEGORY_SUCCESS,
  payload: category,
});

export const deleteCategorySuccess = id => ({
  type: categoriesActionTypes.DELETE_CATEGORY_SUCCESS,
  payload: id,
});
