import React from 'react';
import { bool, shape, string } from 'prop-types';

import { Box } from '@mui/material';

import { refType } from '../../../constants/types/types';

import './Item.scss';

function defineStyleClasses({ useOnHover, isSelectable, isHighlighted }) {
  const styles = ['l-item'];

  if (useOnHover) {
    styles.push('with-background');
  }
  if (isSelectable) {
    styles.push('selectable');
  }
  if (isHighlighted) {
    styles.push('highlighted');
  }

  return styles.join(' ');
}

function Item({
                children,
                useOnHover,
                isSelectable,
                isHighlighted,
                forwardRef,
                sx,
                id
              }) {
  const className = `${defineStyleClasses({ useOnHover, isSelectable, isHighlighted })}`;
  return (
    <Box id={id || 'item'}
         data-test-id={id}
         className={className}
         ref={forwardRef}
         sx={sx}>
      {children}
    </Box>
  );
}

Item.defaultProps = {
  useOnHover: false,
  isSelectable: false,
  forwardRef: null,
  isHighlighted: false,
  sx: {}
};

Item.propTypes = {
  useOnHover: bool,
  isSelectable: bool,
  forwardRef: refType,
  isHighlighted: bool,
  sx: shape({}),
  id: string,
};

export default Item;
