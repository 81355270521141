import {
  CommonPagesConfig,
  EaterPagesConfig,
  FoodlordPagesConfig,
  OpenPagesConfig,
} from '../../routes/applicationPages';

/**
 *
 * @param routesMap {Object} - route config
 * @param selector {('displayInSideMenu'|'displayInSideMenuReports')} - flag in route config
 * @return {{}}
 */
const getRoutesBySelector = (routesMap, selector) => {
  const result = {};

  Object.entries(routesMap)
        .forEach(([routePath, routeConfig]) => {
          if (routeConfig[selector]) {
            result[routePath] = routeConfig;
          }
        });

  return result;
};

export const getFoodlordSideMenuRoutes = () => getRoutesBySelector(FoodlordPagesConfig, 'displayInSideMenu');
export const getOpenSideMenuRoutes = () => getRoutesBySelector(OpenPagesConfig, 'displayInSideMenu');
export const getEaterSideMenuRoutes = () => getRoutesBySelector(EaterPagesConfig, 'displayInSideMenu');

export const getFoodlordSideMenuReportRoutes = () => getRoutesBySelector(FoodlordPagesConfig,
                                                                         'displayInSideMenuReports');
export const getCommonSideMenuReportRoutes = () => getRoutesBySelector(CommonPagesConfig, 'displayInSideMenuReports');

