import { connect } from 'react-redux';
import OrderCategoryAccordion from './OrderCategoryAccordion';
import { isPeriodMenuPurchased, } from '../../../../../../../store/menu';
import {
  findIsAnyItemForCategoryOrdered,
  selectDailyOrderForActiveDate,
  selectMenuItemsForCategoryBasedOnMenuStatus
} from '../../../../../../../store/order';

const mapStateToProps = (state, ownProps) => {
  const isPurchased = isPeriodMenuPurchased(state);

  const menuItems = selectMenuItemsForCategoryBasedOnMenuStatus(state, ownProps.category);

  const dailyOrder = selectDailyOrderForActiveDate(state);
  const isAnyItemForCategoryOrdered = findIsAnyItemForCategoryOrdered(menuItems, dailyOrder);

  return {
    menuItems,
    isDefaultOpen: isPurchased,
    isAnyItemForCategoryOrdered,
  };
};

export default connect(mapStateToProps)(OrderCategoryAccordion);
