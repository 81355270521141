import React, { useState } from 'react';
import { arrayOf, func } from 'prop-types';

import Form from '../Common/Form/Form';
import DishFormContent from './DishFormContent';
import FormDialog from '../Common/FormDialog/FormDialog';

import { categoryType, dishType } from '../../../constants/types/types';

// HTML form submit works when same ID is provided to <form> and <SubmitButton>
const FORM_ID = 'dish-form';

function DishForm(props) {
  const {
    save, close, getInitialValues, categories, dish,
  } = props;

  const [isFormInvalid, setFormValidationStatus] = useState(true);

  return (
    <FormDialog
      title="label.dish"
      formIdToSubmit={FORM_ID}
      onClose={close}
      isSubmitDisabled={isFormInvalid}>

      <Form
        FormContent={DishFormContent}
        save={save}
        close={close}
        initialValues={getInitialValues(dish)}
        formContentProps={{
          formId: FORM_ID,
          setFormValidationStatus,
          categories,
        }} />

    </FormDialog>
  );
}

DishForm.propTypes = {
  categories: arrayOf(categoryType).isRequired,
  close: func.isRequired,
  dish: dishType.isRequired,
  getInitialValues: func.isRequired,
  save: func.isRequired,
};

export default DishForm;
