import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { Grid } from '@mui/material';

import { SearchInput } from '../../Inputs/SearchInput';
import { CategoryFilter as CategoryFilterPicker } from '../../Inputs/CategoryFilter';
import DishItems from './DishItems';

import { dishType } from '../../../constants/types';
import { themePresets } from '../../../constants/themeConstants';

const styles = {
  flexContainer: {
    ...themePresets.flexColumnContainer,
    flexWrap: 'nowrap',
  },
  disableGrow: {
    flexGrow: 0,
  },
  inheritHeight: {
    height: 'inherit',
  },
  scrollable: {
    overflowY: 'auto',
  },
};

function DishesList(props) {
  const { t: localize } = useTranslation();
  const {
    dishes: dishesFromProps, selectedCategoryId, filterDishes, newDish: newDishFromProps, clearNewDish,
  } = props;

  const [filterCriteria, setFilterCriteria] = useState('');
  const [dishes, setDishes] = useState(dishesFromProps);

  useEffect(() => {
    setDishes(filterDishes(filterCriteria));
  }, [filterCriteria, dishesFromProps, filterDishes]);

  const newDishRef = useRef(null);
  const newDish = {
    ref: newDishRef,
  };
  Object.assign(newDish, newDishFromProps);

  useEffect(() => {
    const timer = setTimeout(() => newDishRef.current && scrollIntoView(newDishRef.current, {
      scrollMode: 'if-needed',
      block: 'end',
    }), 200);
    return () => clearTimeout(timer);
  }, [newDishFromProps, newDishRef]);

  useEffect(() => {
    newDishFromProps && setTimeout(() => clearNewDish(), 3000);
  }, [newDishFromProps, clearNewDish]);

  return (
    <Grid
      id="dishes-list-root"
      container
      direction="column"
      sx={{ ...styles.flexContainer, ...styles.inheritHeight }}>

      <Grid
        id="dishes-list-toolbar"
        container
        direction="column"
        sx={{ ...styles.flexContainer, ...styles.disableGrow }}>

        <CategoryFilterPicker
          selectedCategoryId={selectedCategoryId} />

        <SearchInput
          value={filterCriteria}
          onChange={(value => setFilterCriteria(value))}
          clearValue={() => setFilterCriteria('')}
          label={localize('label.search-dishes')} />
      </Grid>

      <Grid
        id="dishes-list-content"
        container
        alignItems="stretch"
        sx={{ ...styles.flexContainer, ...styles.scrollable }}>

        <DishItems
          dishes={dishes}
          newDish={newDish}
          selectedCategoryId={selectedCategoryId} />
      </Grid>
    </Grid>
  );
}

DishesList.propTypes = {
  dishes: arrayOf(dishType).isRequired,
  newDish: dishType,
  clearNewDish: func.isRequired,
  filterDishes: func.isRequired,
  selectedCategoryId: number,
};

DishesList.defaultProps = {
  selectedCategoryId: undefined,
  newDish: null,
};

export default DishesList;
