import { connect } from 'react-redux';

import PrintableStickersReport from './PrintableStickersReport';

import { selectStickersData } from '../../../../../store/report';

const mapStateToProps = state => ({
  stickersReportData: selectStickersData(state),
});

export default connect(mapStateToProps)(PrintableStickersReport);
