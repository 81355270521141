import React from 'react';
import { func, shape, string } from 'prop-types';
import Notifications from '../Notifications';

function SuccessNotifications(props) {
  return props.success.status && (
    <Notifications
      text={`snackbar-success.${props.success.status}`}
      variant="success"
      clear={props.clear} />
  );
}

SuccessNotifications.propTypes = {
  success: shape({
                   status: string,
                 }).isRequired,
  clear: func.isRequired,
};

export default SuccessNotifications;
