const usersActionTypes = {
  FETCH_USER_NAMES_START: '[Users] Start user names retrieval',
  FETCH_USER_NAMES_SUCCESS: '[Users] Retrieved users names',
  SAVE_USER_FILTER: '[Users] save user filter',
};

const fetchUserNamesStart = () => ({
  type: usersActionTypes.FETCH_USER_NAMES_START,
});

const fetchUserNamesSuccess = users => ({
  type: usersActionTypes.FETCH_USER_NAMES_SUCCESS,
  payload: users,
});

const saveUserFilter = users => ({
  type: usersActionTypes.SAVE_USER_FILTER,
  payload: users,
});

export {
  usersActionTypes,
  fetchUserNamesStart,
  fetchUserNamesSuccess,
  saveUserFilter,
};
