import React from 'react';

/**
 * @param rules {Map}
 * @param props
 * @returns {*}
 */
function getAlternativeFromMap(rules, props) {
  const conditionsEntries = rules.entries();
  let conditionE = conditionsEntries.next();

  let Alternative;
  while (!conditionE.done) {
    const [ruleCb, Alternate] = conditionE.value;
    if (ruleCb(props)) {
      Alternative = Alternate;
      break;
    } else {
      conditionE = conditionsEntries.next();
    }
  }

  return Alternative;
}

/** If rule === `true` Alternative is displayed, if `false` --> Component
 *
 *
 * @param {(Map<function(props: Object): boolean, function(*): React.Component>
 *                                              | function(props: Object): boolean)
 *                                              | boolean } alternativeRules - one of next:
 * 1) Map with [ key: renderConditionFunction(props: Object): boolean, value: React.Component ].
 * 2) Function that accepts 'props' Object and returns boolean.
 *
 * @param {(React.Component | function(*): React.Component)} [Alternate] - Component to render (required
 * only when
 * 'alternativeRules' is function).
 *
 * @returns {function(React.Component): function(props: Object): React.Component} - Component to render
 */
export function withAlternative(
  alternativeRules,
  Alternate
) {
  return Component => props => {
    let ResultComponent;

    if (typeof alternativeRules === 'function') {
      ResultComponent = alternativeRules(props) ? Alternate : Component;
    } else if (alternativeRules instanceof Map) {
      const AlternateComponent = getAlternativeFromMap(alternativeRules, props);
      ResultComponent = AlternateComponent || Component;
    } else if (typeof alternativeRules === 'boolean') {
      ResultComponent = alternativeRules ? Alternate : Component;
    } else {
      throw new Error('Unsupported alternative rules');
    }

    return (<ResultComponent {...props} />);
  };
}
