import React, { useEffect } from 'react';
import { arrayOf, bool, func, string, } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';

import { LoadingSpinnerProvider } from '../../LoadingSpinnerProvider';

const filterOptions = (options, { inputValue }) => options.filter(userName => {
  let hasMatch = false;
  const userNameParts = userName.split(' ');

  userNameParts.forEach(userNamePart => {
    if (userNamePart.toLowerCase()
                    .startsWith(inputValue.toLowerCase())) {
      hasMatch = true;
    }
  });

  return hasMatch && userName;
});

export default function UsersAutocompleteSearch(props) {
  const {
    load,
    users,
    filteredUsers,
    saveFilter,
    isLoading,
  } = props;
  const { t: localize } = useTranslation();

  useEffect(() => {
    if (!users.length && !isLoading) {
      load();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <LoadingSpinnerProvider isLoading={isLoading}>
      <Autocomplete
        sx={{ margin: '1em' }}
        multiple
        options={users}
        value={filteredUsers}
        onChange={(e, values) => saveFilter(values)}
        autoHighlight
        filterSelectedOptions
        filterOptions={filterOptions}
        noOptionsText={localize('label.no-options')}
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            label={localize('label.search')}
            placeholder={localize('label.names')} />
        )} />
    </LoadingSpinnerProvider>
  );
}

UsersAutocompleteSearch.propTypes = {
  users: arrayOf(string).isRequired,
  filteredUsers: arrayOf(string).isRequired,
  load: func.isRequired,
  saveFilter: func.isRequired,
  isLoading: bool.isRequired,
};
