import React from 'react';
import { string } from 'prop-types';

import { Grid, SvgIcon } from '@mui/material';
import { iconType } from '../../../constants';

const styles = {
  root: {
    height: '12em',
    width: 'auto',
    maxWidth: '15em',
  },
};

export function MessageImage(props) {
  return (
    <Grid item>
      <SvgIcon
        component={props.src}
        titleAccess={props.alt}
        viewBox={props.viewBox}
        sx={styles.root} />
    </Grid>
  );
}

MessageImage.propTypes = {
  src: iconType.isRequired,
  alt: string.isRequired,
  viewBox: string.isRequired,
};

export default MessageImage;
