import React from 'react';
import { Route } from 'react-router-dom';

import RouteContainer from './RouteContainer';

import {
  CommonPagesConfig,
  EaterPagesConfig,
  FoodlordPagesConfig,
  OpenPagesConfig,
} from '../../routes/applicationPages';

const generateRoutes = routesMap => Object.values(routesMap)
                                          .map(({ link, component: Component }) => (
                                            <Route
                                              path={link}
                                              render={props => (
                                                <RouteContainer
                                                  pageConfig={routesMap[link]}
                                                  location={props.location}>
                                                  <Component {...props} />
                                                </RouteContainer>
                                              )}
                                              key={link} />
                                          ));

export const generateCommonRoutes = () => generateRoutes(CommonPagesConfig);
export const generateEaterRoutes = () => generateRoutes(EaterPagesConfig);
export const generateFoodlordRoutes = () => generateRoutes(FoodlordPagesConfig);
export const generateOpenRoutes = () => generateRoutes(OpenPagesConfig);
