import { connect } from 'react-redux';

import PrintableMenuReport from './PrintableMenuReport';

import { selectMenuPrintReportData } from '../../../../../../store/report';
import { selectPeriodMenuForActiveDate } from '../../../../../../store/menu';

const mapStateToProps = state => ({
  menuReportData: selectMenuPrintReportData(state),
  menu: selectPeriodMenuForActiveDate(state),
});

export default connect(mapStateToProps)(PrintableMenuReport);
