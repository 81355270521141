import { connect } from 'react-redux';

import commonRouter from './CommonRouter';
import { hasAnyRole } from '../../store/auth';

const mapStateToProps = state => ({
  isAuthenticated: hasAnyRole(state),
});

export default connect(mapStateToProps)(commonRouter);
