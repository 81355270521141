import NotFoundPage from '../../../components/pages/Errors/NotFoundPage/NotFoundPageContainer';

import { isDateValid } from '../../validations';
import { withAlternative } from '../withAlternative';

export const withDateFromUrlValidation = WrappedComponent => props => {
  return withAlternative(!isDateValid, NotFoundPage)(
    WrappedComponent
  )(props);
};
