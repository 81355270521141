import { errorActionTypes } from '../actions/luncherActions/errorActions/errorActions';
import generalErrors from '../constants/generalErrors';
import { orderActionTypes } from '../store/order/orderActions/orderActionTypes';

const initialState = {
  status: null,
};

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case errorActionTypes.SET_LOG_IN_ERROR:
    case errorActionTypes.SET_GENERAL_ERROR:
      return {
        ...state,
        status: action.payload,
      };

    case errorActionTypes.CLEAR_LOG_IN_ERROR:
    case errorActionTypes.CLEAR_GENERAL_ERROR:
      return {
        ...state,
        status: null,
      };
    case orderActionTypes.VALIDATE_ORDER_ERROR:
      return {
        ...state,
        status: generalErrors.VALIDATION_ERROR,
        data: action.payload,
      };

    default:
      return state;
  }
}
