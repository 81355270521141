import { menuReportPrintType, periodMenuType } from '../../../../../../constants';
import { generatePrintMenuReport } from './GeneratePrintMenuReport';

/**
 * @description PrintableMenuReport is used to print report that shows who and what has ordered for each day of the week
 *
 * This component accepts menuReportData which is a map of a week day to orders for that day.
 */
function PrintableMenuReport({ menuReportData, menu }) {
  return generatePrintMenuReport(menuReportData, menu);
}

PrintableMenuReport.propTypes = {
  menuReportData: menuReportPrintType.isRequired,
  menu: periodMenuType.isRequired,
};

export default PrintableMenuReport;

