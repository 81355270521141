import { reportActionsTypes } from '../reportActions/reportActionsTypes';

const initialState = {
  data: null,
  isLoading: false,
};

function persistMenuReportDataToState(menuReportStateData, payload) {
  return menuReportStateData === null ? payload : {
    ...menuReportStateData,
    ...payload,
  };
}

export default function menuReportReducer(state = initialState, action) {
  switch (action.type) {
    case reportActionsTypes.FETCH_MENU_REPORT_START:
      return {
        ...state,
        isLoading: true,
      };

    case reportActionsTypes.FETCH_MENU_REPORT_SUCCESS:
      return {
        data: persistMenuReportDataToState(state.data, action.payload),
        isLoading: false,
      };

    case reportActionsTypes.NO_MENU_REPORT_AVAILABLE:
      return {
        data: null,
        isLoading: false,
      };

    default:
      return state;
  }
}
