import React, { useState } from 'react';
import { any, bool, func } from 'prop-types';
import Calendar from 'react-calendar';

import { Grid, Popover } from '@mui/material';

import i18n from '../../i18n';
import { weekPickerStyles } from './weekPickerStyles';
import { dateType } from '../../constants/types';

import 'react-calendar/dist/Calendar.css';
import { LocalDate, nativeJs } from '@js-joda/core';

export function usePopoverState() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setPopoverOpened] = useState(false);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    setPopoverOpened(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverOpened(false);
  };

  return ({
    anchorEl,
    open,
    openPopover: handlePopoverOpen,
    onClose: handlePopoverClose,
  });

}

export function CalendarPopover({ anchorEl, onClose, open, date, onDateChange }) {
  const nativeDate = new Date(date.toString());

  const handleDateChange = date => {
    onDateChange(LocalDate.from(nativeJs(date)));
    onClose();
  };

  return (
    <Popover
      id="calendar-popover"
      keepMounted
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
      transformOrigin={{ vertical: 'top', horizontal: 'center', }}
      sx={weekPickerStyles.popover}>

      <Grid
        id="calendar-container-grid"
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={weekPickerStyles.calendarContainer}>

        <Calendar
          value={nativeDate}
          onChange={handleDateChange}
          locale={i18n.language} />
      </Grid>
    </Popover>
  );
}

CalendarPopover.propTypes = {
  open: bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: any,
  onClose: func,
  onDateChange: func.isRequired,
  date: dateType.isRequired,
};
