import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';

import App from '../../App';
import { LoadingSpinnerProvider } from '../LoadingSpinnerProvider';

import { userProfileType } from '../../constants';
import { withConnectionErrorHandler } from '../../utils/HOCs';

function AuthorizationController(props) {
  const { isLoading, loadUserProfile, userProfile } = props;

  useEffect(() => {
    if (!userProfile && !isLoading) {
      loadUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpinnerProvider isLoading={!userProfile}>
      <App />
    </LoadingSpinnerProvider>
  );
}

AuthorizationController.defaultProps = {
  userProfile: null,
};

AuthorizationController.propTypes = {
  userProfile: userProfileType,
  isLoading: bool.isRequired,
  loadUserProfile: func.isRequired,
};

export default withConnectionErrorHandler(AuthorizationController);
