import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { getPagesResponsiveHeights } from '../../../../../constants';

export const StyledRootContainer = styled(Grid)(({ theme }) => ({
  ...getPagesResponsiveHeights(theme),
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 1,
  flexWrap: 'wrap',
}));

export const StyledContentGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: 'inherit',
  width: 'inherit',
  [theme.breakpoints.down('sm')]: {
    order: 1,
  },
}));

export const StyledToolbarContainer = styled(Grid)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    flexShrink: 0,
    height: 'fit-content',
    marginBottom: 8,
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
    flexShrink: 1,
    height: 'inherit',
  },
}));
