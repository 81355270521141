/* eslint-disable react/prop-types */
import React from 'react';
import { bool } from 'prop-types';

import { CircularProgress, Grid, Paper } from '@mui/material';

const styles = {
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
};

function ProgressCircularSpinner(props) {
  return (
    <Paper sx={styles.flexContainer}>
      <Grid sx={styles.flexContainer}
            container
            justifyContent="center"
            alignItems="center">
        <CircularProgress {...props} />
      </Grid>
    </Paper>
  );
}

export function LoadingSpinnerProvider({ children, isLoading }) {
  return (isLoading ? <ProgressCircularSpinner /> : children);
}

LoadingSpinnerProvider.propTypes = {
  isLoading: bool.isRequired,
};

export const withLoadingSpinner = WrappedComponent => props => {
  return (
    <LoadingSpinnerProvider isLoading={props.isLoading}>
      <WrappedComponent {...props} />
    </LoadingSpinnerProvider>
  );
};
