import axiosInstance from '../../../../axiosInstance';
import { fetchProfile, fetchProfileSuccess, } from './authorizationActions';
import { setGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';

export const fetchUserProfile = () => dispatch => {
  dispatch(fetchProfile());
  return axiosInstance.get('/profile')
                      .then(response => response && dispatch(fetchProfileSuccess(response.data)))
                      .catch(error => dispatch(setGeneralError(error)));
};
