import React from 'react';
import { bool, func } from 'prop-types';

import { Paper } from '@mui/material';

import ReportPrintButtons from '../MenuReportPrint/ReportPrintButtons';
import { UsersAutocompleteSearch } from '../../../../Inputs/UsersAutocompleteSearch';
import { PeriodMenuDetails } from '../../../Foodlord/PeriodMenu/components/PeriodMenuDetails';
import { MobileDrawer } from '../../../../Drawers';
import { CloseButton } from '../../../../Buttons';

import { componentType, themePresets } from '../../../../../constants';

const styles = {
  closeDrawerButton: {
    display: 'flex',
    marginTop: 'auto',
  },
  flexColumnContainer: {
    ...themePresets.flexColumnContainer,
    height: 'inherit',
  },
  divider: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  paperRoot: {
    margin: 0,
    padding: 0,
  },
};

function DesktopPanel(props) {
  return (
    <Paper sx={{ ...styles.flexColumnContainer, ...styles.paperRoot }}>
      {props.children}
    </Paper>
  );
}

DesktopPanel.propTypes = {
  children: componentType
};

DesktopPanel.defaultProps = {
  children: ''
};

function PanelResponsiveContainer(props) {
  if (props.isWideScreen) {
    return (
      <DesktopPanel>
        {props.children}
      </DesktopPanel>
    );
  }

  return (
    <MobileDrawer open={props.isOpen}
                  openDrawer={props.open}
                  closeDrawer={props.close}
                  sx={styles.flexColumnContainer}
                  anchor="right">

      {props.children}

      <CloseButton onClick={props.close}
                   sx={styles.closeDrawerButton} />
    </MobileDrawer>
  );
}

PanelResponsiveContainer.propTypes = {
  isWideScreen: bool.isRequired,
  isOpen: bool.isRequired,
  open: func.isRequired,
  close: func.isRequired,
};

function MenuReportPanel(props) {
  const { showPrintButton, ...restProps } = props;

  return (
    <PanelResponsiveContainer {...restProps}>
      {restProps.isWideScreen ? <PeriodMenuDetails /> : ''}
      {showPrintButton ? <ReportPrintButtons /> : ''}
      <UsersAutocompleteSearch />
    </PanelResponsiveContainer>
  );
}

MenuReportPanel.propTypes = {
  isWideScreen: bool.isRequired,
  showPrintButton: bool.isRequired,
  isOpen: bool.isRequired,
  open: func.isRequired,
  close: func.isRequired,
};

export default MenuReportPanel;
