import { connect } from 'react-redux';

import LatestVersionLoader from '../../Common/LatestVersionLoader';

import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';
import { fetchPeriodMenu, selectLastDraftPeriodMenu, selectPeriodMenuIsLoading } from '../../../../store/menu';
import { getNowDate } from '../../../../utils/dates';

function getMenuDate(state) {
  const menu = selectLastDraftPeriodMenu(state);

  return menu ? menu.firstDate : getNowDate();
}

const mapStateToProps = state => ({
  isLoading: selectPeriodMenuIsLoading(state),
  date: getMenuDate(state),
});

const mapDispatchToProps = dispatch => ({
  load: dateRange => {
    dispatch(clearGeneralError());
    dispatch(fetchPeriodMenu(dateRange));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestVersionLoader);
