import { connect } from 'react-redux';

import SideMenuButton from './SideMenuButton';

import { closeSideMenu, openSideMenu } from '../../../store/components';

const mapStateToProps = state => ({
  // TODO move to selector
  isSideMenuOpen: state.components.sideMenu.isSideMenuOpen,
});

const mapDispatchToProps = dispatch => ({
  dispatchOpenSideMenu: () => dispatch(openSideMenu()),
  dispatchCloseSideMenu: () => dispatch(closeSideMenu()),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...ownProps,
  getOnClickHandler: matchesBigScreen => {
    const { dispatchOpenSideMenu, dispatchCloseSideMenu } = propsFromDispatch;
    const { isSideMenuOpen } = propsFromState;

    const getDesktopOnClick = () => (isSideMenuOpen ? dispatchCloseSideMenu : dispatchOpenSideMenu);

    return matchesBigScreen ? getDesktopOnClick() : dispatchOpenSideMenu;
  },

});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SideMenuButton);
