import React, { useCallback, useState } from 'react';
import { func } from 'prop-types';

import DeleteButton from './DeleteButton';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

function DeleteDishButton(props) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const handleOpen = useCallback(() => setIsDialogOpened(true), [setIsDialogOpened]);
  const handleClose = useCallback(() => setIsDialogOpened(false), [setIsDialogOpened]);

  return (
    <>
      <DeleteButton onClick={handleOpen} />
      <ConfirmDialog
        isOpened={isDialogOpened}
        title="delete-this-dish"
        description="you-wont-be-able-to-undo"
        primaryButtonText="button.delete"
        onClose={handleClose}
        onConfirm={props.onConfirm} />
    </>
  );
}

DeleteDishButton.propTypes = {
  onConfirm: func.isRequired,
};

export default DeleteDishButton;
