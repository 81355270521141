import React from 'react';

import { Box } from '@mui/material';

const styles = {
  listGroup: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 1,
    paddingBottom: 1,
  },
};

function ListGroup({ children, ...restProps }) {
  return (
    <Box sx={styles.listGroup} {...restProps}>
      {children}
    </Box>
  );
}

export default ListGroup;
