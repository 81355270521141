import React from 'react';
import { bool, shape, string } from 'prop-types';

import { Typography, useMediaQuery } from '@mui/material';

import { ItemContent, ItemHeader } from '../../Templates';
import DishDescription from './DishDescription';
import EverydayDishTooltip from './EverydayDishTooltip';
import DishInfoTooltip from './DishInfoTooltip';

export default function DishContent(props) {
  const {
    description,
    isEveryday,
    isSelectable,
    name,
    withTooltip,
    renderOptionProps,
    withDescriptionIndent,
    ...restProps
  } = props;
  const matchesMediumOrSmallScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const tooltipRenderCondition = Boolean(withTooltip && description && matchesMediumOrSmallScreen);
  const descriptionRenderCondition = Boolean(description && !matchesMediumOrSmallScreen);

  const headerSx = withDescriptionIndent ? ({ marginRight: 1 }) : ({});
  return (
    <ItemContent id={`dish-content-${name}`}
                 isSelectable={isSelectable}
                 renderOptionProps={renderOptionProps}
                 {...restProps}>
      <ItemHeader sx={headerSx}>
        <Typography data-test-id={name}>{name}</Typography>
        {isEveryday ? <EverydayDishTooltip /> : ''}
        {tooltipRenderCondition ? <DishInfoTooltip text={description} /> : ''}
      </ItemHeader>
      {
        descriptionRenderCondition ? <DishDescription text={description} /> : ''
      }
    </ItemContent>
  );
}

DishContent.propTypes = {
  name: string.isRequired,
  description: string,
  isSelectable: bool,
  withTooltip: bool,
  withDescriptionIndent: bool,
  isEveryday: bool.isRequired,
  /**
   * used for correct Option rendering in MUI MenuItemAutosuggest
   * (check `renderOptionProps` prop API of MenuItemAutosuggest)
   */
  renderOptionProps: shape({})
};

DishContent.defaultProps = {

  description: null,
  isSelectable: false,
  withTooltip: false,
  withDescriptionIndent: false,
  renderOptionProps: {}
};
