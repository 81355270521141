import { selectProfileId } from '../auth';
import { isAnyOfEntitiesLoading, isLoadingEntitiesFailedDueToConnectionError } from '../../utils/selectors';
import { compareByOrderState, compareByUserName, getComparatorByCurrentUser } from '../../utils/comparators';
import { selectActiveDate } from '../activeState';
import { MenuReportItem } from '../../models/internal/Report';

// _____________MenuReport___________________________________________

export const selectMenuReport = state => state.menuReport.data;

export const selectMenuReportAndDepsLoading = state => (
  isAnyOfEntitiesLoading(state, 'menuReport', 'categories', 'menu')
);

export const selectMenuReportAndDepsLoadingFailed = state => (
  isLoadingEntitiesFailedDueToConnectionError(state, 'menuReport', 'categories', 'menu')
);

export const selectMenuReportWithLoggedUserOnTop = state => {
  const currentUserId = selectProfileId(state);
  const compareByCurrentUser = getComparatorByCurrentUser(currentUserId);

  return Object
    .entries(selectMenuReport(state) || {})
    .reduce((acc, [date, orders]) => {
      acc[date] = orders
        .sort(compareByUserName)
        .map((r, index) => new MenuReportItem({ ...r, index: index + 1 }))
        .sort(compareByCurrentUser);
      return acc;
    }, {});
};

export const selectMenuReportWithLoggedUserOnTopForActiveDate = state => {
  const activeDate = selectActiveDate(state);
  return selectMenuReportWithLoggedUserOnTop(state)[activeDate.toString()];
};

export const selectIsMenuReportAvailable = state => {
  const menuReport = selectMenuReportWithLoggedUserOnTop(state);
  return (menuReport && Object.entries(menuReport).length !== 0);
};

// _____________VendorReport___________________________________________

export const selectVendorReport = state => state.vendorReport.data;

export const selectVendorReportAndDepsLoading = state => (
  isAnyOfEntitiesLoading(state, 'vendorReport', 'menu')
);

export const selectVendorReportAndDepsLoadingFailed = state => (
  isLoadingEntitiesFailedDueToConnectionError(state, 'vendorReport', 'menu')
);

// _____________SlowpokeReport___________________________________________

export const selectSlowpokeReport = state => {
  return state.slowpokeReport.data && [...state.slowpokeReport.data]
    .sort(compareByUserName)
    .sort(compareByOrderState);
};

export const selectSlowpokeReportAndDepsLoading = state => (
  isAnyOfEntitiesLoading(state, 'slowpokeReport', 'menu')
);

export const selectSlowpokeReportAndDepsLoadingFailed = state => (
  isLoadingEntitiesFailedDueToConnectionError(state, 'slowpokeReport', 'menu')
);
