import React from 'react';
import { bool, func, string } from 'prop-types';
import Dialog from './Dialog';

function RejectDialog(props) {
  const {
    isOpened, title, description, primaryButtonText, onClose,
  } = props;

  return (
    <Dialog
      isOpened={isOpened}
      title={title}
      description={description}
      onClose={onClose}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={onClose} />
  );
}

RejectDialog.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  primaryButtonText: string.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
};

export default RejectDialog;
