import { connect } from 'react-redux';

import UserDailyOrder from './UserDailyOrder';
import { getReducedDishesNames } from '../../../../../../store/report';
import { selectCategories } from '../../../../../../utils/selectors/categorySelectors';

const getDishName = categoryDishes => ((categoryDishes.length === 0)
                                       ? 'nothing-ordered'
                                       : getReducedDishesNames(categoryDishes));

const getCategoryIcon = (name, categories) => {
  const categoryItem = categories.find(category => category.name === name);
  return categoryItem && categoryItem.icon;
};

const formOrderedDishes = (userOrder, categories) => userOrder
  .map(({ category, dishes }) => ({
    categoryName: category,
    categoryIcon: getCategoryIcon(category, categories),
    dishName: getDishName(dishes),
  }));

const mapStateToProps = (state, { userOrder, userProfile, index }) => {
  let userOrderedDishes = [];
  const categories = selectCategories(state);

  if (userOrder && categories) {
    userOrderedDishes = formOrderedDishes(userOrder, categories);
  }

  return ({
    userOrderedDishes,
    userProfile,
    index,
  });
};

export default connect(mapStateToProps)(UserDailyOrder);
