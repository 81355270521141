/* eslint-disable */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
// import logger from 'redux-logger';
import dishesReducer from '../reducers/dishesReducer';
import categoriesReducer from '../reducers/categoriesReducer';
import errorReducer from '../reducers/errorReducer';
import menusReducer from './menu/menusReducer';
import successReducer from '../reducers/successReducer';
import ordersReducer from './order/ordersReducer';
import authenticationReducer from './auth/authenticationReducer';
import authorizationReducer from './auth/authorizationReducer';
import locationReducer from '../reducers/locationReducer';
import vendorReportReducer from './report/reducers/vendorReportReducer';
import menuReportReducer from './report/reducers/menuReportReducer';
import slowpokeReportReducer from './report/reducers/slowpokeReportReducer';
import sideMenuReducer from './components/componentReducers/sideMenuReducer';
import usersReducer from './users/usersReducer';
import reportPanelReducer from './components/componentReducers/reportPanelReducer';
import dishesPageReducer from './components/componentReducers/dishesPageReducer';
import { activeStateReducer } from './activeState';

// noinspection ES6PreferShortImport
import { ORDER_STATE_KEY } from './order/orderSelectors';

const componentsReducer = combineReducers({
                                            sideMenu: sideMenuReducer,
                                            reportPanel: reportPanelReducer,
                                            dishesPage: dishesPageReducer,
                                          });

const rootReducer = combineReducers({
                                      active: activeStateReducer,
                                      components: componentsReducer,
                                      dishes: dishesReducer,
                                      categories: categoriesReducer,
                                      menu: menusReducer,
                                      error: errorReducer,
                                      success: successReducer,
                                      [ORDER_STATE_KEY]: ordersReducer,
                                      authentication: authenticationReducer,
                                      authorization: authorizationReducer,
                                      location: locationReducer,
                                      vendorReport: vendorReportReducer,
                                      menuReport: menuReportReducer,
                                      slowpokeReport: slowpokeReportReducer,
                                      users: usersReducer,
                                    });

const isProduction = process.env.NODE_ENV === 'production';

// read more `GetDefaultMiddlewareOptions` --> `node_modules/@reduxjs/toolkit/src/getDefaultMiddleware.ts`
const middleware = (getDefaultMiddleware) => {
  const defaultMiddlewaresConfig = {
    // serializableCheck: {},
    serializableCheck: false,
    immutableCheck: {},
    // immutableCheck: false,
    thunk: {}
    // thunk: false
  };

  return isProduction ? [thunkMiddleware] :
         getDefaultMiddleware(defaultMiddlewaresConfig)
  // .concat(logger);
};

const store = configureStore({
                               reducer: rootReducer,
                               middleware,
                               devTools: !isProduction,
                             });

export default store;
