import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import DishFormContainer from './DishFormContainer';

import { addDish } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import { getCategoryFromUrl } from '../../../../utils/selectors/categorySelectors';

const mapStateToProps = (state, ownProps) => ({
  dish: {
    id: 0,
    name: '',
    officialName: '',
    description: '',
    price: 0,
    category: getCategoryFromUrl(state, ownProps.location.search),
    isEveryday: false,
    blockedCategories: [],
  },
});

const mapDispatchToProps = {
  save: addDish,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    DishFormContainer,
  ),
);
