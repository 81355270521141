import React from 'react';

import { Box, Paper, Typography } from '@mui/material';

import { PeriodMenuStatus } from './components';

import { dateType, periodMenuType } from '../../../../../../constants';
import { getDateLabelForWeekTab } from '../../../../../../utils/dates';

const _format = date => getDateLabelForWeekTab(date);

const styles = {
  rootBoxStyles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  widget: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: 1,
    width: 'inherit'
  }
};

function DateRange({ firstDate, lastDate }) {
  return (
    <Typography sx={{ fontWeight: 500 }} fontSize="large">
      {_format(firstDate) + ' - ' + _format(lastDate)}
    </Typography>
  );
}

DateRange.propTypes = { firstDate: dateType.isRequired, lastDate: dateType.isRequired };

export function PeriodMenuDetails(props) {
  return (
    <Box id="period-menu-details-root-container" sx={styles.rootBoxStyles}>
      <Paper id="period-menu-details-widget" sx={styles.widget} elevation={0}>

        <DateRange firstDate={props.menu.firstDate} lastDate={props.menu.lastDate} />

        <PeriodMenuStatus status={props.menu.status} />

      </Paper>
    </Box>
  );
}

PeriodMenuDetails.propTypes = {
  menu: periodMenuType,
};

PeriodMenuDetails.defaultProps = {
  menu: null,
};

