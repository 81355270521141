import { LastOrderPage, OrderPage as EaterOrderPage } from '../../components/pages/Eater/OrderPage';

import OrderIconSvg from '../../images/menuIcons/order-figma.svg';

import { eaterRoutes } from '../eater/eaterRoutes';

const EaterPagesConfig = {
  [eaterRoutes.ORDER]: {
    link: eaterRoutes.ORDER,
    component: EaterOrderPage,
    title: 'routes.order',
    icon: null,
  },
  [eaterRoutes.LAST_ORDER]: {
    link: eaterRoutes.LAST_ORDER,
    component: LastOrderPage,
    title: 'routes.order',
    icon: OrderIconSvg,
    displayInSideMenu: true,
  },
};

export default EaterPagesConfig;
