import { toLocalDate } from '../utils/dates';

export class DateRange {
  constructor(dateRange) {
    const { firstDate, lastDate } = dateRange;

    Object.assign(this, {
      firstDate: toLocalDate(firstDate),
      lastDate: toLocalDate(lastDate),
    });
  }
}

export class DateRangeDto {
  constructor(dateRange) {
    const { firstDate, lastDate } = dateRange;

    Object.assign(this, {
      firstDate: firstDate.toString(),
      lastDate: lastDate.toString(),
    });
  }
}
