import { connect } from 'react-redux';

import LatestVersionLoader from '../../Common/LatestVersionLoader';

import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';
import { fetchPeriodMenu, selectLastPublishedPeriodMenu, selectPeriodMenuIsLoading } from '../../../../store/menu';
import { fetchPeriodOrders } from '../../../../store/order';

import { getNowDate } from '../../../../utils/dates';

function selectDateForRedirect(state) {
  const menu = selectLastPublishedPeriodMenu(state);

  return menu ? menu.firstDate : getNowDate();
}

const mapStateToProps = state => ({
  isLoading: selectPeriodMenuIsLoading(state),
  date: selectDateForRedirect(state),
});

const mapDispatchToProps = dispatch => ({
  load: (dateRange, activeDate) => {
    dispatch(clearGeneralError());
    dispatch(fetchPeriodMenu(dateRange));
    dispatch(fetchPeriodOrders(dateRange, activeDate));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestVersionLoader);
