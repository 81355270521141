import { dishesActionTypes } from '../actions/luncherActions/dishesActions/dishesActions';
import { sortDishes } from '../utils/entitiesUtils/dishesUtils';

const initialState = {
  data: [],
  isLoading: true,
};

function insertUpdatedDish(state, updatedDish) {
  return state.data.map(dish => (
    (dish.id === updatedDish.id) ? updatedDish : dish
  ));
}

function insertNewDish(state, newDish) {
  return [newDish, ...state.data];
}

function removeDeletedDish(state, deletedDish) {
  return state.data.filter(dish => dish.id !== deletedDish);
}

export default function dishesReducer(state = initialState, action) {
  switch (action.type) {
    case dishesActionTypes.FETCH_DISHES_START:
      return {
        ...state,
        isLoading: true,
      };

    case dishesActionTypes.FETCH_DISHES_SUCCESS:
      return {
        ...state,
        data: sortDishes(action.payload),
        isLoading: false,
      };

    case dishesActionTypes.SORT_DISHES_BY_NAME:
      return {
        ...state,
        data: sortDishes(state.data),
      };

    case dishesActionTypes.ADD_DISH_SUCCESS:
      return {
        ...state,
        data: sortDishes(insertNewDish(state, action.payload)),
      };

    case dishesActionTypes.UPDATE_DISH_SUCCESS:
      return {
        ...state,
        data: sortDishes(insertUpdatedDish(state, action.payload)),
      };

    case dishesActionTypes.DELETE_DISH_SUCCESS:
      return {
        ...state,
        data: removeDeletedDish(state, action.payload),
      };

    default:
      return state;
  }
}
