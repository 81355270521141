export const reportActionsTypes = {
  FETCH_VENDOR_REPORT_START: '[VENDOR_REPORT] Fetch vendor report start',
  FETCH_VENDOR_REPORT_SUCCESS: '[VENDOR_REPORT] Fetch vendor report success',
  NO_VENDOR_REPORT_AVAILABLE: '[VENDOR_REPORT] No vendor report available',

  FETCH_MENU_REPORT_START: '[MENU_REPORT] Fetch menu report start',
  FETCH_MENU_REPORT_SUCCESS: '[MENU_REPORT] Fetch menu report success',
  NO_MENU_REPORT_AVAILABLE: '[MENU_REPORT] No menu report available',

  FETCH_SLOWPOKE_REPORT_START: '[SLOWPOKE_REPORT] Fetch slowpoke report start',
  FETCH_SLOWPOKE_REPORT_SUCCESS: '[SLOWPOKE_REPORT] Fetch slowpoke report success',
  NO_SLOWPOKE_REPORT_AVAILABLE: '[SLOWPOKE_REPORT] No slowpoke report available',
};
