import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Typography, useMediaQuery } from '@mui/material';

import { MessageContainer, MessageImage, MessageText } from './Templates';
import { iconType } from '../../constants';

export function Message({ children, message, image, imageBox, }) {
  const { t: localize } = useTranslation();
  const matchesSmallScreen = useMediaQuery(theme => theme.breakpoints.down('lg'));

  return (
    <MessageContainer>
      <MessageImage
        src={image}
        alt={localize(message)}
        viewBox={imageBox} />
      <MessageText>
        <Typography variant={matchesSmallScreen ? 'h5' : 'h4'}>
          {localize(message)}
        </Typography>
      </MessageText>
      {children}
    </MessageContainer>
  );
}

Message.propTypes = {
  message: string.isRequired,
  image: iconType.isRequired,
  imageBox: string.isRequired,
};

export default Message;
