import { fetchUserNamesStart, fetchUserNamesSuccess, } from './usersActions';
import axiosInstance from '../../../axiosInstance';
import { setGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';

export const fetchUserNames = () => dispatch => {
  dispatch(fetchUserNamesStart());
  return axiosInstance.get('/users/names')
                      .then(response => response && dispatch(fetchUserNamesSuccess(response.data)))
                      .catch(error => dispatch(setGeneralError(error)));
};

export default fetchUserNames;
