const compareStrings = (str1, str2) => {
  if (str1 < str2) {
    return -1;
  }

  if (str1 > str2) {
    return 1;
  }

  return 0;
};

const compareBoolean = (bool1, bool2) => {
  if (bool2) {
    return -1;
  }

  if (bool1) {
    return 1;
  }

  return 0;
};

export const compareObjectsByNameField = ({ name: name1 }, { name: name2 }) => compareStrings(name1, name2);

export const compareByPosition = (item1, item2) => item1.position - item2.position;

export const compareMenuItemsByDishNames = ({ food: food1 }, { food: food2 }) => {
  return compareObjectsByNameField(food1, food2);
};

export const compareOrderItemsByPosition = (item1, item2) => compareByPosition(item1.menuItem, item2.menuItem);

// eslint-disable-next-line max-len
export const compareMenuItemsByIsEverydayField = (item1, item2) => compareBoolean(item1.food.isEveryday,
                                                                                  item2.food.isEveryday);
export const compareMenuItemsByFoodName = (a, b) => a.food.name.localeCompare(b.food.name);

// first go newer, last go older
export const lastDateComparator = (wo1, wo2) => {
  return wo1.lastDate.isAfter(wo2.lastDate) ? -1 : 1;
};

export const compareByOrderState = (a, b) => {
  if (a.ordered === b.ordered) {
    return 0;
  }
  return a.ordered ? 1 : -1;
};
export const compareByUserName = (a, b) => a.user.name.localeCompare(b.user.name);

export const getComparatorByCurrentUser = currentUserId => (a, b) => {
  if (a.user.id === currentUserId) {
    return -1;
  }
  if (b.user.id === currentUserId) {
    return 1;
  }
  return 0;
};
