import axiosInstance from '../../../axiosInstance';
import {
  createPeriodOrderStart,
  createPeriodOrderSuccess,
  fetchOrdersSuccess,
  fetchPeriodOrdersStart,
  periodOrderCreationError,
  setDraftDailyOrder,
  updateDailyOrderSuccess,
  updatePeriodOrderSuccess,
} from './orderActions';
import { setGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';

import { DailyOrder, DailyOrderDto, PeriodOrder, PeriodOrderDto } from '../../../models';
import { DateRangeDto } from '../../../models/DateRange';

export const createPeriodOrder = dateRange => dispatch => {
  dispatch(createPeriodOrderStart());
  return axiosInstance
    .post('/period-orders', new DateRangeDto(dateRange))
    .then(res => res && dispatch(createPeriodOrderSuccess(new PeriodOrder(res.data))))
    .catch(error => dispatch(periodOrderCreationError(error)));
};

/**
 * @param dateRange {DateRange}
 */
export const fetchPeriodOrders = dateRange => dispatch => {
  dispatch(fetchPeriodOrdersStart());

  return axiosInstance
    .get('/period-orders/', { params: new DateRangeDto(dateRange) })
    .then(res => {
      if (res?.data) {
        const periodOrders = res.data.map(PO => new PeriodOrder(PO));

        return dispatch(fetchOrdersSuccess(periodOrders));
      }
    })
    .catch(error => dispatch(setGeneralError(error)));
};

export const updatePeriodOrder = periodOrder => dispatch => {
  return axiosInstance
    .put(`/period-orders/${periodOrder.id}`, new PeriodOrderDto({ ...periodOrder, ordered: true }))
    .then(res => res.data && dispatch(updatePeriodOrderSuccess(new PeriodOrder(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};

// ___DAILY_ORDER_______________________________________________________________________________________
/**
 * If dailyOrder is ordered we store updated model in local State and on demand we push these changes to BE
 * (in `updateAllDailyOrdersInPeriodOrder` action)
 * @param dailyOrder {DailyOrder}
 * @param ordered {boolean}
 * @returns {function(*): *|Promise<*>}
 */
export const updateDailyOrder = (dailyOrder, ordered) => dispatch => {
  return ordered
         ? dispatch(setDraftDailyOrder(dailyOrder)) :
         (
           axiosInstance.put(`/daily-orders/${dailyOrder.id}`, new DailyOrderDto(dailyOrder))
                        .then(res => res?.data && dispatch(updateDailyOrderSuccess(new DailyOrder(res.data))))
                        .catch(error => dispatch(setGeneralError(error)))
         );
};

export const updateAllDailyOrdersInPeriodOrder = periodOrder => dispatch => {
  return Promise
    .all(periodOrder
           .dailyOrders
           .map(dailyOrder => {
                  return axiosInstance
                    .put(`/daily-orders/${dailyOrder.id}`, new DailyOrderDto(dailyOrder))
                    .then(res => new DailyOrder(res.data));
                }
           ))
    .then(dailyOrders => dispatch(updatePeriodOrderSuccess(new PeriodOrder({ ...periodOrder, dailyOrders }))))
    .catch(error => dispatch(setGeneralError(error)));
};
