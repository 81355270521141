import { getWeekBounds } from '../../utils/dates';

export const selectActiveDate = state => state.active.activeDate;
export const selectActiveDateRange = state => state.active.activeDateRange;

/**
 * @param state {object}
 * @return {DateRange}
 */
export const selectWeekBoundsForActiveDay = state => getWeekBounds(selectActiveDate(state));
