import React from 'react';

import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { DayPicker } from '../../../../DayPicker';
import { DailyOrderCategories } from './DailyOrderCategories';

import { dateToUrlSetter } from '../../../../../utils/dates';
import { eaterPath } from '../../../../../routes/eater/eaterRoutes';

export const DatePickerGridFlexContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
  height: 'inherit',
}));

export function DayPickerOrderPage() {
  return (
    <DatePickerGridFlexContainer id="order-period-menu"
                            container>
      <DayPicker handleDateChange={dateToUrlSetter(eaterPath.ORDER)}>
        <DailyOrderCategories />
      </DayPicker>
    </DatePickerGridFlexContainer>
  );
}

DayPickerOrderPage.propTypes = {};
