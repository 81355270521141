import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool } from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ListGroup } from '../../../../../../ListItems/ListGroup';
import { CategoryTitle } from '../../../../../../ListItems/CategoryTitle';
import { SelectableDishItem } from '../SelectableDishItem';

import { categoryType, menuItemType, refType } from '../../../../../../../constants';
import {
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary
} from './OrderCategoryAccordionStyledComponents';

function DishItems(props) {
  return props.menuItems
              .map((item, index, menuItems) => {
                const isLastElement = index === (menuItems.length - 1);

                return (
                  <SelectableDishItem
                    data-test-id={`${item.food.name}-item`}
                    key={item.id}
                    menuItem={item}
                    forwardRef={isLastElement ? props.lastDishRef : null} />
                );
              });
}

DishItems.propTypes = {
  menuItems: arrayOf(menuItemType).isRequired,
  lastDishRef: refType.isRequired,
};

function scrollIntoViewLastDishItemOnPanelOpening(lastDishRef, isExpansionPanelOpen) {
  if (lastDishRef.current && isExpansionPanelOpen) {
    const timer = setTimeout(() => scrollIntoView(lastDishRef.current, {
      scrollMode: 'if-needed',
      block: 'end',
    }), 200);

    return () => clearTimeout(timer);
  }
}

function OrderCategoryAccordion(props) {
  const { menuItems, category, isDefaultOpen, isAnyItemForCategoryOrdered } = props;

  const [isExpansionPanelOpen, setExpansionPanelOpen] = useState(isDefaultOpen);
  const lastDishRef = useRef(null);

  useEffect(() => {
    return scrollIntoViewLastDishItemOnPanelOpening(lastDishRef, isExpansionPanelOpen);
  }, [isExpansionPanelOpen, lastDishRef]);

  return (
    <ListGroup data-test-id={`${category.name}-order-group`}>
      <StyledAccordion expanded={isExpansionPanelOpen}
                       data-test-id={`${category.name}-accordion`}
                       onChange={(e, isOpen) => setExpansionPanelOpen(isOpen)}>
        <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CategoryTitle
            category={category}
            withBadge={isAnyItemForCategoryOrdered}
            color="primary" />
        </StyledAccordionSummary>

        <StyledAccordionDetails>
          <DishItems
            menuItems={menuItems}
            lastDishRef={lastDishRef} />
        </StyledAccordionDetails>

      </StyledAccordion>
    </ListGroup>
  );
}

OrderCategoryAccordion.defaultProps = {
  isDefaultOpen: false,
  isAnyItemForCategoryOrdered: false,
};

OrderCategoryAccordion.propTypes = {
  category: categoryType.isRequired,
  menuItems: arrayOf(menuItemType).isRequired,
  isDefaultOpen: bool,
  isAnyItemForCategoryOrdered: bool,
};

export default OrderCategoryAccordion;
