import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';

import { DeleteMenuAction, PublishMenuAction, PurchaseMenuAction, UnpublishMenuAction } from './period';
import { CreateDailyMenuAction, RemoveDailyMenuAction } from './day';
import { periodMenuType } from '../../../../../../constants';

export function MenuActions(props) {
  const { isDraft, isPublished, isPurchased, isDayAddable, isDayRemovable } = props;
  const [components, setComponents] = useState([]);

  const addIfTrue = (condition, ...args) => condition && setComponents(args);

  useEffect(() => {
    addIfTrue(isDraft(),
      <PublishMenuAction key={'publish-menu-action'} />,
              isDayAddable() ? <CreateDailyMenuAction key={'create-daily-menu-action'} /> : '',
              isDayRemovable() ? <RemoveDailyMenuAction key={'remove-daily-menu-action'} /> : '',
      <DeleteMenuAction key={'delete-menu-action'} />,
    );
    addIfTrue(isPublished(),
      <UnpublishMenuAction key={'unpublish-menu-action'} />,
      <PurchaseMenuAction key={'purchase-menu-action'} />,
    );
    addIfTrue(isPurchased(), '');
  }, [isDraft, isPublished, isPurchased, isDayAddable, isDayRemovable]);

  return components;
}

MenuActions.propTypes = {
  menu: periodMenuType,

  isDraft: func.isRequired,
  isPurchased: func.isRequired,
  isPublished: func.isRequired,
  isDayAddable: func.isRequired,
  isDayRemovable: func.isRequired,
};

MenuActions.defaultProps = {
  menu: null,
};

