import React from 'react';
import { func } from 'prop-types';

import EditButton from '../../Buttons/EditButton';
import Item from '../Templates/Item';
import ItemContent from '../Templates/ItemContent';
import ItemHeader from '../Templates/ItemHeader';
import ItemToolbar from '../Templates/ItemToolbar';
import DeleteCategory from '../../Buttons/DeleteCategoryButton/DeleteCategoryButtonContainer';

import { foodlordPath } from '../../../routes/foodlord/foodlordRoutes';
import { categoryType } from '../../../constants';

function Category(props) {
  return (
    <Item id={`${props.category.name}-category-item`} useOnHover>
      <ItemContent>
        <ItemHeader>
          {props.category.name}
        </ItemHeader>
      </ItemContent>
      <ItemToolbar>
        <EditButton id="category-item-edit-button"
                    href={`${foodlordPath.EDIT_CATEGORY}/${props.category.id}`} />
        <DeleteCategory id="category-item-delete-button"
                        onConfirm={props.deleteCategory}
                        category={props.category} />
      </ItemToolbar>
    </Item>
  );
}

Category.propTypes = {
  category: categoryType.isRequired,
  deleteCategory: func.isRequired,
};

export default Category;
