import React from 'react';

import { EmptyStickersRow, SingleDayStickers } from './components';

import { stickersReportPrintType } from '../../../../../constants';

import './components/StickersReportPrint.scss';

/**
 * @description PrintableStickersReport is used to print stickers
 * that are later attached to each container with a lunch in Intelliarts office.
 *
 * This component accepts stickersReportData which is two dimension array, where outer array separates week days,
 * and inner array separates stickers themselves.
 */
export default function PrintableStickersReport({ stickersReportData }) {
  return (
    <>
      {
        stickersReportData.map(stickersForSpecificDay => (
          <div key={Math.random()}>
            <SingleDayStickers stickers={stickersForSpecificDay} />
            <EmptyStickersRow />
          </div>
        ))
      }
    </>
  );
}

PrintableStickersReport.propTypes = {
  stickersReportData: stickersReportPrintType.isRequired,
};
