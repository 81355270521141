import { ActiveStateActionsTypes } from './activeStateActionsTypes';
import { areDateRangesEqual, areDatesEqual, getDateRangeWithPrevAndNextTwoWeeks, getNowDate } from '../../utils/dates';

const initialState = {
  activeDate: getNowDate(),
  activeDateRange: getDateRangeWithPrevAndNextTwoWeeks(getNowDate()),
};

export function activeStateReducer(state = initialState, action) {
  switch (action.type) {
    case ActiveStateActionsTypes.SET_ACTIVE_DATE:
      return areDatesEqual(action.payload, state.activeDate) ? state : ({
        ...state,
        activeDate: action.payload,
      });

    case ActiveStateActionsTypes.SET_ACTIVE_DATE_RANGE:
      return areDateRangesEqual(action.payload, state.activeDateRange) ? state : ({
        ...state,
        activeDateRange: action.payload,
      });

    default:
      return state;
  }
}
