import axios from 'axios';
import store from './store';
import { setGeneralError, setLoginError } from './actions/luncherActions/errorActions/errorActions';
import generalErrors from './constants/generalErrors';

const BASE_URL = '/api';
const CONNECTION_TIMEOUT = 30 * 1000;

const axiosInstance = axios.create({
                                     baseURL: BASE_URL,
                                     headers: {
                                       Accept: 'application/json',
                                       'Content-Type': 'application/json',
                                     },
                                     timeout: CONNECTION_TIMEOUT,
                                   });

axiosInstance.interceptors.response.use(
  data => data,

  error => {
    if (error.response.status === 401) {
      window.location.replace(error.response.data);
    }
    if (error.response && error.response.status <= 500) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 504) {
      store.dispatch(setLoginError(generalErrors.API_LOGIN_ERROR));
      return Promise.resolve();
    }

    store.dispatch(setGeneralError(generalErrors.API_CALL_ERROR));
    return Promise.resolve();
  },
);

export default axiosInstance;
