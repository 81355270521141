import React from 'react';
import { arrayOf, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { categoryType } from '../../../../constants';
import { Category } from '../../../../models';

import './BlockedCategories.scss';

export default function BlockedCategories({ blockedCategories, disabled }) {
  const { t: localize } = useTranslation();

  return (
    <Box
      className="blocked-categories"
      sx={disabled ? { display: 'none' } : {}}>
      <Typography className="title">
        {localize('label.without')}
      </Typography>
      {
        blockedCategories.map(
          (bc, index, array) => {
            const DELIMITER = index === array.length - 1 ? '' : ',';
            const category = new Category(bc);

            return (
              <Typography key={category.id} className="item">
                {category.name}{DELIMITER}
              </Typography>
            );
          },
        )
      }
    </Box>
  );
}

BlockedCategories.propTypes = {
  disabled: bool.isRequired,
  blockedCategories: arrayOf(categoryType).isRequired,
};
