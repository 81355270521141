import React from 'react';
import { bool, func, number } from 'prop-types';

import { Box } from '@mui/material';

import { Counter } from '../../../../../../Buttons';
import { BlockedCategories } from '../../../../../../ListItems/DishItems/BlockedCategories';
import { DishContent, DishPrice } from '../../../../../../ListItems/DishItems/Dish';
import { Item } from '../../../../../../ListItems/Templates';

import { menuItemType, refType } from '../../../../../../../constants';

import './SelectableDishItem.scss';

function SelectableDishItem({
                              menuItem,
                              count,
                              isDishSelected,
                              isDisabled,
                              handleDishSelection,
                              handleDecreaseDishCounter,
                              handleIncreaseDishCounter,
                              forwardRef,
                              ...restProps
                            }) {
  return (
    <Box
      id={`selectable-dish-item-${menuItem?.food?.name}`}
      ref={forwardRef}
      role="button"
      tabIndex={0}
      onClick={handleDishSelection}
      onKeyPress={handleDishSelection}
      className={isDishSelected ? 'dish-order-selected' : 'dish-order'}>

      <Item isSelectable {...restProps}>
        <DishContent
          name={menuItem.food.name}
          description={menuItem.food.description}
          isEveryday={menuItem.food.isEveryday}
          isSelectable
          withTooltip />

        <BlockedCategories
          disabled={!menuItem.food.blockedCategories.length}
          blockedCategories={menuItem.food.blockedCategories} />

        <Box className="item-counter-and-price">
          <Counter
            count={count}
            disabled={isDisabled}
            onDecrease={handleDecreaseDishCounter}
            onIncrease={handleIncreaseDishCounter} />

          <DishPrice
            price={menuItem.food.price}
            isSelectable
            fixed />
        </Box>
      </Item>
    </Box>);
}

SelectableDishItem.propTypes = {
  menuItem: menuItemType.isRequired,
  count: number.isRequired,
  isDisabled: bool.isRequired,
  isDishSelected: bool.isRequired,
  handleDishSelection: func.isRequired,
  handleDecreaseDishCounter: func.isRequired,
  handleIncreaseDishCounter: func.isRequired,
  forwardRef: refType,
};

SelectableDishItem.defaultProps = {
  forwardRef: null,
};

export default SelectableDishItem;
