import React from 'react';
import { arrayOf, number, shape } from 'prop-types';

import { EditableDishItem } from '../../ListItems/DishItems/EditableDishItem';
import { NothingToShowMessage } from '../../Messages';

import { withAlternative } from '../../../utils/HOCs';
import { dishType, refType } from '../../../constants';

function DishItems(props) {
  return (
    props.dishes
         .map(dish => {
           const isNewDish = props.newDish.id === dish.id;

           return (
             <EditableDishItem
               key={dish.id}
               newDishRef={isNewDish ? props.newDish.ref : null}
               isHighlighted={isNewDish}
               dish={dish}
               selectedCategoryId={props.selectedCategoryId} />
           );
         })
  );
}

DishItems.defaultProps = {
  newDish: null,
};

DishItems.propsTypes = {
  dishes: arrayOf(dishType).isRequired,
  newDish: shape({
                   ref: refType,
                   ...dishType,
                 }),
  selectedCategoryId: number,
};

const noDishesAvailable = props => !props.dishes.length;

export default withAlternative(noDishesAvailable, NothingToShowMessage)(DishItems);
