import React, { useCallback, useState } from 'react';
import { bool, func } from 'prop-types';

import DeleteButton from '../DeleteButton';
import ConfirmDialog from '../../Dialogs/ConfirmDialog';
import RejectDialog from '../../Dialogs/RejectDialog';

function DeleteCategoryButton(props) {
  const { hasCategoryDishes, onConfirm } = props;
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  const handleOpen = useCallback(() => setIsDialogOpened(true), [setIsDialogOpened]);
  const handleClose = useCallback(() => setIsDialogOpened(false), [setIsDialogOpened]);

  const renderRejectDialog = () => (
    <RejectDialog
      isOpened={isDialogOpened}
      title="impossible"
      description="category-should-not-contain-dishes"
      primaryButtonText="button.cancel"
      onClose={handleClose} />
  );

  const renderConfirmDialog = () => (
    <ConfirmDialog
      isOpened={isDialogOpened}
      title="delete-this-category"
      description="you-wont-be-able-to-undo"
      primaryButtonText="button.delete"
      onClose={handleClose}
      onConfirm={onConfirm} />
  );

  return (
    <>
      <DeleteButton onClick={handleOpen} />
      {hasCategoryDishes ? renderRejectDialog() : renderConfirmDialog()}
    </>
  );
}

DeleteCategoryButton.propTypes = {
  onConfirm: func.isRequired,
  hasCategoryDishes: bool.isRequired,
};

export default DeleteCategoryButton;
