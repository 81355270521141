import React from 'react';
import { bool, func, string } from 'prop-types';
import Dialog from './Dialog';

function ConfirmDialog(props) {
  const {
    title,
    description,
    primaryButtonText,
    isOpened,
    onClose,
    onConfirm,
  } = props;

  return (
    <Dialog
      isOpened={isOpened}
      title={title}
      description={description}
      onClose={onClose}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={() => {
        onConfirm();
        onClose();
      }}
      withCancelButton />
  );
}

ConfirmDialog.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  primaryButtonText: string.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
};

export default ConfirmDialog;
