import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import { LAYOUT_COMPONENTS_HEIGHTS, themePresets } from '../../constants/themeConstants';

export const LayoutContentContainer = styled(Container)(({ theme }) => ({
  ...themePresets.flexRowContainer,
  alignItems: 'stretch',
  height: `calc(100vh - ${LAYOUT_COMPONENTS_HEIGHTS.header + 8}px)`,
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
}));

export const LayoutContentGrid = styled(Grid)(() => ({
  ...themePresets.flexColumnContainer,
  height: 'inherit',
  minHeight: 0,
  overflowY: 'hidden',
  overflowX: 'hidden',
}));

export const LayoutHeaderGrid = styled(Grid)(() => ({
  display: 'flex',
  height: LAYOUT_COMPONENTS_HEIGHTS.header,
  maxHeight: LAYOUT_COMPONENTS_HEIGHTS.header,
}));

export const LayoutRootGrid = styled(Grid)(() => ({
  display: 'flex',
  minHeight: 'inherit',
  height: 'inherit',
  flexDirection: 'column',
  alignItems: 'stretch',
}));
