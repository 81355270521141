import { toLocalDate } from '../../../utils/dates';
import { OrderItem } from './OrderItem';

export class DailyOrderDto {
  id;
  /**
   * @type{LocalDate}
   */
  date;
  /**
   * @type {OrderItem[]}
   */
  orderItems;

  /**
   * @param dailyOrder {DailyOrder}
   */
  constructor(dailyOrder) {
    Object.assign(this, {
      ...dailyOrder,
      date: dailyOrder.date.toString()
    });
  }
}

/**
 * @namespace DailyOrder
 * @property {number} DailyOrder.id
 * @property {LocalDate} DailyOrder.date
 * @property {OrderItem[]} DailyOrder.orderItems
 */
export class DailyOrder {
  id;
  /**
   * @type{LocalDate}
   */
  date;
  /**
   * @type {OrderItem[]}
   */
  orderItems;

  /**
   * @param dailyOrderDto {DailyOrderDto}
   */
  constructor(dailyOrderDto) {
    Object.assign(this, {
      ...dailyOrderDto,
      date: toLocalDate(dailyOrderDto.date),
      orderItems: dailyOrderDto.orderItems.map(orderItem => new OrderItem(orderItem)),
    });
  }
}
