import React from 'react';
import * as PropTypes from 'prop-types';
import ItemHeader from '../../Templates/ItemHeader';
import ItemAdditionalContent from '../../Templates/ItemAdditionalContent';
import { asPriceWithTwoDecimals } from '../../../../utils/numberUtils';

function DishPrice({ price, fixed, isSelectable }) {
  return (
    <ItemAdditionalContent fixed={fixed} isSelectable={isSelectable}>
      <ItemHeader>
        ₴
        {' '}
        {asPriceWithTwoDecimals(price)}
      </ItemHeader>
    </ItemAdditionalContent>
  );
}

DishPrice.defaultProps = {
  fixed: false,
  isSelectable: false,
};

DishPrice.propTypes = {
  price: PropTypes.number.isRequired,
  fixed: PropTypes.bool,
  isSelectable: PropTypes.bool,
};

export default DishPrice;
