import React, { forwardRef } from 'react';
import { bool, string } from 'prop-types';

import { Box, Divider, Typography } from '@mui/material';
import { DailyOrderDishesWithTotal } from './DailyOrderDishesWithTotal';
import { Item, ItemContent, ItemHeader } from '../../../../../../../ListItems/Templates';

import { dailyOrderType, dateType, refType, themeConstants } from '../../../../../../../../constants';
import { formatDateForDailyOrderItem } from '../../../../../../../../utils/dates';

const styles = {
  cursorDefault: {
    cursor: 'default',
  },
  dayContainer: {
    marginLeft: 1,
    marginRight: 1,
  },
  dayContainerSelected: {
    background: theme => theme.palette.primaryLightest?.main || themeConstants.primaryLightest.main,
  },
  daySeparator: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  divider: {
    flexGrow: 1,
    marginLeft: 2,
    height: '0.1em',
    backgroundColor: theme => theme.palette.primaryLighter?.main || themeConstants.primaryLighter.main,
  },
  dailyOrderDishItem: {
    margin: theme => `0 ${theme.spacing(2)}`,
  },
};

function DailyOrderDateHeader(props) {
  return (
    <ItemHeader>
      <Box sx={styles.daySeparator}>
        <Box>
          <Typography
            noWrap
            sx={styles.cursorDefault}
            color="primary">
            {props.localizedDate}
          </Typography>
        </Box>
        <Divider sx={styles.divider} />
      </Box>
    </ItemHeader>
  );
}

DailyOrderDateHeader.propTypes = { localizedDate: string.isRequired };

function DailyOrderItem({ dailyOrder, date, internalRef, isHoliday, isSelected }) {
  const localizedDate = formatDateForDailyOrderItem(date);

  return (
    <Box id={`daily-order-item-root-${date}`}
         data-test-id={`daily-order-item-${date}`}
         sx={{
           ...styles.dayContainer,
           ...(isSelected && styles.dayContainerSelected)
         }}
         ref={internalRef}>
      <Item>
        <ItemContent>
          <DailyOrderDateHeader localizedDate={localizedDate} />

          <DailyOrderDishesWithTotal dailyOrder={dailyOrder} date={date} isHoliday={isHoliday} />
        </ItemContent>
      </Item>
    </Box>
  );
}

DailyOrderItem.propTypes = {
  date: dateType.isRequired,
  dailyOrder: dailyOrderType.isRequired,
  isHoliday: bool,
  internalRef: refType.isRequired,
  isSelected: bool.isRequired,
};

DailyOrderItem.defaultProps = {
  isHoliday: false,
};

export default forwardRef(
  (props, ref) => <DailyOrderItem {...props} internalRef={ref} />,
);
