import { connect } from 'react-redux';

import LatestVersionLoader from './LatestVersionLoader';

import { clearGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';
import { fetchPeriodMenu, selectPeriodMenuIsLoading } from '../../../store/menu';
import { getNowDate } from '../../../utils/dates';

const mapStateToProps = state => ({
  isLoading: selectPeriodMenuIsLoading(state),
  date: getNowDate(),
});

const mapDispatchToProps = dispatch => ({
  load: dateRange => {
    dispatch(clearGeneralError());
    dispatch(fetchPeriodMenu(dateRange));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestVersionLoader);
