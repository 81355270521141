import React from 'react';
import { bool, func } from 'prop-types';

import { IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = {
  menuButton: {
    marginRight: 2,
  },
};

const getIcon = (matchesBigScreen, isSideMenuOpen) => {
  let Icon = MenuIcon;

  if (matchesBigScreen) {
    Icon = isSideMenuOpen ? ChevronLeftIcon : MenuIcon;
  }

  return Icon;
};

export default function SideMenuButton(props) {
  const matchesBigScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));
  const {
    getOnClickHandler,
    isSideMenuOpen
  } = props;

  const Icon = getIcon(matchesBigScreen, isSideMenuOpen);
  const onClick = getOnClickHandler(matchesBigScreen);

  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      sx={styles.listSubheaderRoot}
      size="large">
      <Icon />
    </IconButton>
  );
}

SideMenuButton.propTypes = {
  getOnClickHandler: func.isRequired,
  isSideMenuOpen: bool.isRequired,
};
