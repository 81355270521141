import { dishesActionTypes } from '../../../actions/luncherActions/dishesActions/dishesActions';
import { dishesPageActionTypes } from '../componentActions/dishesPageActionTypes';

const initialState = {
  newDish: null,
};

export default function dishesPageReducer(state = initialState, action) {
  switch (action.type) {
    case dishesActionTypes.ADD_DISH_SUCCESS:
      return {
        newDish: action.payload,
      };

    case dishesPageActionTypes.CLEAR_NEW_DISH:
      return {
        newDish: null,
      };

    default:
      return state;
  }
}
