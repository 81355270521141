import React from 'react';
import { func } from 'prop-types';
import { DayOfWeek, TemporalAdjusters } from '@js-joda/core';

import { Button, Grid, IconButton } from '@mui/material';
import { ArrowLeft, ArrowRight, DateRange } from '@mui/icons-material';

import { PickedDateRangeComponent } from './PickedDateRangeComponent';
import { CalendarPopover, usePopoverState } from './CalendarPopover';

import { getWeekBounds } from '../../utils/dates';
import { dateType } from '../../constants';
import { weekPickerStyles } from './weekPickerStyles';

import './WeekPicker.scss';

function PreviousWeekArrowButton(props) {
  const showPreviousWeek = () => {
    const prevMonday = props.date
                            .with(TemporalAdjusters.previousOrSame(DayOfWeek.MONDAY))
                            .with(TemporalAdjusters.previous(DayOfWeek.MONDAY));
    props.onDateChange(prevMonday);
  };

  return (
    <IconButton
      color="secondary"
      sx={weekPickerStyles.weekPickerArrow}
      onClick={showPreviousWeek}
      size="large">
      <ArrowLeft fontSize="large" />
    </IconButton>
  );
}

PreviousWeekArrowButton.propTypes = {
  onDateChange: func.isRequired,
  date: dateType.isRequired
};

function NextWeekArrowButton(props) {
  const showNextWeek = () => {
    const nextMonday = props.date
                            .with(TemporalAdjusters.next(DayOfWeek.MONDAY));
    props.onDateChange(nextMonday);
  };
  return (
    <IconButton
      color="secondary"
      sx={weekPickerStyles.weekPickerArrow}
      onClick={showNextWeek}
      size="large">
      <ArrowRight fontSize="large" />
    </IconButton>
  );
}

NextWeekArrowButton.propTypes = {
  onDateChange: func.isRequired,
  date: dateType.isRequired
};

export function WeekPicker(props) {
  const { openPopover, ...restPopoverStateProps } = usePopoverState();
  const { firstDate, lastDate } = getWeekBounds(props.date);

  return (
    <Grid container justifyContent="center" alignItems="center" wrap="nowrap" className="weekpicker">

      <Grid item>
        <PreviousWeekArrowButton {...props} />
      </Grid>

      <Grid item className="weekpicker-date-range">
        <Button color="secondary" size="small" sx={weekPickerStyles.button}
                onClick={openPopover}>

          <DateRange />

          <PickedDateRangeComponent firstDate={firstDate}
                                    lastDate={lastDate} />
        </Button>

        <CalendarPopover {...props} {...restPopoverStateProps} />
      </Grid>

      <Grid item>
        <NextWeekArrowButton  {...props} />
      </Grid>
    </Grid>
  );
}

WeekPicker.propTypes = {
  onDateChange: func.isRequired,
  date: dateType.isRequired

};
