import React, { useCallback } from 'react';
import { elementType, func, shape, string, } from 'prop-types';

import { Form as RffForm } from 'react-final-form';
import createDecorator from 'final-form-focus';

import { localizeValidationErrors } from '../../../../utils/localizationUtils/localizationUtils';

const focusOnError = createDecorator();

function Form(props) {
  const {
    initialValues, save, close, FormContent, formContentProps,
  } = props;

  const onSubmit = useCallback(
    entityToSave => (
      save(entityToSave)
        .then(close)
        .catch(localizeValidationErrors)),
    [save, close],
  );

  return (
    <RffForm
      onSubmit={onSubmit}
      decorators={[focusOnError]}
      initialValues={initialValues}
      initialValuesEqual={() => true}
      render={rffProps => (
        <FormContent
          formProps={rffProps}
          {...formContentProps} />
      )} />
  );
}

Form.propTypes = {
  formContentProps: shape({
                            formId: string.isRequired,
                          }).isRequired,
  FormContent: elementType.isRequired,
  initialValues: shape({}).isRequired,
  save: func.isRequired,
  close: func.isRequired,
};

export default Form;
