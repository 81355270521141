export const themeConstants = {
  primary: {
    main: '#ff6800',
    contrastText: '#ffffff',
  },
  primaryLight: {
    main: '#FF9A3F',
    contrastText: '#ffffff',
  },
  primaryDark: {
    main: '#C43600',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#616161',
    contrastText: '#ffffff',
  },
  secondaryLight: {
    main: '#8E8E8E',
    contrastText: '#ffffff',
  },
  secondaryDark: {
    main: '#373737',
    contrastText: '#ffffff',
  },

  // not from official palette,
  primaryLighter: {
    main: '#ffc399',
    contrastText: '#ffffff',
  },
  primaryLightened: {
    main: '#ffede0',
    contrastText: '#616161',
  },
  primaryLightest: {
    main: '#fff6f0',
    contrastText: '#616161',
  },
  // not from official palette, but from holidays color
  secondaryLightest: {
    main: '#D3D3D3',
  },
};

export const getPagesResponsiveHeights = theme => ({
  [theme.breakpoints.down('md')]: {
    height: `calc(100vh - 171px)`,
  },
  [theme.breakpoints.up('md')]: {
    height: `calc(100vh - 122px)`,
  },
});

export const themePresets = {
  flexColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexFlow: 'nowrap column',
    flex: '1 1 auto',
  },
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
  },
};

export const LAYOUT_COMPONENTS_HEIGHTS = {
  header: 50,
  weekPicker: 43 + 8 * 2,
  dayPickerTabs: 48,
};
