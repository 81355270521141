import { menusActionTypes } from './menusActionTypes';

export const loadingPeriodMenuStart = () => ({
  type: menusActionTypes.LOADING_PERIOD_MENUS_START,
});

export const fetchPeriodMenuSuccess = menus => ({
  type: menusActionTypes.FETCH_PERIOD_MENUS_FOR_RANGE_SUCCESS,
  payload: menus,
});

export const createPeriodMenuSuccess = menu => ({
  type: menusActionTypes.CREATE_PERIOD_MENU_SUCCESS,
  payload: menu,
});

export const createDailyMenuSuccess = menu => ({
  type: menusActionTypes.CREATE_DAILY_MENU_SUCCESS,
  payload: menu,
});

export const removeDailyMenuSuccess = menu => ({
  type: menusActionTypes.REMOVE_DAILY_MENU_SUCCESS,
  payload: menu,
});

export const deletePeriodMenuSuccess = menu => ({
  type: menusActionTypes.DELETE_PERIOD_MENU_SUCCESS,
  payload: menu,
});

export const updatePeriodMenuStatusSuccess = menu => ({
  type: menusActionTypes.UPDATE_PERIOD_MENU_STATUS_SUCCESS,
  payload: menu,
});

export const addMenuItemSuccess = (menu, item) => ({
  type: menusActionTypes.ADD_MENU_ITEM_SUCCESS,
  payload: { menu, item },
});

export const removeMenuItemSuccess = (menu, item) => ({
  type: menusActionTypes.REMOVE_MENU_ITEM_SUCCESS,
  payload: { menu, item },
});
