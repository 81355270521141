import React from 'react';
import { connect } from 'react-redux';

import MenuReportCards from './MenuReportCards';
import { NoContentMessage } from '../../../../Messages';
import { noOrdersAvailable, withAlternative, withReportErrors } from '../../../../../utils/HOCs';
import { selectUsersFilter } from '../../../../../store/users';
import {
  selectMenuReportWithLoggedUserOnTop,
  selectMenuReportWithLoggedUserOnTopForActiveDate
} from '../../../../../store/report';

const filterByUsers = (orders, users) => orders.filter(order => users.includes(order.user.name));

const mapStateToProps = state => {
  const usersToFilter = selectUsersFilter(state);

  const orders = selectMenuReportWithLoggedUserOnTopForActiveDate(state) || [];

  return {
    menuReport: selectMenuReportWithLoggedUserOnTop(state),
    usersDailyOrders: usersToFilter.length
                      ? filterByUsers(orders, usersToFilter)
                      : orders
  };
};

const NoOrdersMessage = () => <NoContentMessage message="no-daily-orders-available" />;

// TODO check is OK?
export default connect(mapStateToProps)(
  withReportErrors(
    withAlternative(noOrdersAvailable, NoOrdersMessage)(
      MenuReportCards
    ),
    'menuReport'),
);
