import React, { useState } from 'react';
import { bool, func, oneOfType, shape, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from '@intelliarts/react-components';
import { selectActiveDate } from '../../../../../../../store/activeState';
import { selectLastDraftPeriodMenu, selectPeriodMenuForActiveDate } from '../../../../../../../store/menu';
import { ConfirmDialog } from '../../../../../../Dialogs';

function ToolbarButton(props) {
  const { children, ...restProps } = props;
  const { t: localize } = useTranslation();

  return (
    <Button
      color="primary"
      data-test-id={localize(children)}
      {...restProps}>
      {localize(children)}
    </Button>
  );
}

ToolbarButton.propTypes = {
  onClick: func.isRequired,
  variant: string.isRequired,
  disabled: bool.isRequired,
  children: string.isRequired,
  sx: shape({}).isRequired,
};

export function MenuAction(props) {
  const { description, label, variant, action, title, disabled: isDisabled, ...restProps } = props;
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const date = useSelector(selectActiveDate);
  const menuForActiveDate = useSelector(selectPeriodMenuForActiveDate);
  const lastDraftMenu = useSelector(selectLastDraftPeriodMenu);
  const menu = menuForActiveDate || lastDraftMenu;

  const disabled = typeof isDisabled === 'boolean' ? isDisabled : isDisabled(menu, date);

  return (
    <>
      <ToolbarButton variant={variant}
                     onClick={() => setIsOpen(true)}
                     disabled={disabled}
                     {...restProps}>
        {label}
      </ToolbarButton>

      {
        disabled ? '' : (
          <ConfirmDialog
            isOpened={isOpen}
            title={title}
            description={description}
            primaryButtonText="button.confirm"
            onClose={() => setIsOpen(false)}
            onConfirm={() => dispatch(action(menu, date))} />
        )
      }
    </>
  );
}

MenuAction.propTypes = {
  action: func.isRequired,

  label: string.isRequired,
  variant: string,
  disabled: oneOfType([bool, func]),

  title: string.isRequired,
  description: string.isRequired,

  sx: shape({}),
};

MenuAction.defaultProps = {
  disabled: false,
  variant: 'contained',
  sx: {},
};

