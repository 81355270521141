import generalErrors from '../../constants/generalErrors';

const selectGeneralErrorStatus = state => state.error.status;

export const isConnectionError = state => selectGeneralErrorStatus(state) === generalErrors.API_CALL_ERROR;

export const isAnyGeneralError = state => Boolean(selectGeneralErrorStatus(state));

export const hasLoginError = state => selectGeneralErrorStatus(state) === generalErrors.API_LOGIN_ERROR;

export const selectIsNoGeneralError = state => selectGeneralErrorStatus(state) === null;
