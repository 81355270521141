import i18n from '../../i18n';
import { foodlordParams } from '../../routes/foodlord/foodlordRoutes';
import { findCategoryById } from '../entitiesUtils/categoriesUtils';

const ALL_CATEGORIES_OPTION = {
  id: -1,
  name: i18n.t('category-selector.all-categories'),
  position: -1,
};

const selectCategories = state => state.categories.data;

const selectCategoryFilterOptions = state => [
  ALL_CATEGORIES_OPTION,
  ...selectCategories(state),
];

const findCategoryByIdOrGetDefault = (state, categoryId) => (
  findCategoryById(selectCategories(state), categoryId, ALL_CATEGORIES_OPTION)
);

export const getCategoryFromUrl = (state, urlQuery) => {
  const searchParams = new URLSearchParams(urlQuery);
  const categoryId = Number(searchParams.get(foodlordParams.CATEGORY) || 0);

  return findCategoryById(selectCategories(state), categoryId);
};

export const getCategoryFromQueryParams = (state, queryParams) => (
  getCategoryFromUrl(state, queryParams) || ALL_CATEGORIES_OPTION
);

export {
  ALL_CATEGORIES_OPTION,
  selectCategories,
  selectCategoryFilterOptions,
  findCategoryByIdOrGetDefault,
};
