import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import { ThemeProvider } from '@mui/material';
import { theme } from '@intelliarts/react-components';

import AuthenticationController from './components/Authentication/AuthenticationControllerContainer';

import store from './store';

ReactDOM.render((
                  <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={4} preventDuplicate>
                      <Provider store={store}>
                        <BrowserRouter>
                          <AuthenticationController />
                        </BrowserRouter>
                      </Provider>
                    </SnackbarProvider>
                  </ThemeProvider>
                ), document.getElementById('root'));
