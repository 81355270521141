import httpCodes from './httpCodes';
import generalErrors from './generalErrors';

export const errorsByStatusCode = {
  [generalErrors.API_CALL_ERROR]: 'no-server-connection',
  [httpCodes.INTERNAL_SERVER_ERROR]: 'item-does-not-exist',
  [generalErrors.VALIDATION_ERROR]: 'validation-error',
};

export const defaultMessage = 'default-message';
