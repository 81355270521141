export const joinWithPlus = (acc, item) => `${acc} + ${item}`;

export const getReducedDishesIds = dishes => dishes.map(dish => dish.id).reduce(joinWithPlus);

export const getReducedDishesVendorNames = dishes => dishes.map(dish => dish.officialName).reduce(joinWithPlus);

export const getReducedDishesNames = dishes => dishes.map(dish => dish.name).reduce(joinWithPlus);

// TODO refactor by extracting 'nothing-ordered' to constant and updating all its usages
export const isNothingOrdered = dishName => dishName === 'nothing-ordered';
