const authenticationActionTypes = {
  START_LOG_IN_PROCEDURE: '[Authentication] Start login procedures',
  STOP_LOG_IN_PROCEDURE: '[Authentication] Stop login procedures',
};

const startLoginProcedure = () => ({
  type: authenticationActionTypes.START_LOG_IN_PROCEDURE,
});

const stopLoginProcedure = () => ({
  type: authenticationActionTypes.STOP_LOG_IN_PROCEDURE,
});

export {
  authenticationActionTypes,
  startLoginProcedure,
  stopLoginProcedure,
};
