import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LogoutButton } from '@intelliarts/react-components';

import { Avatar, Box, IconButton, List, ListItem, Popover, SvgIcon, Typography } from '@mui/material';

import { MenuLink } from '../SideMenu/MenuLink';

import { GLOBALS, userProfileType } from '../../constants';
import { getOpenSideMenuRoutes } from '../../utils/selectors/routeSelectors';

import SignOutIconSvg from '../../images/menuIcons/sign-out.svg';

import { userMenuStyles } from './UserMenuStyles';

// FIXME remove dependency on routes
const menuItems = {
  open: getOpenSideMenuRoutes(),
};

export default function UserMenu(props) {
  const { t: localize } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const openDialog = event => setAnchorEl(event.currentTarget);
  const closeDialog = () => setAnchorEl(null);

  return (
    <Box sx={userMenuStyles.menuButton}>
      <IconButton
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={openDialog}
        size="large">
        <Avatar src={props.userProfile.avatar} />
      </IconButton>

      <Popover
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeDialog}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center', }}>

        <List>
          {
            Object
              .entries(menuItems.open)
              .map(([link, routeConfig]) => (
                <MenuLink key={link}
                          routeConfig={routeConfig}
                          onClick={closeDialog} />
              ))
          }
          <ListItem sx={userMenuStyles.listItem} onClick={closeDialog}>
            <LogoutButton sx={userMenuStyles.button}>
              <SvgIcon component={SignOutIconSvg}
                       sx={userMenuStyles.icon}
                       viewBox={GLOBALS.images.viewBox.logout} />
              <Typography>{localize('menu.logout')}</Typography>
            </LogoutButton>
          </ListItem>

        </List>
      </Popover>
    </Box>
  );
}

UserMenu.propTypes = {
  userProfile: userProfileType.isRequired,
};
