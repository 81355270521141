import { sideMenuActionTypes } from './sideMenuActionTypes';

export const openSideMenu = () => ({
  type: sideMenuActionTypes.OPEN_SIDE_MENU,
});

export const closeSideMenu = () => ({
  type: sideMenuActionTypes.CLOSE_SIDE_MENU,
});

export const saveSideMenuStateToLocalStorage = isOpen => ({
  type: sideMenuActionTypes.SAVE_SIDE_MENU_STATE,
  payload: isOpen,
});
