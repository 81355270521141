import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bool, func, } from 'prop-types';

import { LoadingSpinnerProvider } from '../../LoadingSpinnerProvider';
import { WeekPickerToolbar } from '../../WeekPickerToolbar';

import { dateToUrlSetter, getWeekBounds, isDateFromDateRange } from '../../../utils/dates';
import { EmptyComponent, withConnectionErrorHandler } from '../../../utils/HOCs';
import { componentType, dateRangeType, dateType } from '../../../constants';
import { selectActiveDate, selectActiveDateRange } from '../../../store/activeState';

const useActiveWeekChanged = ({ activeDate, onWeekChange }) => {
  const [prevDate, setPrevDate] = useState(activeDate);

  useEffect(() => {
    const weekDateRange = getWeekBounds(activeDate);
    if (!prevDate.equals(activeDate) && !isDateFromDateRange(prevDate, weekDateRange)) {
      setPrevDate(activeDate);
      onWeekChange(weekDateRange, activeDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevDate, activeDate]);
};

function WeekPickerPage(props) {
  const {
    isLoading, button, activeDate, activeDateRange,
    load, Component, onDateChange, onWeekChange,
    ...passThroughProps
  } = props;

  useEffect(() => {
    load(activeDateRange, activeDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDateRange]);

  useActiveWeekChanged({ activeDate, onWeekChange });

  return (
    <>
      <WeekPickerToolbar
        date={activeDate}
        onDateChange={onDateChange}
        RightControl={button} />

      <LoadingSpinnerProvider isLoading={isLoading}>
        <Component isLoading={isLoading}
                   date={activeDate}
                   {...passThroughProps} />
      </LoadingSpinnerProvider>
    </>
  );
}

WeekPickerPage.propTypes = {
  isLoading: bool.isRequired,
  load: func.isRequired,
  onDateChange: func.isRequired,
  onWeekChange: func,
  button: componentType,
  activeDate: dateType.isRequired,
  activeDateRange: dateRangeType.isRequired,
  Component: componentType.isRequired
};

WeekPickerPage.defaultProps = {
  button: EmptyComponent,
  onWeekChange: () => '',
};

export const withWeekPickerPage = urlPath => Component => {
  return (
    withConnectionErrorHandler(
      connect(
        state => ({
          activeDate: selectActiveDate(state),
          activeDateRange: selectActiveDateRange(state),
          Component: Component,
          onDateChange: dateToUrlSetter(urlPath),
        })
      )(
        WeekPickerPage
      )
    )
  );
};
