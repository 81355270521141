import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import EaterRouter from './eater/eaterRouterContainer';
import FoodlordRouter from './foodlord/foodlordRouterContainer';
import CommonRouter from './common/commonRouterContainer';

import NotFoundPageRoute from './NotFoundPageRoute';

import { foodlordRoot } from './foodlord/foodlordRoutes';
import { eaterRouteMask } from './eater/eaterRoutes';
import { commonRouteMask, commonRoutes } from './common/commonRoutes';

import { generateOpenRoutes } from '../utils/routeUtils';

function PageRoutes() {
  return (
    <Switch>
      <Route path={commonRouteMask} component={CommonRouter} />
      <Route path={eaterRouteMask} component={EaterRouter} />
      <Route path={foodlordRoot} component={FoodlordRouter} />
      <Redirect exact from="/" to={commonRoutes.LAST_MENU_REPORT} />

      {generateOpenRoutes()}

      <NotFoundPageRoute />
    </Switch>
  );
}

export default PageRoutes;
