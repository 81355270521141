import React from 'react';
import { number } from 'prop-types';

import { Card, CardContent } from '@mui/material';

import UserDailyOrderHeader from './UserDailyOrderHeader';
import { CategoryOrderItem } from './CategoryOrderItemComponent';

import { joinWithPlus } from '../../../../../../store/report';
import { themePresets, userOrderedDishesType, userType } from '../../../../../../constants';

const styles = {
  card: {
    ...themePresets.flexColumnContainer,
    marginBottom: 0,
  },
  content: {
    ...themePresets.flexColumnContainer,
  },
};

export default function UserDailyOrder(props) {
  const { userOrderedDishes, userProfile, index } = props;

  return (
    <Card
      data-test-id={`user-daily-order-card-root-${userProfile.name}`}
      sx={styles.card}
      elevation={2}
      variant="elevation">

      <UserDailyOrderHeader userProfile={userProfile} index={index} />

      <CardContent sx={styles.content} data-test-id={`user-order-card-${userProfile.name}`}>
        {userOrderedDishes.map(userOrderedDish => (
          <CategoryOrderItem
            categoryOrder={userOrderedDish}
            key={joinWithPlus(userOrderedDish.categoryName, userOrderedDish.dishName)} />
        ))}
      </CardContent>
    </Card>
  );
}

UserDailyOrder.propTypes = {
  userOrderedDishes: userOrderedDishesType.isRequired,
  userProfile: userType.isRequired,
  index: number.isRequired,
};
