export const GLOBALS = {
  images: {
    viewBox: {
      pandaSleeping: '0 -40 198.43 85.04',
      pandaDancing: '0 0 226.77 255.12',
      pandaInHood: '0 0 141.73 226.77',
      unicorn: '0 0 170.08 170.08',
      everyDay: '0 0 400 400',
      category: '0 0 344 344',
      logout: '0 0 344 344'
    },
  },

  localStorage: {
    languageKey: 'language',
  },

  sideMenu: {
    statusKey: 'isSideMenuOpen',
  },
};

export default GLOBALS;
