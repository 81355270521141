import React from 'react';
import { string } from 'prop-types';
import { EditLinkButton } from '@intelliarts/react-components';
import { useTranslation } from 'react-i18next';

function EditButton(props) {
  const { t: localize } = useTranslation();

  return (
    <EditLinkButton to={props.href} tooltipText={localize('label.edit')} />
  );
}

EditButton.propTypes = {
  href: string.isRequired,
};

export default EditButton;
