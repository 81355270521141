import React from 'react';
import { connect } from 'react-redux';

import { DailyCategories } from '../../../../../Lists/DailyCategories';
import { OrderCategoryAccordion } from './OrderCategoryAccordion';

import { selectCategoriesFromMenuItems, selectOrderForActiveDate } from '../../../../../../store/order';
import { isMenuPublished, selectPeriodMenuForActiveDate } from '../../../../../../store/menu';
import { noMenuAvailable, noOrderButWithPublishedMenuAvailable, withAlternative } from '../../../../../../utils/HOCs';
import { NoContentMessage } from '../../../../../Messages';
import { CreatePeriodOrder } from '../CreatePeriodOrder';
import { selectActiveDate } from '../../../../../../store/activeState';

export const DailyOrderCategories = connect(
  state => ({
    menu: selectPeriodMenuForActiveDate(state),
    order: selectOrderForActiveDate(state),
    categories: selectCategoriesFromMenuItems(state),
    isDisabled: !isMenuPublished(selectPeriodMenuForActiveDate(state)),
    date: selectActiveDate(state),
    Content: OrderCategoryAccordion,
  })
)(
  withAlternative(noMenuAvailable, () => <NoContentMessage message="no-period-menu-available" />)(
    withAlternative(noOrderButWithPublishedMenuAvailable, CreatePeriodOrder)(
      DailyCategories
    )
  )
);
