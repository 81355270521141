import React from 'react';
import { arrayOf } from 'prop-types';

import { Container } from '@mui/material';

import { NothingToShowMessage } from '../../Messages';
import { CategoryItem } from '../../ListItems/CategoryItem';

import { categoryType } from '../../../constants';
import { withAlternative } from '../../../utils/HOCs';

const styles = {
  categoriesContainerRoot: {
    display: 'flex',
    flexFlow: 'nowrap column',
    flex: '1 1 auto',
  },
};

function CategoriesList(props) {
  return (
    <Container sx={styles.categoriesContainerRoot}>
      {
        props.categories.map(category => (
          <CategoryItem key={category.id} category={category} />
        ))
      }
    </Container>
  );
}

CategoriesList.propTypes = {
  categories: arrayOf(categoryType).isRequired,
};

const noCategories = props => !props.categories.length;

export default withAlternative(noCategories, NothingToShowMessage)(CategoriesList);
