import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CategoryFilter from './CategoryFilter';

import { sortDishesByName } from '../../../actions/luncherActions/dishesActions/dishesActions';
import { foodlordRoutes } from '../../../routes/foodlord/foodlordRoutes';
import { selectCategoryFilterOptions } from '../../../utils/selectors/categorySelectors';
import { generateUrlWithCategoryFilter } from '../../../utils/UrlUtils/UrlUtils';

const mapStateToProps = state => ({
  categories: selectCategoryFilterOptions(state),
});

const mapDispatchToProps = dispatch => ({
  sort: () => dispatch(sortDishesByName()),
});

const mergeProps = ({ categories }, { sort }, { history, ...restOwnProps }) => ({
  categories,
  ...restOwnProps,
  onCategorySelect: categoryId => {
    history.push(generateUrlWithCategoryFilter(categoryId, foodlordRoutes.DISH_LIST));

    sort();
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(CategoryFilter));
