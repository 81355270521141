import { connect } from 'react-redux';

import { DailyCategories } from '../../../../../Lists/DailyCategories';
import { NoMenuStickerMessage } from '../NoMenuStickerMessage';
import { MenuCategoryItems } from '../MenuCategoryItems';

import { isMenuContainingActiveDateExists, selectIsMenuCompleted, } from '../../../../../../store/menu';
import { menuIsNotCreated, withAlternative } from '../../../../../../utils/HOCs';
import { selectCategories } from '../../../../../../utils/selectors/categorySelectors';

const mapStateToProps = state => ({
  isDisabled: selectIsMenuCompleted(state),
  categories: selectCategories(state),
  isMenuCreated: isMenuContainingActiveDateExists(state),
  Content: MenuCategoryItems,
});

/*
  isMenuCreated: bool.isRequired, // for `withAlternative`
 */
export default connect(mapStateToProps)(
  withAlternative(menuIsNotCreated, NoMenuStickerMessage)(
    DailyCategories
  )
);
