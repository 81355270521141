import React from 'react';
import { string } from 'prop-types';

import { formatDateForStickerReport } from '../../../../../../utils/dates';

export function StickerSingleItem({ dishName, customerName, date }) {
  return (
    <div className="menu-item-container-sticker">
      <div className="sticker-dish">
        {dishName}
      </div>
      <div className="lower-row">
        <div className="sticker-customer-name">
          {customerName}
        </div>
        <div className="sticker-date">
          {formatDateForStickerReport(date)}
        </div>
      </div>
    </div>
  );
}

StickerSingleItem.propTypes = {
  dishName: string.isRequired,
  customerName: string.isRequired,
  date: string.isRequired,
};
