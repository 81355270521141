import React, { useRef } from 'react';
import { bool, func } from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemText } from '@mui/material';

import ListItemIconWithTooltip from './ListItemIconWithTooltip';

import { pageConfigType } from '../../../constants/types';
import { themeConstants } from '../../../constants/themeConstants';

const styles = {
  link: {
    '&:hover': {
      color: theme => theme.palette.primaryDark?.main || themeConstants.primaryDark.main,
    },
    color: theme => theme.palette.secondaryDark?.main || themeConstants.secondaryDark.main,
  },
};

/**
 * props.routeConfig.icon {String || Object} - either Material UI icon, or SVG file.
 */
function MenuLink(props) {
  const { routeConfig, onClick, isActive } = props;
  const { t: localize } = useTranslation();

  const linkRef = useRef(null);

  const { link, icon, title } = routeConfig;

  return (
    <ListItem
      component={Link}
      to={link}
      ref={linkRef}
      sx={styles.link}
      onClick={onClick}>
      {
        icon ? (
          <ListItemIconWithTooltip
            Icon={icon}
            title={localize(title)}
            isActive={isActive}
            onIconClick={event => {
              event.preventDefault();
              linkRef?.current && linkRef.current.click();
            }} />
        ) : ''
      }
      <ListItemText primary={localize(title)} />
    </ListItem>
  );
}

MenuLink.defaultProps = {
  isActive: false,
};

MenuLink.propTypes = {
  onClick: func.isRequired,
  routeConfig: pageConfigType.isRequired,
  isActive: bool,
};

export default MenuLink;
