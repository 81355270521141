import React, { useCallback, useEffect, useState } from 'react';
import { bool, number, string } from 'prop-types';

import { Box, ClickAwayListener } from '@mui/material';

import Tooltip from './Tooltip';

/** Extends Tooltip behavior with timeouts for opening and closing.
 *
 * @param title {String} - tooltip content
 * @param openTimeout {Number} - timeout before opening tooltip
 * @param closeTimeout {Number} - timeout to close SINCE tooltip IS OPEN
 * @param shouldClose {Boolean} - enable auto close
 * @param placement {String} - position of tooltip
 * @param children {React.Component}
 * @return {React.Component}
 * @constructor
 */
export default function TooltipApplier({
                                         title,
                                         openTimeout,
                                         closeTimeout,
                                         shouldClose,
                                         placement,
                                         children,
                                       }) {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const openTooltip = useCallback(() => setTooltipOpen(true), [setTooltipOpen]);
  const closeTooltip = useCallback(() => setTooltipOpen(false), [setTooltipOpen]);

  const [delayedOpening, setDelayedOpening] = useState(false);
  const delayTooltipOpening = useCallback(() => setDelayedOpening(true), [setDelayedOpening]);
  const resetDelayTooltipOpening = useCallback(() => setDelayedOpening(false), [setDelayedOpening]);

  const [timers, setTimers] = useState({
                                         openTooltipTimer: null,
                                         closeTooltipTimer: null,
                                       });
  const { closeTooltipTimer, openTooltipTimer } = timers;

  // Effect for tooltip opening management
  useEffect(() => {
    if (delayedOpening && !isTooltipOpen) {
      setTimers(prevState => ({
        ...prevState,
        openTooltipTimer: setTimeout(openTooltip, openTimeout),
      }));
    }

    return () => isTooltipOpen && clearTimeout(openTooltipTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delayedOpening, isTooltipOpen, openTimeout]);

  // Effect for delayed opening reset
  useEffect(() => {
    if (shouldClose && delayedOpening && isTooltipOpen) {
      resetDelayTooltipOpening();
    }
  }, [delayedOpening, isTooltipOpen, shouldClose, resetDelayTooltipOpening]);

  // Effect for tooltip closing management
  useEffect(() => {
    if (shouldClose && isTooltipOpen) {
      setTimers(prevState => ({
        ...prevState,
        closeTooltipTimer: setTimeout(closeTooltip, closeTimeout),
      }));
    }

    return () => !isTooltipOpen && clearTimeout(closeTooltipTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTooltipOpen, shouldClose, closeTimeout]);

  function handleCloseTooltip() {
    delayedOpening && resetDelayTooltipOpening();
    isTooltipOpen && closeTooltip();
    openTooltipTimer && clearTimeout(openTooltipTimer);
    closeTooltipTimer && clearTimeout(closeTooltipTimer);
  }

  return (
    <ClickAwayListener onClickAway={handleCloseTooltip}>
      <Tooltip
        open={isTooltipOpen}
        key={title}
        onClose={closeTooltip}
        title={title}
        placement={placement}>
        <Box
          role="button"
          tabIndex="0"
          onKeyPress={e => {
            e.stopPropagation();
            delayTooltipOpening();
          }}
          onClick={e => {
            e.stopPropagation();
            openTooltip();
            delayTooltipOpening();
          }}
          onMouseEnter={delayTooltipOpening}
          onMouseLeave={handleCloseTooltip}
          sx={{ outline: 'none', }}>
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
}
TooltipApplier.defaultProps = {
  openTimeout: 0,
  closeTimeout: 0,
  shouldClose: false,
  placement: 'bottom',
};

TooltipApplier.propTypes = {
  title: string.isRequired,
  openTimeout: number,
  closeTimeout: number,
  shouldClose: bool,
  placement: string,
};
