import React from 'react';
import { bool, func, string } from 'prop-types';

import { Autosuggest } from '../Autosuggest';
import { DishContent } from '../../ListItems/DishItems/Dish';

import { suggestionsType } from '../../../constants';

export function MenuItemAutosuggest({
                                      blurOnSelect,
                                      isDisabled,
                                      loading,
                                      noOptions,
                                      openPopperButtonPlaceholder,
                                      saveSelectedOption,
                                      suggestions,
                                      ...restProps
                                    }) {
  return (
    <Autosuggest
      options={suggestions}
      noOptionsText={noOptions}
      openPopperButtonPlaceholder={openPopperButtonPlaceholder}
      isDisabled={isDisabled}
      loading={loading}
      blurOnSelect={blurOnSelect}
      onChange={(event, [selectedItem]) => {
        saveSelectedOption(selectedItem.value);
      }}
      renderOption={(props, option) => (
        <DishContent
          data-test-id={`autosuggest-option-${option.value.name}`}
          name={option.value.name}
          description={option.value.description}
          isEveryday={option.value.isEveryday}
          withTooltip
          renderOptionProps={props}
          withDescriptionIndent
          {...props} />
      )}
      getOptionLabel={option => option.label}
      {...restProps} />
  );
}

MenuItemAutosuggest.propTypes = {
  suggestions: suggestionsType.isRequired,
  openPopperButtonPlaceholder: string.isRequired,
  noOptions: string,
  saveSelectedOption: func.isRequired,
  isDisabled: bool,
  loading: bool,
  blurOnSelect: bool,
  id: string.isRequired,
};

MenuItemAutosuggest.defaultProps = {
  isDisabled: false,
  loading: false,
  noOptions: 'no options',
  blurOnSelect: false,
};
