import React from 'react';
import { arrayOf } from 'prop-types';

import { StickerSingleItem } from './StickerSingleItem';
import { RowContainer } from './RowContainer';

import { singleStickerType } from '../../../../../../constants';

// FIXME move to Array utils
const splitArrayIntoChunks = (array, size) => {
  const chunks = [];
  const n = array.length;
  let i = 0;

  while (i < n) {
    chunks.push(array.slice(i, i += size));
  }

  return chunks;
};

export function SingleDayStickers({ stickers }) {
  const stickersChunks = splitArrayIntoChunks(stickers, 4);

  return (
    <div>
      {
        stickersChunks.map(chunk => (
          <RowContainer key={Math.random()}>
            {chunk.map(({ customerName, date, dishName }) => (
              <StickerSingleItem
                customerName={customerName}
                date={date}
                dishName={dishName}
                key={Math.random()} />
            ))}
          </RowContainer>
        ))
      }
    </div>
  );
}

SingleDayStickers.propTypes = {
  stickers: arrayOf(singleStickerType).isRequired,
};
