import { deepOrange } from '@mui/material/colors';

export const userMenuStyles = {
  menuButton: {
    marginLeft: 'auto',
  },
  listItem: {
    cursor: 'pointer',
  },
  button: {
    width: '12em',
    color: 'black',
    height: theme => theme.spacing(4),
    '&:hover': {
      color: deepOrange[900],
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  icon: {
    marginRight: theme => theme.spacing(4.25),
    color: theme => theme.palette.secondary.light,
  },
};
