import React from 'react';
import { bool, string } from 'prop-types';

import { Typography } from '@mui/material';
import CategoryIcon from '../../Icons/CategoryIcon';

import {
  StyledBadge,
  StyledIconFlagContainer,
  StyledIconFlagDecoration,
  StyledRootContainer
} from './CategoryTitleStyledComponents';
import { categoryType } from '../../../constants';

export default function CategoryTitle({ variant, color, category, withBadge }) {
  return (
    <StyledBadge color="secondary"
                 badgeContent="Є"
                 invisible={!withBadge}>
      <StyledRootContainer>
        <StyledIconFlagDecoration>
          <StyledIconFlagContainer component="span">
            <CategoryIcon icon={category.icon || undefined} isWhite />
          </StyledIconFlagContainer>
        </StyledIconFlagDecoration>
        <Typography variant={variant} color={color}>
          {category.name}
        </Typography>
      </StyledRootContainer>
    </StyledBadge>
  );
}

CategoryTitle.propTypes = {
  color: string,
  variant: string,
  category: categoryType.isRequired,
  withBadge: bool,
};

CategoryTitle.defaultProps = {
  color: 'textSecondary',
  variant: 'h6',
  withBadge: false,
};
