import React from 'react';

import { Box } from '@mui/material';

function ItemHeader({ children, ...rest }) {
  return (
    <Box id="item-header"
         className="l-item-header"
         {...rest}>
      {children}
    </Box>
  );
}

export default ItemHeader;
