const authorizationActionTypes = {
  FETCH_USER_PROFILE_START: '[Authorization] Start user profile download',
  FETCH_USER_PROFILE_SUCCESS: '[Authorization] Retrieved user profile',
};

const fetchProfile = () => ({
  type: authorizationActionTypes.FETCH_USER_PROFILE_START,
});

const fetchProfileSuccess = profile => ({
  type: authorizationActionTypes.FETCH_USER_PROFILE_SUCCESS,
  payload: profile,
});

export {
  authorizationActionTypes,
  fetchProfile,
  fetchProfileSuccess,
};
