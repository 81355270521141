export const menusActionTypes = {
  LOADING_PERIOD_MENUS_START: '[PERIOD_MENU] Loading period menus start',

  FETCH_PERIOD_MENUS_FOR_RANGE_SUCCESS: '[PERIOD_MENU] Fetch all period menus for date range success',
  CREATE_PERIOD_MENU_SUCCESS: '[PERIOD_MENU] Create period menu success',
  DELETE_PERIOD_MENU_SUCCESS: '[PERIOD_MENU] Delete period menu success',
  UPDATE_PERIOD_MENU_STATUS_SUCCESS: '[PERIOD_MENU] Update period menu status success',

  CREATE_DAILY_MENU_SUCCESS: '[PERIOD_MENU] Create daily menu success',
  REMOVE_DAILY_MENU_SUCCESS: '[PERIOD_MENU] Remove daily menu success',

  ADD_MENU_ITEM_SUCCESS: '[MENU_ITEM] Add new item success',
  REMOVE_MENU_ITEM_SUCCESS: '[MENU_ITEM] Remove item from menu success',
};
