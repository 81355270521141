import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@mui/material';
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from '@mui/icons-material';

export default function ReportPanelButton({ isOpen, open, close }) {
  const { t: localize } = useTranslation();
  return (
    <Tooltip title={localize(isOpen ? 'label.close-panel' : 'label.open-panel')}>
      <IconButton
        size="medium"
        color="primary"
        onClick={isOpen ? close : open}>
        {isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </Tooltip>
  );
}

ReportPanelButton.propTypes = {
  isOpen: bool.isRequired,
  open: func.isRequired,
  close: func.isRequired,
};
