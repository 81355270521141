import React from 'react';
import { bool } from 'prop-types';

import { Box } from '@mui/material';

function ItemToolbar(props) {
  return (
    <Box id="item-toolbar"
         className={`l-item-toolbar ${props.isFixed ? '' : 'l-item_not-fixed-element'}`}>
      {props.children}
    </Box>
  );
}

ItemToolbar.propTypes = {
  isFixed: bool,
};

ItemToolbar.defaultProps = {
  isFixed: false,
};

export default ItemToolbar;
