import { _selectOrderedMenuItemsByDate } from '../order';
import { selectActiveDate } from '../activeState';

import { compareMenuItemsByDishNames, lastDateComparator } from '../../utils/comparators';
import { isAnyOfEntitiesLoading, isLoadingEntitiesFailedDueToConnectionError } from '../../utils/selectors';
import { isDateFromDateRange } from '../../utils/dates';
import { isMenuCompleted, isMenuPurchased } from './menuUtils';

import { PERIOD_MENU_STATUSES } from '../../constants';

const selectMenus = state => state.menu.data;

const selectSortedMenus = state => [...selectMenus(state)].sort(lastDateComparator);

/**
 * @param state
 * @return {PeriodMenu}
 */
export const selectPeriodMenuForActiveDate = state => {
  const activeDate = selectActiveDate(state);

  return selectSortedMenus(state)
    .find(({ firstDate, lastDate }) => isDateFromDateRange(activeDate, { firstDate, lastDate }));
};

/**
 * @param state
 * @return {PeriodMenu}
 */
export const selectLastDraftPeriodMenu = state => {
  return selectSortedMenus(state)
    .find(({ status }) => (status === PERIOD_MENU_STATUSES.DRAFT));
};

/**
 * @param state
 * @return {PeriodMenu}
 */
export const selectLastPublishedPeriodMenu = state => {
  return selectSortedMenus(state)
    .find(({ status }) => (status === PERIOD_MENU_STATUSES.PUBLISHED));
};

/**
 * @param state
 * @return {PeriodMenu}
 */
export const selectLastPurchasedPeriodMenu = state => {
  return selectSortedMenus(state)
    .find(({ status }) => (status === PERIOD_MENU_STATUSES.PURCHASED));
};

export const selectPeriodMenuIsLoading = state => state.menu.isLoading;

export const isMenuAndAllDepsAreLoading = state => {
  return isAnyOfEntitiesLoading(state, 'dishes', 'categories', 'menu');
};
export const isMenuAndAllDepsFailedToLoadDueToConnectionError = state => {
  return isLoadingEntitiesFailedDueToConnectionError(state, 'dishes', 'categories', 'menu');
};

export const isMenuContainingActiveDateExists = state => Boolean(selectPeriodMenuForActiveDate(state));

export const isLastPeriodMenuContainsDate = (state, date) => {
  const periodMenu = selectLastDraftPeriodMenu(state);
  if (!periodMenu) {
    return false;
  }

  const { firstDate, lastDate } = periodMenu;

  return isDateFromDateRange(date, { firstDate, lastDate });
};

export const _selectMenuItemsByDate = state => {
  const date = selectActiveDate(state);

  return selectPeriodMenuForActiveDate(state)?.items
                                             .filter(item => date.equals(item.date));
};

/**
 * @param menuItems {MenuItem[]}
 * @param category {Category}
 * @return {MenuItem[]}
 */
const _filterMenuItemsByCategoryAndSort = (menuItems, category) => (
  menuItems.filter(item => item.food.category.id === category.id)
           .sort(compareMenuItemsByDishNames)
);

/**
 * @param state
 * @param category {Category}
 * @return {MenuItem[]}
 */
export const selectMenuItemsByDateAndCategory = (state, category) => (
  _filterMenuItemsByCategoryAndSort(_selectMenuItemsByDate(state), category)
);

/**
 * @param state
 * @param category {Category}
 * @return {MenuItem[]}
 */
export const selectOrderedMenuItemsByDateAndCategory = (state, category) => (
  _filterMenuItemsByCategoryAndSort(_selectOrderedMenuItemsByDate(state), category)
);

export const isPeriodMenuPurchased = state => isMenuPurchased(selectPeriodMenuForActiveDate(state));

export const selectIsMenuCompleted = state => {
  const periodMenu = selectPeriodMenuForActiveDate(state);

  return Boolean(periodMenu) && isMenuCompleted(periodMenu);
};
