import axiosInstance from '../../../axiosInstance';
import {
  fetchMenuReportStart,
  fetchMenuReportSuccess,
  fetchSlowpokeReportStart,
  fetchSlowpokeReportSuccess,
  fetchVendorReportStart,
  fetchVendorReportSuccess,
  noMenuReportAvailable,
  noSlowpokeReportAvailable,
  noVendorReportAvailable,
} from './reportActions';
import { withInternalServerError } from '../../../utils/HOCs';
import { DateRangeDto } from '../../../models/DateRange';
import { setGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';

export const fetchVendorReport = ({ firstDate, lastDate }) => dispatch => {
  dispatch(fetchVendorReportStart());
  return axiosInstance
    .get('/report/vendor', { params: new DateRangeDto({ firstDate, lastDate }) })
    .then(res => res && dispatch(fetchVendorReportSuccess(res.data)))
    .catch(error => withInternalServerError(error, noVendorReportAvailable));
};

export const fetchMenuReport = ({ firstDate, lastDate }) => dispatch => {
  dispatch(fetchMenuReportStart());
  return axiosInstance
    .get('/report/menu', { params: new DateRangeDto({ firstDate, lastDate }) })
    .then(res => res && dispatch(fetchMenuReportSuccess(res.data)))
    .catch(error => {
      dispatch(noMenuReportAvailable())
      withInternalServerError(error, setGeneralError)
    });
};

export const fetchSlowpokeReport = ({ firstDate, lastDate }) => dispatch => {
  dispatch(fetchSlowpokeReportStart());
  return axiosInstance
    .get('/report/slowpoke', { params: new DateRangeDto({ firstDate, lastDate }) })
    .then(res => res && dispatch(fetchSlowpokeReportSuccess(res.data)))
    .catch(error => withInternalServerError(error, noSlowpokeReportAvailable));
};
