import React from 'react';
import { bool, shape } from 'prop-types';

import { Divider as MuiDivider, Icon } from '@mui/material';

export function Divider(props) {
  const { children, sx, icon, ...restProps } = props;

  return (
    <MuiDivider textAlign="center"
                sx={{
                  width: 'inherit',
                  alignSelf: 'stretch',
                  ...sx
                }}
                {...restProps}>
      {
        icon ? <Icon>{children}</Icon> : children
      }
    </MuiDivider>
  );
}

Divider.propTypes = {
  sx: shape({}),
  icon: bool
};

Divider.defaultProps = {
  sx: {},
  icon: false,
};

