import React from 'react';
import { func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

export default function CloseButton(props) {
  const { t: localize } = useTranslation();

  return (
    <Button
      sx={props.sx}
      onClick={props.onClick}
      color="primary">
      {localize('button.close')}
    </Button>
  );
}

CloseButton.defaultProps = {
  sx: {},
};

CloseButton.propTypes = {
  sx: shape({}),
  onClick: func.isRequired,
};
