import React from 'react';
import { bool } from 'prop-types';

import { Box } from '@mui/material';

function defineStyleClasses(fixed, isSelectable) {
  const styles = ['l-item-addition-content'];

  if (!fixed) {
    styles.push('l-item_not-fixed-element');
  }
  if (isSelectable) {
    styles.push('selectable');
  }

  return styles.join(' ');
}

function ItemAdditionalContent(props) {
  return (
    <Box id="item-additional-content"
         className={defineStyleClasses(props.fixed, props.isSelectable)}>
      {props.children}
    </Box>
  );
}

ItemAdditionalContent.propTypes = {
  fixed: bool,
  isSelectable: bool,
};

ItemAdditionalContent.defaultProps = {
  fixed: false,
  isSelectable: false,
};

export default ItemAdditionalContent;
