import React from 'react';
import { string } from 'prop-types';

import { Paper } from '@mui/material';

import { Message } from './Message';

import PandaInHoodImageSvg from '../../images/pandaInHood.svg';
import { GLOBALS } from '../../constants';

const styles = {
  noContentPaper: {
    display: 'flex',
    flexGrow: 1,
    padding: 0,
    margin: 0,
  },
};

export function NoContentMessage(props) {
  return (
    <Paper sx={styles.noContentPaper}>
      <Message
        message={props.message}
        image={PandaInHoodImageSvg}
        imageBox={GLOBALS.images.viewBox.pandaInHood}>
        {props.children}
      </Message>
    </Paper>
  );
}

NoContentMessage.propTypes = {
  message: string.isRequired,
};
