import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';

function DeleteButton(props) {
  const { t: localize } = useTranslation();

  // TODO fix bug with tooltip (replace usage to TooltipApplier)
  return (
    <IconButton color="primary" onClick={props.onClick} size="large">
      <Tooltip title={localize('button.delete')}>
        <DeleteOutlined />
      </Tooltip>
    </IconButton>
  );
}

DeleteButton.propTypes = {
  onClick: func.isRequired,
};

export default DeleteButton;
