import { connect } from 'react-redux';

import { MenuCategoryItems } from './MenuCategoryItems';

import {
  selectAppointedDishesByCategory,
  selectEverydayDishesByCategory,
} from '../../../../../../utils/selectors/dishSelectors';
import {
  addMenuItem,
  removeMenuItem,
  selectMenuItemsByDateAndCategory,
  selectPeriodMenuForActiveDate,
} from '../../../../../../store/menu';
import { selectActiveDate } from '../../../../../../store/activeState';

const toSuggestions = dishes => dishes.map(food => ({
  value: food,
  label: food.name,
  isDisabled: food.isEveryday,
  group: food.isEveryday ? 'label.everyday-dishes' : '',
}));

const mapStateToProps = (state, { category }) => {
  const menu = selectPeriodMenuForActiveDate(state);
  const date = selectActiveDate(state);
  const menuItems = selectMenuItemsByDateAndCategory(state, category);

  const appointedDishes = selectAppointedDishesByCategory(state, category);
  const everydayDishes = selectEverydayDishesByCategory(state, category);
  const _areNotInMenuItemsFilter = food => !menuItems.some(item => item.food.id === food.id);
  const dishSuggestions = toSuggestions([
                                          ...appointedDishes,
                                          ...everydayDishes,
                                        ].filter(_areNotInMenuItemsFilter));

  return {
    date,
    menuItems,
    menu,
    dishSuggestions,
  };
};

const mapDispatchToProps = dispatch => ({
  addMenuItemToMenu: (menu, item) => dispatch(addMenuItem(menu, item)),
  removeMenuItem: (menu, item) => dispatch(removeMenuItem(menu, item)),
});

const mergeProps = (state, dispatch, props) => {
  const { menu, menuItems, dishSuggestions, date } = state;
  const { category, isDisabled } = props;

  return {
    addFoodToMenuItemForActiveDate: food => dispatch.addMenuItemToMenu(menu, { food, date }),
    removeMenuItem: item => dispatch.removeMenuItem(menu, item),
    menuItems,
    dishSuggestions,
    category,
    isDisabled,
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  MenuCategoryItems
);
