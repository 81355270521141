import { categoriesActionTypes } from '../actions/luncherActions/categoriesActions/categoriesActions';
import { sortCategories } from '../utils/entitiesUtils/categoriesUtils';

export const initialState = {
  data: [],
  isLoading: true,
  errors: null,
};

function insertNewCategory(state, newCategory) {
  return state.data.concat(newCategory);
}

function insertUpdatedCategory(state, updatedCategory) {
  return state.data.map(
    category => (category.id === updatedCategory.id
                 ? updatedCategory
                 : category),
  );
}

function removeDeletedCategory(state, deletedCategoryId) {
  return state.data.filter(category => category.id !== deletedCategoryId);
}

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case categoriesActionTypes.FETCH_CATEGORIES_START:
      return {
        ...state,
        isLoading: true,
      };

    case categoriesActionTypes.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: sortCategories(action.payload),
        isLoading: false,
      };

    case categoriesActionTypes.CATEGORIES_VALIDATION_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: action.payload,
      };

    case categoriesActionTypes.CLEAR_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: null,
      };

    case categoriesActionTypes.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        data: sortCategories(insertNewCategory(state, action.payload)),
        errors: null,
      };

    case categoriesActionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: sortCategories(insertUpdatedCategory(state, action.payload)),
        errors: null,
      };

    case categoriesActionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        data: sortCategories(removeDeletedCategory(state, action.payload)),
      };

    default:
      return state;
  }
}
