import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { isNothingOrdered } from '../../../../../../store/report';
import { menuReportPrintItemType } from '../../../../../../constants';

import './MenuReportPrintItem.scss';

export default function MenuReportPrintItem({ data, isGray }) {
  const { name, dishes, index } = data;
  const { t: localize } = useTranslation();

  return (
    <div className={`menu-item-container ${isGray ? 'gray-background' : ''}`}>
      <div className="menu-item-header">
        <div className="name-item">
          {name}
        </div>
        <div className="counter">
          {index}
        </div>
      </div>
      <div className="dish-container">
        {
          dishes
            .map(dishName => (
              <div
                key={Math.random()}
                className={`dish-item ${isNothingOrdered(dishName) ? 'nothing-ordered-item' : ''}`}>
                {`- ${localize(dishName)}`}
              </div>
            ))
        }
      </div>
    </div>
  );
}

MenuReportPrintItem.defaultProps = {
  isGray: false,
};

MenuReportPrintItem.propTypes = {
  isGray: bool,
  data: menuReportPrintItemType.isRequired,
};
