import React from 'react';

import { Grid } from '@mui/material';

import { UserDailyOrder } from './UserOrderComponent';

import { usersDailyOrdersType } from '../../../../../constants';

const classes = {
  menuReportCardsRoot: {
    display: 'flex',
    padding: 2,
  },
  userDailyOrderContainer: {
    height: 'fit-content',
  },
};

function UsersDailyOrders({ orders }) {
  return orders.map(({ user, order, index }) => (
    <Grid
      id="user-daily-order-grid-container"
      item
      xs={12}
      sm={6}
      md={6}
      lg={4}
      xl={3}
      key={user.id}
      sx={classes.userDailyOrderContainer}>

      <UserDailyOrder
        userOrder={order}
        index={index}
        userProfile={user} />
    </Grid>
  ));
}

export default function MenuReportCards(props) {
  return (
    <Grid
      id="menu-report-cards-grid-container"
      container
      spacing={2}
      justifyContent="space-around"
      sx={classes.menuReportCardsRoot}>

      <UsersDailyOrders orders={props.usersDailyOrders} />
    </Grid>
  );
}

MenuReportCards.propTypes = {
  usersDailyOrders: usersDailyOrdersType.isRequired,
};
