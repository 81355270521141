import React from 'react';
import { func } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Paper, Typography } from '@mui/material';

import { MessageContainer, MessageImage, MessageText } from '../../Messages/Templates';
import PandaSleepingImageSvg from '../../../images/pandaSleeping.svg';

import { selectActiveDate, selectActiveDateRange } from '../../../store/activeState';
import { GLOBALS } from '../../../constants';

import './pageStyles.scss';

export default function ConnectionErrorPage(props) {
  const { t: localize } = useTranslation();

  const activeDate = useSelector(selectActiveDate);
  const activeDateRange = useSelector(selectActiveDateRange);

  return (
    <Paper className="paper-no-content-page">
      <MessageContainer>
        <MessageImage
          src={PandaSleepingImageSvg}
          alt={localize('server-connection-error')}
          viewBox={GLOBALS.images.viewBox.pandaSleeping} />
        <MessageText>
          <Typography variant="h6">
            {localize('server-connection-error')}
          </Typography>
        </MessageText>
        <Button variant="contained" onClick={() => props.load(activeDateRange, activeDate)}>
          {localize('button.retry')}
        </Button>
      </MessageContainer>
    </Paper>
  );
}

ConnectionErrorPage.propTypes = {
  load: func.isRequired,
};

