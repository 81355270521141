import { reportPanelActionTypes } from '../componentActions/reportPanelActionTypes';

const initialState = {
  isOpen: false,
};

export default function reportPanelReducer(state = initialState, action) {
  switch (action.type) {
    case reportPanelActionTypes.SET_PANEL_IS_OPENED:
      return {
        isOpen: true,
      };

    case reportPanelActionTypes.SET_PANEL_IS_CLOSED:
      return {
        isOpen: false,
      };

    default:
      return state;
  }
}
