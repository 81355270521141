import React from 'react';
import { string } from 'prop-types';

import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';

import { TooltipApplier } from '../../../Tooltip';

import { themeConstants } from '../../../../constants/themeConstants';

const styles = {
  infoIcon: {
    color: theme => theme.palette.primaryLighter?.main || themeConstants.primaryLighter?.main,
  },
};

const StyledIconButton = styled(IconButton)(() => ({
  '.MuiIconButton-sizeSmall': {
    padding: 0,
  },
  marginLeft: '0.5em',
}));

export default function DishInfoTooltip({ text }) {
  return (
    <TooltipApplier title={text}>
      <StyledIconButton size="small">
        <InfoOutlinedIcon sx={styles.infoIcon} />
      </StyledIconButton>
    </TooltipApplier>
  );
}

DishInfoTooltip.propTypes = {
  text: string.isRequired,
};
