import { areDateRangesEqual, getDateRangeFromEntity, isDateFromDateRange } from '../../utils/dates';
import { findDailyOrderById, sortDailyOrderItemsByPosition, sortPeriodOrdersByLastDate } from './orderUtils';

export const _insertUpdatedPeriodOrder = (state, updatedPO) => {
  const updatedOrderDateRange = getDateRangeFromEntity(updatedPO);
  const periodOrders = state
    .dataArr
    .map(PO => {
      const areForSameDateRange = areDateRangesEqual(updatedOrderDateRange, getDateRangeFromEntity(PO));

      return areForSameDateRange ? updatedPO : PO;
    });

  return sortPeriodOrdersByLastDate(periodOrders);
};

export const _insertUpdatedDailyOrderIntoPeriodOrder = (state, updatedDO) => {
  const periodOrders = state
    .dataArr
    .map(PO => {
      const isDailyOrderFromPO = isDateFromDateRange(updatedDO.date, getDateRangeFromEntity(PO));
      const updatedPO = {
        ...PO,
        dailyOrders: _upsertDailyOrderIfExists(PO, updatedDO)
      };

      return isDailyOrderFromPO ? updatedPO : PO;
    });

  return sortPeriodOrdersByLastDate(periodOrders);
};

export const _insertNewPeriodOrder = (state, PO) => {
  const periodOrders = state.dataArr.concat(PO);

  return sortPeriodOrdersByLastDate(periodOrders);
};

// ___DAILY_ORDER_______________________________________________________________________________________

/**
 * @param periodOrderFromState {PeriodOrder}
 * @param updatedDailyOrder {DailyOrder}
 * @return {DailyOrder[]}
 * @private
 */
export const _upsertDailyOrderIfExists = (periodOrderFromState, updatedDailyOrder) => {
  const isDailyOrderPresentInPeriodOrder = !!findDailyOrderById(periodOrderFromState.dailyOrders, updatedDailyOrder);

  return isDailyOrderPresentInPeriodOrder
         ? _replaceUpdatedDailyOrder(periodOrderFromState.dailyOrders, updatedDailyOrder)
         : _insertNewDailyOrder(periodOrderFromState.dailyOrders, updatedDailyOrder);
};

/**
 * @param dailyOrders {DailyOrder[]}
 * @param updatedDO {DailyOrder}
 * @return {(*|T)[]}
 */
export const _replaceUpdatedDailyOrder = (dailyOrders, updatedDO) => {
  return sortDailyOrderItemsByPosition(dailyOrders
                                         .map(DO => DO.id === updatedDO.id ? updatedDO : DO));
};

export const _insertNewDailyOrder = (dailyOrders, newDO) => {
  return sortDailyOrderItemsByPosition(dailyOrders
                                         .concat(newDO));
};

