import { connect } from 'react-redux';

import MenuReportPanel from './MenuReportPanel';

import { hasFoodlordRole } from '../../../../../store/auth';
import { selectReportPanelIsOpen, setPanelIsClosed, setPanelIsOpened } from '../../../../../store/components';
import { isMenuCompleted, selectPeriodMenuForActiveDate } from '../../../../../store/menu';

const mapStateToProps = state => ({
  showPrintButton: hasFoodlordRole(state) && isMenuCompleted(selectPeriodMenuForActiveDate(state)),
  isOpen: selectReportPanelIsOpen(state),
});

const mapDispatchToProps = {
  open: setPanelIsOpened,
  close: setPanelIsClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuReportPanel);
