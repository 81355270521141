import React, { useRef } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { Grid, Paper, Tab, Tabs } from '@mui/material';

import { Divider } from '../Divider';

import { dateType, themeConstants, themePresets } from '../../constants';

const styles = {
  tabRoot: {
    flex: '1',
    minWidth: 'max-content',
    padding: '0.4rem 0.4rem',
    '&.Mui-selected': {
      fontWeight: 900,
    }
  },
  tabsRoot: {
    flexGrow: 1,
    '&.MuiTabs-indicator': {
      borderRadius: 1,
    }
  },
  holidayColor: {
    color: theme => theme.palette.secondaryLightest?.main || themeConstants.secondaryLightest.main,
  },
  dayPickerPaper: {
    width: 'inherit',
    padding: 0,
    margin: 0,
  },
  dayPickerContainer: {
    ...themePresets.flexColumnContainer,
    height: 'inherit',
  },
  flexColumnContainer: {
    ...themePresets.flexColumnContainer,
    minHeight: 0,
    height: 'inherit',
  },
  scrollable: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  horizScrollable: {
    display: 'flex',
    flexShrink: 0,
    overflowX: 'auto',
  },
};

function renderWeekTabs({ dateToIsHolidayDictionary, dates, getLabel }) {
  return (
    dates.map(date => {
      const isHoliday = dateToIsHolidayDictionary[date.toString()];

      return (
        <Tab
          key={date.toString()}
          label={getLabel(date)}
          value={date.toString()}
          sx={{
            ...styles.tabRoot,

            ...(!isHoliday && styles.holidayColor)
          }} />
      );
    })
  );
}

function DayPicker(props) {
  const childrenRef = useRef(null);
  const { dateToIsHolidayDictionary, dates, handleDateChange, getLabel, selectedDate, } = props;

  const onTabSelect = (e, value) => {
    scrollIntoView(childrenRef.current, {
      scrollMode: 'always',
      block: 'center',
      behavior: 'smooth'
    });

    return handleDateChange(value);
  };

  return (
    <Paper id="day-picker-paper-root" sx={{ ...styles.dayPickerPaper, ...styles.flexColumnContainer }}>
      <Grid id="day-picker-container"
            container sx={styles.flexColumnContainer}>

        <Grid id="week-tabs-container"
              item sx={styles.horizScrollable}>
          <Tabs value={selectedDate.toString()}
                onChange={onTabSelect}
                indicatorColor="primary"
                sx={styles.tabsRoot}
                textColor="primary"
                variant="scrollable">
            {
              renderWeekTabs({
                               dates,
                               dateToIsHolidayDictionary,
                               getLabel,
                             })
            }
          </Tabs>
        </Grid>

        <Divider />

        <Grid id="day-picker-children-container"
              item sx={{ ...styles.flexColumnContainer, ...styles.scrollable }}>
          <div ref={childrenRef} />
          {props.children}
        </Grid>
      </Grid>
    </Paper>

  );
}

DayPicker.propTypes = {
  selectedDate: dateType.isRequired,
  handleDateChange: func.isRequired,
  dateToIsHolidayDictionary: shape({ [string]: bool }).isRequired,
  dates: arrayOf(dateType).isRequired,
  getLabel: func.isRequired,
};

export default DayPicker;
