import { localizeValidations } from '../localizationUtils';

export const CATEGORY_FORM_CONSTRAINTS = {
  name: {
    length: 80,
  },
};

export const DISH_FORM_CONSTRAINTS = {
  name: {
    length: 80,
  },
  description: {
    length: 500,
  },
  price: {
    max: 1000,
    min: 1,
  },
};

export const max = maxValue => value => (
  Number(value || 0) > maxValue
  ? { message: 'max', values: { max: maxValue } }
  : undefined
);

export const min = minValue => value => (
  Number(value || 0) < minValue
  ? { message: 'min', values: { min: minValue } }
  : undefined
);

export const maxLength = maxValue => value => (
  (value || '').length > maxValue
  ? { message: 'maxLength', values: { max: maxValue } }
  : undefined
);

export const maxValue = maxValue => value => (
  value > maxValue
  ? { message: 'maxValue', values: { max: maxValue } }
  : undefined
)

export const required = value => (value ? undefined : 'required');

export const createValidator = (field, ...validators) => value => {
  const error = validators.reduce((res, validator) => res || validator(value), undefined);

  switch (typeof error) {
    case 'string':
      return localizeValidations(`${field}.${error}`);

    case 'object':
      return localizeValidations(`${field}.${error.message}`, error.values);

    default:
      return undefined;
  }
};

export const trimValue = value => (value ? value.trim() : value);

export const allowOnlyDigits = value => (value ? value.replace(/^0|\D/g, '') : value);
