import { areDatesEqual, isDateFromDateRange, iterateOverDateRangeItemsAndPerformAct } from '../../utils/dates';
import { PERIOD_MENU_STATUSES } from '../../constants';

// TODO make it as selector //
//  or should it be another name for function
/**
 * Identifies whether it is holiday by looking for any food in menu for particular day.
 *
 * @param menu {PeriodMenu}
 * @param activeDateRange {DateRange}
 * @returns {Object<String, Boolean>} - Returns Object with date str as key and boolean flag `isHoliday` as value
 */
export function getDateToIsInMenuDictionary(menu, activeDateRange) {
  const dateToIsInMenuFlagDict = {};

  const { firstDate, lastDate } = menu || activeDateRange;

  iterateOverDateRangeItemsAndPerformAct({ firstDate, lastDate })(date => {
    const dateKey = date.toString();

    dateToIsInMenuFlagDict[dateKey] = Boolean(menu);
  });

  return dateToIsInMenuFlagDict;
}

export function isMenuDraft(menu) {
  return menu?.status === PERIOD_MENU_STATUSES.DRAFT;
}

export function isMenuPublished(menu) {
  return menu?.status === PERIOD_MENU_STATUSES.PUBLISHED;
}

export function isMenuPurchased(menu) {
  return menu?.status === PERIOD_MENU_STATUSES.PURCHASED;
}

export function isMenuCompleted(menu) {
  return isMenuPublished(menu) || isMenuPurchased(menu);
}

export const isMenuContainsDate = (menu, date) => {
  const { firstDate, lastDate } = menu;

  return isDateFromDateRange(date, { firstDate, lastDate });
};

export const isMenuForOneDayRange = menu => {
  const { firstDate, lastDate } = menu;

  return areDatesEqual(firstDate, lastDate);
};

export const isDayAddableToMenu = (menu, date) => {
  return menu && !isMenuContainsDate(menu, date) && !isMenuCompleted(menu);
};

export const isDayRemovableFromMenu = (menu, date) => {
  return menu ?
         !isMenuCompleted(menu) && !isMenuForOneDayRange(menu) && isMenuContainsDate(menu, date)
              : false;
};
