import React from 'react';
import { bool, func, shape, string } from 'prop-types';

import { SwipeableDrawer } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { LAYOUT_COMPONENTS_HEIGHTS } from '../../constants/themeConstants';

const getMobileDrawerStyles = (anchor = 'bottom', classes) => {
  const styles = {
    drawerPaper: null,
    dragIcon: null,
  };

  switch (anchor) {
    case 'bottom':
      styles.drawerPaper = classes.bottomMobileDrawerPaper;
      styles.dragIcon = classes.bottomDragIcon;
      break;

    case 'right':
      styles.drawerPaper = classes.rightMobileDrawerPaper;
      styles.dragIcon = classes.rightDragIcon;
      break;
  }

  return styles;
};

const mobileDrawerStyles = {
  bottomMobileDrawerPaper: {
    maxHeight: '90%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    marginBottom: 0
  },
  bottomDragIcon: {
    position: 'absolute',
    left: 'calc(50vw)',
    top: -5,
  },
  rightMobileDrawerPaper: {
    width: '90%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    height: `calc(100vh - ${LAYOUT_COMPONENTS_HEIGHTS.header}px)`,
    marginTop: `${LAYOUT_COMPONENTS_HEIGHTS.header}px`
  },
  rightDragIcon: {
    position: 'absolute',
    left: -5,
    top: 'calc(50vh)',
    transform: 'rotate(-90deg)',
  },
};

export default function MobileDrawer(props) {
  const { anchor, children, closeDrawer, open, openDrawer, } = props;
  const styles = getMobileDrawerStyles(anchor, mobileDrawerStyles);

  const iOS = navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      id="mobile-drawer-root"
      anchor={anchor}
      open={open}
      onClose={closeDrawer}
      onOpen={openDrawer}
      sx={{
        ...props.sx,
        '& .MuiDrawer-paper': styles.drawerPaper
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}>

      <DragHandleIcon sx={styles.dragIcon} />

      {children}
    </SwipeableDrawer>
  );
}

MobileDrawer.propTypes = {
  anchor: string,
  open: bool.isRequired,
  openDrawer: func.isRequired,
  closeDrawer: func.isRequired,
  sx: shape({})
};

MobileDrawer.defaultProps = {
  anchor: 'bottom',
  sx: {}
};
