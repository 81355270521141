import { compareByPosition } from '../comparators/comparator';
import { ALL_CATEGORIES_OPTION } from '../selectors/categorySelectors';

export function sortCategories(categories) {
  return categories.sort(compareByPosition);
}

export const findCategoryById = (categories, categoryId, defaultCategory) => (
  categories.find(category => category.id === categoryId) || defaultCategory
);

export const isAllCategoriesSelected = categoryId => (categoryId === ALL_CATEGORIES_OPTION.id);
