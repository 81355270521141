import NotFoundPage from '../../components/pages/Errors/NotFoundPage/NotFoundPageContainer';

const NotFoundPageConfig = {
  link: '',
  component: NotFoundPage,
  title: 'routes.not-found',
  icon: null,
};

export default NotFoundPageConfig;
