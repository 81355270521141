import { usersActionTypes } from './usersActions/usersActions';

const initialState = {
  names: [],
  filter: [],
  isLoading: false,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case usersActionTypes.FETCH_USER_NAMES_START:
      return {
        ...state,
        isLoading: true,
      };

    case usersActionTypes.FETCH_USER_NAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        names: [...new Set(action.payload)],
      };

    case usersActionTypes.SAVE_USER_FILTER:
      return {
        ...state,
        filter: action.payload,
      };

    default:
      return state;
  }
}
