import { connect } from 'react-redux';

import VendorReport from './VendorReport';
import { withWeekPickerPage } from '../../../Common/WeekPickerPage';

import { foodlordPath } from '../../../../../routes/foodlord/foodlordRoutes';
import {
  fetchVendorReport,
  selectVendorReport,
  selectVendorReportAndDepsLoading,
  selectVendorReportAndDepsLoadingFailed
} from '../../../../../store/report';

import { withActiveDate, withReportErrors } from '../../../../../utils/HOCs';

const mapStateToProps = state => ({
  vendorReport: selectVendorReport(state),
  isLoading: selectVendorReportAndDepsLoading(state),
  isLoadingFailed: selectVendorReportAndDepsLoadingFailed(state),
});

const mapDispatchToProps = dispatch => ({
  load: dateRange => {
    dispatch(fetchVendorReport(dateRange));
  },
  onWeekChange: activeWeekDateRange => {
    dispatch(fetchVendorReport(activeWeekDateRange));
  }
});

export default withActiveDate(
  connect(mapStateToProps, mapDispatchToProps)(
    withWeekPickerPage(foodlordPath.VENDOR_REPORT)(
      withReportErrors(
        VendorReport, 'vendorReport',
      ),
    )
  ),
);
