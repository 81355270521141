import { any, arrayOf, bool, func, instanceOf, number, object, oneOfType, shape, string } from 'prop-types';
import { LocalDate } from '@js-joda/core';

export const dateType = instanceOf(LocalDate);

export const dateRangeType = shape({
                                     firstDate: dateType.isRequired,
                                     lastDate: dateType.isRequired,
                                   });

export const pageConfigType = shape({
                                      link: string.isRequired,
                                      component: oneOfType([
                                                             func,
                                                             object,
                                                           ]).isRequired,
                                      title: string,
                                      icon: oneOfType([
                                                        func,
                                                        object,
                                                      ]),
                                      displayInSideMenu: bool,
                                    });

export const categoryType = shape({
                                    id: number.isRequired,
                                    name: string.isRequired,
                                    position: number.isRequired,
                                    icon: string,
                                  });

export const dishType = shape({
                                id: number.isRequired,
                                name: string.isRequired,
                                officialName: string.isRequired,
                                description: string,
                                price: number.isRequired,
                                category: categoryType,
                                isEveryday: bool.isRequired,
                                blockedCategories: arrayOf(categoryType),
                              });

export const menuItemType = shape({
                                    id: number.isRequired,
                                    date: dateType.isRequired,
                                    food: dishType.isRequired,
                                  });

export const periodMenuType = shape({
                                      id: number.isRequired,
                                      firstDate: dateType.isRequired,
                                      lastDate: dateType.isRequired,
                                      items: arrayOf(menuItemType).isRequired,
                                      status: string.isRequired,
                                    });

export const dailyOrderItemType = shape({
                                          count: number.isRequired,
                                          menuItem: menuItemType.isRequired,
                                        });

export const dailyOrderType = shape({
                                      id: number.isRequired,
                                      date: dateType.isRequired,
                                      orderItems: arrayOf(dailyOrderItemType).isRequired,
                                    });

export const periodOrderType = shape({
                                       id: number.isRequired,
                                       firstDate: dateType.isRequired,
                                       lastDate: dateType.isRequired,
                                       dailyOrders: arrayOf(dailyOrderType).isRequired,
                                       ordered: bool.isRequired,
                                       modified: bool,
                                     });

export const userProfileType = shape({
                                       userName: string.isRequired,
                                       roles: arrayOf(string).isRequired,
                                       avatar: string,
                                     });

export const userType = shape({
                                id: number.isRequired,
                                name: string.isRequired,
                                email: string.isRequired,
                                photo: string.isRequired,
                              });

export const vendorReportItemsType = arrayOf(shape({
                                                     count: number.isRequired,
                                                     date: string.isRequired,
                                                     dishes: arrayOf(dishType).isRequired,
                                                     position: number.isRequired,
                                                   }));

export const vendorReportType = shape(
  { [string]: vendorReportItemsType },
);

export const suggestionsType = arrayOf(shape({
                                               value: dishType.isRequired,
                                               label: string.isRequired,
                                               isDisabled: bool,
                                               group: string,
                                             }));

export const iconType = oneOfType([func, object,]);

export const componentType = oneOfType([func, object, any,]);

export const refType = oneOfType([
                                   // Either a function
                                   func,
                                   // Or the instance of a DOM native element (see the note about SSR)
                                   shape({ current: instanceOf(Element) }),
                                 ]);
