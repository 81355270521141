export function asPriceWithTwoDecimals(number) {
  return Number(number).toFixed(2);
}

export const normalizePrice = value => {
  if (!value) {
    return value;
  }
  const valueWithoutLetters = value.replace(/[^\d.]/g, '');

  const [int, float] = valueWithoutLetters.split('.');

  return float === undefined ? int : `${int}.${float.slice(0, 2)}`;
};
