import React, { useEffect } from 'react';
import { arrayOf, bool, func, shape, string, } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Checkboxes, Select, TextField, } from 'mui-rff';

import { MenuItem } from '@mui/material';

import { DISH_FORM_CONSTRAINTS, max, maxLength, min, required, } from '../../../utils/validations/validations';
import { normalizePrice } from '../../../utils/numberUtils/priceFormat';
import { checkIsFormValid, createValidatorFor } from '../../../utils/componentsUtils/formUtils';
import { categoryType } from '../../../constants/types';

const getValidator = createValidatorFor('dish');

// TODO extract inputs in separate components.
export default function DishFormContent(props) {
  const {
    formProps, setFormValidationStatus, categories, formId,
  } = props;

  useEffect(() => {
    setFormValidationStatus(checkIsFormValid(formProps));
  }, [formProps, setFormValidationStatus]);

  const { t: localize } = useTranslation();

  return (
    <form
      id={formId}
      onSubmit={formProps.handleSubmit}
      noValidate>

      <TextField
        name="name"
        label={localize('label.name')}
        required
        autoFocus
        margin="dense"
        fieldProps={{
          validateFields: [],
          validate: (
            getValidator('name',
                         required,
                         maxLength(DISH_FORM_CONSTRAINTS.name.length))
          ),
        }} />

      <TextField
        name="officialName"
        label={localize('label.official-name')}
        required
        margin="dense"
        fieldProps={{
          validateFields: [],
          validate: (
            getValidator('official-name',
                         required,
                         maxLength(DISH_FORM_CONSTRAINTS.name.length))
          ),
        }} />

      <TextField
        name="description"
        label={localize('label.description')}
        multiline
        margin="dense"
        fieldProps={{
          validateFields: [],
          validate: (
            getValidator('description',
                         maxLength(DISH_FORM_CONSTRAINTS.description.length))
          ),
        }} />

      <TextField
        name="price"
        label={localize('label.price')}
        required
        margin="dense"
        fieldProps={{
          validateFields: [],
          parse: normalizePrice,
          validate: (
            getValidator('price',
                         required,
                         min(DISH_FORM_CONSTRAINTS.price.min),
                         max(DISH_FORM_CONSTRAINTS.price.max))
          ),
        }} />

      <Select
        name="category"
        label={localize('label.category')}
        required
        formControlProps={{ margin: 'dense' }}
        fieldProps={{
          validateFields: [],
          validate: getValidator('category', required),
        }}>
        {
          categories.map(category => (
            <MenuItem value={category} key={category.id}>
              {category.name}
            </MenuItem>
          ))
        }
      </Select>

      <Autocomplete
        name="blockedCategories"
        sx={{ marginTop: '10px' }}
        label={localize('label.blocked-categories')}
        multiple
        options={categories}
        getOptionLabel={option => option.name}/>

      <Checkboxes
        name="isEveryday"
        formControlProps={{
          margin: 'dense',
          fullWidth: true,
        }}
        data={{
          label: localize('label.is-everyday'),
          value: true,
        }} />
    </form>
  );
}

DishFormContent.propTypes = {
  formId: string.isRequired,
  categories: arrayOf(categoryType).isRequired,
  formProps: shape({
                     handleSubmit: func.isRequired,
                     hasSubmitErrors: bool.isRequired,
                     hasValidationErrors: bool.isRequired,
                     dirtySinceLastSubmit: bool.isRequired,
                     submitting: bool.isRequired,
                     pristine: bool.isRequired,
                   }).isRequired,
  setFormValidationStatus: func.isRequired,
};
