import React from 'react';

import { Box, Typography } from '@mui/material';

import { NoDailyOrderMessage } from './NoDailyOrderMessage';
import DailyOrderDishItem from '../../../../../../../ListItems/DishItems/DailyOrderDishItem';

import { getDailyOrderTotal } from '../../../../../../../../store/order';
import { asPriceWithTwoDecimals } from '../../../../../../../../utils/numberUtils';
import { noOrderItemsInDailyOrder, withAlternative } from '../../../../../../../../utils/HOCs';

import { dailyOrderType, dateType } from '../../../../../../../../constants';

const styles = {
  cursorDefault: {
    cursor: 'default',
  },
  dailyOrderDishItem: {
    margin: theme => `0 ${theme.spacing(2)}`,
  },
};

function _DailyOrderDishesWithTotal(props) {
  const { dailyOrder, date } = props;
  const dailyTotal = getDailyOrderTotal(dailyOrder);

  return (
    <Box sx={styles.dailyOrderDishItem}>
      {
        dailyOrder
          .orderItems
          .map(orderItem => (
            <DailyOrderDishItem
              key={`${date}${orderItem.menuItem.id}`}
              orderItem={orderItem} />
          ))
      }
      <Typography align="right"
                  data-test-id={`daily-sum-${date}`}
                  sx={styles.cursorDefault}>
        {`₴ ${asPriceWithTwoDecimals(dailyTotal)}`}
      </Typography>
    </Box>
  );
}

_DailyOrderDishesWithTotal.propTypes = {
  date: dateType.isRequired,
  dailyOrder: dailyOrderType.isRequired,
};

export const DailyOrderDishesWithTotal = withAlternative(noOrderItemsInDailyOrder, NoDailyOrderMessage)(
  _DailyOrderDishesWithTotal
);
