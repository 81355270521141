import { connect } from 'react-redux';

import { PeriodMenuDetails } from './PeriodMenuDetails';

import { EmptyComponent, noMenu, withAlternative } from '../../../../../../utils/HOCs';
import { selectPeriodMenuForActiveDate } from '../../../../../../store/menu';

const mapStateToProps = (state, props) => ({
  menu: selectPeriodMenuForActiveDate(state),
  ...props,  // needed in case someone wants to override menu via props
});

export default connect(mapStateToProps)(
  withAlternative(noMenu, EmptyComponent)(
    PeriodMenuDetails
  )
);
