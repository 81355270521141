import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Fab, Tooltip } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const styles = {
  fullWidthElement: {
    textAlign: 'center',
    bottom: theme => theme.spacing(1),
  },
  centeredFab: {
    margin: '0 auto',
  },
};

function MobileOrderButton(props) {
  const { t: localize } = useTranslation();
  const btnTitle = localize('label.order');

  return (
    <Box sx={styles.fullWidthElement}>
      <Tooltip title={btnTitle} aria-label={btnTitle}>
        <Fab
          color="primary"
          variant="extended"
          onClick={props.onClickListener}
          sx={styles.centeredFab}>
          {btnTitle}
          <ExpandMoreOutlinedIcon />
        </Fab>
      </Tooltip>
    </Box>
  );
}

MobileOrderButton.propTypes = {
  onClickListener: func.isRequired,
};

export default MobileOrderButton;
