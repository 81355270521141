import { selectProfile } from './userProfileSelectors';
import ROLES from '../../constants/roles';

const hasDefinedRole = (userProfile, role) => userProfile
  && userProfile.roles
  && userProfile.roles.indexOf(role) !== -1;

export const hasEaterRole = state => hasDefinedRole(selectProfile(state), ROLES.eater);
export const hasFoodlordRole = state => hasDefinedRole(selectProfile(state), ROLES.foodlord);
export const hasAnyRole = state => hasEaterRole(state) || hasFoodlordRole(state);
