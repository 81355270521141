import React from 'react';
import { Switch } from 'react-router-dom';
import { bool } from 'prop-types';

import NotFoundPageRoute from '../NotFoundPageRoute';

import { generateCommonRoutes } from '../../utils/routeUtils/RouteGenerators';

function CommonRouter(props) {
  return props.isAuthenticated ? (
    <Switch>
      {generateCommonRoutes()}
    </Switch>
  ) : (<NotFoundPageRoute />);
}

CommonRouter.propTypes = {
  isAuthenticated: bool.isRequired,
};

export default CommonRouter;
