import React from 'react';
import { bool, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const styles = {
  dialogTitleRoot: {
    background: 'primary',
  },
};

function Dialog(props) {
  const {
    title,
    description,
    primaryButtonText,
    isOpened,
    onClose,
    onPrimaryButtonClick,
    withCancelButton,
  } = props;

  const { t: localize } = useTranslation();

  // TODO add closing of dialog on Escape key press
  return (
    <MuiDialog
      id="dialog"
      open={isOpened}
      onClose={onClose}>

      <DialogTitle sx={styles.dialogTitleRoot}>
        {localize(title)}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {localize(description)}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {withCancelButton && (
          <Button
            id="cancel-button"
            onClick={onClose}
            autoFocus>
            {localize('button.cancel')}
          </Button>
        )}

        <Button
          id="primary-button"
          color="primary"
          autoFocus
          onClick={onPrimaryButtonClick}>
          {localize(primaryButtonText)}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}

Dialog.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  isOpened: bool.isRequired,
  onClose: func.isRequired,

  onPrimaryButtonClick: func.isRequired,
  primaryButtonText: string.isRequired,

  withCancelButton: bool,
};

Dialog.defaultProps = {
  withCancelButton: false,
};

export default Dialog;
