import React from 'react';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CloseOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, useMediaQuery } from '@mui/material';

import { Item, ItemToolbar } from '../Templates';
import { DishContent } from './Dish';

import { menuItemType } from '../../../constants/types';

function DeleteButton(props) {
  const { t: localize } = useTranslation();
  const matchesBigScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <ItemToolbar isFixed={!matchesBigScreen}>
      <IconButton color="secondary" onClick={() => props.onDelete(props.item)} size="large">
        <Tooltip title={localize('button.delete')}>
          <CloseOutlined />
        </Tooltip>
      </IconButton>
    </ItemToolbar>
  );
}

DeleteButton.propTypes = {
  item: menuItemType.isRequired,
  onDelete: func.isRequired,
};

const styles = {
  item: {
    paddingLeft: 5,
    paddingRight: 3,
  },
};

export default function MenuItem(props) {
  const { item, isDisabled, sx, onDelete } = props;

  return (
    <Item
      id="menu-item"
      sx={{ ...styles.item, ...sx }}>

      <DishContent
        name={item.food.name}
        description={item.food.description}
        isEveryday={item.food.isEveryday}
        withTooltip />

      {isDisabled ? '' : <DeleteButton item={item} onDelete={onDelete} />}
    </Item>
  );
}

MenuItem.propTypes = {
  item: menuItemType.isRequired,
  isDisabled: bool,
  onDelete: func.isRequired,
  sx: shape({}),
};

MenuItem.defaultProps = {
  isDisabled: false,
  sx: {}
};
