import { connect } from 'react-redux';

import { withWeekPickerPage } from '../../Common/WeekPickerPage';
import PeriodMenu from './PeriodMenu';

import { fetchCategories } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';
import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';
import { fetchDishes } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import {
  createPeriodMenu,
  fetchPeriodMenu,
  isMenuAndAllDepsAreLoading,
  isMenuAndAllDepsFailedToLoadDueToConnectionError,
} from '../../../../store/menu';
import { withActiveDate } from '../../../../utils/HOCs';
import { foodlordPath } from '../../../../routes/foodlord/foodlordRoutes';
import { selectActiveDate } from '../../../../store/activeState';

const mapStateToProps = state => ({
  activeDate: selectActiveDate(state),
  isLoading: isMenuAndAllDepsAreLoading(state),
  isLoadingFailed: isMenuAndAllDepsFailedToLoadDueToConnectionError(state),
});

const mapDispatchToProps = dispatch => ({
  load: activeDateRange => {
    dispatch(clearGeneralError());
    dispatch(fetchCategories());
    dispatch(fetchDishes());

    dispatch(fetchPeriodMenu(activeDateRange));
  },
  handleCreatePeriodMenuForDate: activeDate => dispatch(createPeriodMenu(activeDate)),
});

const mergeProps = ({ activeDate, ...propsFromState }, { handleCreatePeriodMenuForDate, load }, { match }) => {
  return ({
    ...propsFromState,
    match,
    load,
    handleCreatePeriodMenu: () => handleCreatePeriodMenuForDate(activeDate),
  });
};

export default withActiveDate(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(
    withWeekPickerPage(foodlordPath.MENU)(
      PeriodMenu
    )
  ),
);
