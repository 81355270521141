import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  margin: 0,
  paddingBottom: 0,
  display: 'flex',
  flexFlow: 'nowrap column',
  '& .Mui-expanded': {
    margin: 0,
  }
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  paddingLeft: 0,
  '&:hover': {
    '& .MuiIconButton-edgeEnd path': {
      fill: '#ff6800',
    },
    '& svg': {
      marginTop: 0,
      height: '1em',
    },
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  }
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: 0,
  flexDirection: 'column',
}));
