import React from 'react';

import { Grid } from '@mui/material';

import { Header } from '../Header';
import { SideMenu } from '../SideMenu';
import { ErrorNotifications } from '../Notifications/Error';
import SuccessNotifications from '../Notifications/Success/SuccessNotificationsContainer';

import { LayoutContentContainer, LayoutContentGrid, LayoutHeaderGrid, LayoutRootGrid } from './LayoutStyledComponents';

function Layout(props) {
  return (
    <LayoutRootGrid id="layout-grid-root" container>

      <LayoutHeaderGrid id="header-grid-root" item>
        <Header />
      </LayoutHeaderGrid>

      <Grid
        id="body-content-root"
        item
        xs
        container
        alignItems="stretch">

        <LayoutContentContainer id="page-content-root-container" maxWidth={false}>
          <SideMenu />

          <LayoutContentGrid id="page-content-grid" item container>
            {props.children}
          </LayoutContentGrid>

          <ErrorNotifications />
          <SuccessNotifications />

        </LayoutContentContainer>
      </Grid>
    </LayoutRootGrid>
  );
}

export default Layout;
