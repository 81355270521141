import React, { createRef, useCallback, useEffect } from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

import { Box, useMediaQuery } from '@mui/material';

import { DailyOrderItem } from './DailyOrderItem';

import { dailyOrderType, dateType } from '../../../../../../../constants';

const styles = {
  dailyOrdersListContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
    marginLeft: '10px',
  },
  mobileDailyOrderItemsContainer: {
    maxHeight: `calc(100vh - 266px)`,
    overflowY: 'auto',
  },
};

function DailyOrderItems(props) {
  const { date, dailyOrders, holidayStatuses } = props;
  const matchesBigScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const dateStrToRefsDict = dailyOrders
    .reduce((acc, dailyOrder) => {
      acc[dailyOrder.date.toString()] = createRef();
      return acc;
    }, {});

  const scrollToDate = useCallback(date => {
    if (matchesBigScreen) {
      const element = dateStrToRefsDict[date.toString()]?.current;

      element && scrollIntoView(element, {
        block: 'start',
      });
    }
  }, [matchesBigScreen, dateStrToRefsDict]);

  useEffect(() => {
    scrollToDate(date);
  }, [date, scrollToDate]);

  return (
    <Box id="daily-order-items-container"
         sx={{
           ...styles.dailyOrdersListContainer,
           ...(!matchesBigScreen && styles.mobileDailyOrderItemsContainer)
         }}>
      {dailyOrders.map(dailyOrder => (
        <DailyOrderItem key={`${dailyOrder.date}-${dailyOrder.id}`}
                        date={dailyOrder.date}
                        dailyOrder={dailyOrder}
                        isSelected={dailyOrder.date.equals(date)}
                        isHoliday={holidayStatuses[dailyOrder.date.toString()]}
                        ref={dateStrToRefsDict[dailyOrder.date.toString()]} />
      ))}
    </Box>
  );
}

DailyOrderItems.propTypes = {
  dailyOrders: arrayOf(dailyOrderType),
  holidayStatuses: shape({ [string]: bool }).isRequired,
  date: dateType.isRequired,
};

DailyOrderItems.defaultProps = {
  dailyOrders: [],
};

export default DailyOrderItems;
