export const successActionTypes = {
  SET_SUCCESS_STATUS: '[SUCCESS] Set success status',
  CLEAR_SUCCESS_STATUS: '[SUCCESS] Clear success status',
};

export const setSuccessStatus = messageKey => ({
  type: successActionTypes.SET_SUCCESS_STATUS,
  payload: messageKey,
});

export const clearSuccessStatus = () => ({
  type: successActionTypes.CLEAR_SUCCESS_STATUS,
});

