import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';

import {
  getReducedDishesIds,
  getReducedDishesVendorNames,
  joinWithPlus
} from '../../../../../../store/report';
import { themeConstants, vendorReportItemsType } from '../../../../../../constants';

const styles = {
  tableHeader: {
    backgroundColor: theme => theme.palette.primaryLightest?.main || themeConstants.primaryLightest.main,
  },
};

function VendorReportTable(props) {
  const { t: localize } = useTranslation();

  return (
    <Table>
      <TableHead sx={styles.tableHeader}>
        <TableRow>
          <TableCell>{localize('label.dish')}</TableCell>
          <TableCell>{localize('label.count')}</TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {
          props.data.map(({ dishes, date, count }) => {
            const tableRowKey = joinWithPlus(getReducedDishesIds(dishes), date);
            const reducedDishesOfficialNames = getReducedDishesVendorNames(dishes);

            return (
              <TableRow key={tableRowKey}>
                <TableCell>{reducedDishesOfficialNames}</TableCell>
                <TableCell>{count}</TableCell>
              </TableRow>
            );
          })
        }
      </TableBody>
    </Table>
  );
}

VendorReportTable.propTypes = {
  data: vendorReportItemsType,
};

VendorReportTable.defaultProps = {
  data: [],
};

export default VendorReportTable;
