import { connect } from 'react-redux';

import DayPicker from './DayPicker';

import { getDateLabelForWeekTab, iterateOverDateRangeItemsAndPerformAct } from '../../utils/dates';
import { selectActiveDate, selectWeekBoundsForActiveDay } from '../../store/activeState';
import { getDateToIsInMenuDictionary, selectPeriodMenuForActiveDate } from '../../store/menu';

const getDatesForTabsToRender = dateRange => {
  const dates = [];
  iterateOverDateRangeItemsAndPerformAct(dateRange)(date => {
    dates.push(date);
  });
  return dates;
};

const mapStateToProps = state => {
  const dateRange = selectWeekBoundsForActiveDay(state);
  const periodMenu = selectPeriodMenuForActiveDate(state);

  return ({
    dateToIsHolidayDictionary: getDateToIsInMenuDictionary(periodMenu, dateRange),
    selectedDate: selectActiveDate(state),
    dates: getDatesForTabsToRender(dateRange),
    getLabel: date => getDateLabelForWeekTab(date),
  });
};

export default connect(mapStateToProps)(DayPicker);
