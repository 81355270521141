import { connect } from 'react-redux';

import { PeriodMenuToolbar } from './PeriodMenuToolbar';
import { selectLastDraftPeriodMenu, selectPeriodMenuForActiveDate } from '../../../../../../store/menu';

const mapStateToProps = state => ({
  menu: selectPeriodMenuForActiveDate(state) || selectLastDraftPeriodMenu(state),
});

export default connect(mapStateToProps)(PeriodMenuToolbar);

