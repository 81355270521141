import React from 'react';
import { func, string } from 'prop-types';

import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Close as CloseIcon, Search as SearchIcon } from '@mui/icons-material';

const styles = {
  searchField: {
    width: '100%',
  },
};

export default function SearchInput(props) {
  return (
    <TextField
      id="search-input"
      label={props.label}
      type="text"
      variant="filled"
      onChange={event => props.onChange(event.currentTarget.value)}
      sx={styles.searchField}
      value={props.value}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: props.value && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear input value"
              onClick={props.clearValue}
              size="small"
              edge="end">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }} />
  );
}

SearchInput.propTypes = {
  label: string.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
  clearValue: func.isRequired,
};
