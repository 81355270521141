import React from 'react';
import { useTranslation } from 'react-i18next';
import { bool, func, string } from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@mui/material';

const styles = {
  dialogTitleRoot: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  dialogContentRoot: {
    paddingTop: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  dialogActionsRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};
/**
 *
 * @param props
 * @param props.children isRequired! - html <form> to render and submit
 * @return {*}
 * @constructor
 */
export default function FormDialog(props) {
  const { t: localize } = useTranslation();

  return (
    <Dialog
      id={`${props.formIdToSubmit}-dialog-root`}
      onClose={props.onClose}
      open
      scroll="paper">

      <DialogTitle id={`${props.formIdToSubmit}-dialog-title`} sx={styles.dialogTitleRoot}>
        {localize(props.title)}
      </DialogTitle>

      <DialogContent id={`${props.formIdToSubmit}-dialog-content`} sx={styles.dialogContentRoot}>
        {props.children}
      </DialogContent>

      <DialogActions id={`${props.formIdToSubmit}-dialog-actions`} sx={styles.dialogActionsRoot}>

        <Button onClick={props.onClose} id={`${props.formIdToSubmit}-cancel-button`}>
          {localize('button.cancel')}
        </Button>

        <Button
          id={`${props.formIdToSubmit}-submit-button`}
          type="submit"
          form={props.formIdToSubmit}
          color="primary"
          disabled={props.isSubmitDisabled}>
          {localize('button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FormDialog.propTypes = {
  title: string.isRequired,
  formIdToSubmit: string.isRequired,
  onClose: func.isRequired,
  isSubmitDisabled: bool.isRequired,
};

