import React from 'react';

import { Box, Grid, Paper } from '@mui/material';

import { VendorReportTable } from './VendorReportTable';
import { DayPicker } from '../../../../DayPicker';

import { dateType, themePresets, vendorReportItemsType, vendorReportType } from '../../../../../constants';
import { dateToUrlSetter } from '../../../../../utils/dates';
import { foodlordPath } from '../../../../../routes/foodlord/foodlordRoutes';

const styles = {
  flexColumnContainer: {
    ...themePresets.flexColumnContainer,
  },
  scrollable: {
    overflowY: 'auto',
  },
};

function ReportTable(props) {
  const { date, report } = props;

  return (
    <Box
      id="vendor-report-daily-item"
      sx={styles.flexColumnContainer}
      key={date.toString()}>

      <VendorReportTable data={report} />
    </Box>
  );
}

ReportTable.propTypes = {
  date: dateType.isRequired,
  report: vendorReportItemsType,
};

ReportTable.defaultProps = {
  report: null,
};

function VendorReport(props) {
  const { date, vendorReport } = props;
  const dateString = date.toString();

  return (
    <Paper
      id="vendor-report-paper-root"
      sx={{ ...styles.flexColumnContainer, ...styles.scrollable }}>

      <Grid
        id="vendor-report-grid"
        sx={{ ...styles.flexColumnContainer, ...styles.scrollable }}>

        <DayPicker handleDateChange={dateToUrlSetter(foodlordPath.VENDOR_REPORT)}>

          <ReportTable report={vendorReport[dateString]} date={date} />

        </DayPicker>
      </Grid>
    </Paper>
  );
}

VendorReport.propTypes = {
  date: dateType.isRequired,
  vendorReport: vendorReportType.isRequired,
};

export default VendorReport;
