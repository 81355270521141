export const dishesActionTypes = {
  FETCH_DISHES_START: '[DISHES] Fetch dishes start',
  FETCH_DISHES_SUCCESS: '[DISHES] Fetch dishes success',
  DISHES_VALIDATION_ERROR: '[DISHES] Dishes validation error',
  DELETE_DISH_SUCCESS: '[DISHES] Dish deletion success',
  ADD_DISH_SUCCESS: '[DISHES] Add dish success',
  UPDATE_DISH_SUCCESS: '[DISHES] Update dish success',
  CLEAR_DISH_ERROR: '[DISHES] Clear dish error',
  SORT_DISHES_BY_NAME: '[DISHES] Sort dishes by name',
};

export const fetchDishesStart = () => ({
  type: dishesActionTypes.FETCH_DISHES_START,
});

export const fetchDishesSuccess = dishes => ({
  type: dishesActionTypes.FETCH_DISHES_SUCCESS,
  payload: dishes,
});

export const sortDishesByName = () => ({
  type: dishesActionTypes.SORT_DISHES_BY_NAME,
});

export const deleteDishSuccess = id => ({
  type: dishesActionTypes.DELETE_DISH_SUCCESS,
  payload: id,
});

export const addDishSuccess = dish => ({
  type: dishesActionTypes.ADD_DISH_SUCCESS,
  payload: dish,
});

export const updateDishSuccess = dish => ({
  type: dishesActionTypes.UPDATE_DISH_SUCCESS,
  payload: dish,
});
