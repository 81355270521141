import { authorizationActionTypes } from './actions/authorization/authorizationActions';

const initialState = {
  user: null,
  isLoading: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case authorizationActionTypes.FETCH_USER_PROFILE_START:
      return {
        ...state,
        user: null,
        isLoading: true,
      };

    case authorizationActionTypes.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
