import i18n from 'i18next';

import GLOBALS from '../../constants/globals';

const DEFAULT_LANGUAGE = 'uk';
export const FALLBACK_LANGUAGE = 'en';

const LANGUAGES = [
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
];

/**
 * Translates validation messages to current locale with parameters replacement
 * @param message part of the error message followed by <code>validation.<name>.</code>
 * @param config the object of type <i>{ searchValue: replaceValue }</i>
 * @returns {string} localized message
 */
export const localizeValidations = (message, config = {}) => Object.entries(config).reduce(
  (text, [key, value]) => text.replace(`{${key}}`, value), i18n.t(`validation.${message}`),
);

export const localizeValidationErrors = errors => Object.entries(errors).reduce((res, [key, value]) => {
  res[key] = i18n.t(value);
  return res;
}, {});

export function getDefaultLanguage() {
  const localStorageLng = localStorage.getItem(GLOBALS.localStorage.languageKey);

  return localStorageLng && LANGUAGES.indexOf(localStorageLng) !== -1
         ? localStorageLng
         : DEFAULT_LANGUAGE;
}

const setDefaultLanguage = language => {
  localStorage.setItem(GLOBALS.localStorage.languageKey, language);
};

export function changeAppLanguage(lng) {
  i18n.changeLanguage(lng)
      .then(() => setDefaultLanguage(lng));
}
