import { connect } from 'react-redux';
import ReportPanelButton from './ReportPanelButton';
import { setPanelIsClosed, setPanelIsOpened, } from '../../../store/components';

const mapStateToProps = state => ({
  isOpen: state.components.reportPanel.isOpen,
});

const mapDispatchToProps = {
  open: setPanelIsOpened,
  close: setPanelIsClosed,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPanelButton);
