import React from 'react';
import { bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { List, ListSubheader } from '@mui/material';
import {
  getCommonSideMenuReportRoutes,
  getFoodlordSideMenuReportRoutes,
} from '../../../utils/selectors/routeSelectors';

const reportItems = {
  foodlord: getFoodlordSideMenuReportRoutes(),
  common: getCommonSideMenuReportRoutes(),
};

const styles = {
  listSubheaderRoot: {
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
  },
};

function ReportsSubmenu(props) {
  const { t: localize } = useTranslation();

  return (
    <List
      component="nav"
      aria-labelledby="reports-submenu"
      subheader={(
        <ListSubheader
          component="div"
          id="reports-submenu"
          sx={styles.listSubheaderRoot}>
          {localize('menu.reports')}
        </ListSubheader>
      )}>
      {props.hasFoodlordRole && props.renderMenuLinks(reportItems.foodlord)}
      {props.hasAnyRole && props.renderMenuLinks(reportItems.common)}
    </List>
  );
}

ReportsSubmenu.propTypes = {
  hasFoodlordRole: bool.isRequired,
  hasAnyRole: bool.isRequired,
  renderMenuLinks: func.isRequired,
};

export default ReportsSubmenu;
