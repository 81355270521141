import axiosInstance from '../../../axiosInstance';
import {
  addMenuItemSuccess,
  createDailyMenuSuccess,
  createPeriodMenuSuccess,
  deletePeriodMenuSuccess,
  fetchPeriodMenuSuccess,
  loadingPeriodMenuStart,
  removeDailyMenuSuccess,
  removeMenuItemSuccess,
  updatePeriodMenuStatusSuccess,
} from './menuActions';
import { setGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';

import { DateRangeDto } from '../../../models/DateRange';
import { MenuItem, MenuItemDto, PeriodMenu } from '../../../models';

import { PERIOD_MENU_STATUSES } from '../../../constants';
import { getWeekBounds } from '../../../utils/dates';

const MENUS = '/menus';

export const fetchPeriodMenu = dateRange => dispatch => {
  dispatch(loadingPeriodMenuStart());
  const dateRangeDto = new DateRangeDto(dateRange);

  return axiosInstance
    .get(MENUS, { params: dateRangeDto })
    .then(res => res?.data && dispatch(fetchPeriodMenuSuccess(res.data.map(wm => new PeriodMenu(wm)))))
    .catch(error => dispatch(setGeneralError(error)));
};

// TODO replace with fetchPeriodMenu and delete this method!
export const fetchPeriodMenuForWeek = date => dispatch => {
  dispatch(loadingPeriodMenuStart());
  const dateRangeDto = new DateRangeDto(getWeekBounds(date));

  return axiosInstance
    .get(MENUS, { params: dateRangeDto })
    .then(res => res?.data && dispatch(fetchPeriodMenuSuccess(res.data.map(wm => new PeriodMenu(wm)))))
    .catch(error => dispatch(setGeneralError(error)));
};

export const createPeriodMenu = date => dispatch => {
  dispatch(loadingPeriodMenuStart());
  const data = {
    ...new DateRangeDto({ firstDate: date, lastDate: date }),
    status: PERIOD_MENU_STATUSES.DRAFT,
  };

  return axiosInstance
    .post(MENUS, data)
    .then(res => res && dispatch(createPeriodMenuSuccess(new PeriodMenu(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};

export const deletePeriodMenu = menu => dispatch => {
  dispatch(loadingPeriodMenuStart());

  return axiosInstance
    .delete(`${MENUS}/${menu.id}`)
    .then(res => res && dispatch(deletePeriodMenuSuccess(menu)))
    .catch(error => dispatch(setGeneralError(error)));
};

export const updatePeriodMenuStatus = (menu, status) => dispatch => {
  return axiosInstance
    .put(`${MENUS}/${menu.id}/status`, status, { headers: { 'Content-Type': 'text/plain' } })
    .then(res => res && dispatch(updatePeriodMenuStatusSuccess(new PeriodMenu(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};

export const addMenuItem = (menu, item) => dispatch => {
  return axiosInstance
    .post(`${MENUS}/${menu.id}/items`, new MenuItemDto(item))
    .then(res => res?.data && dispatch(addMenuItemSuccess(menu, new MenuItem(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};

export const removeMenuItem = (menu, item) => dispatch => {
  return axiosInstance
    .delete(`${MENUS}/${menu.id}/items/${item.id}`)
    .then(response => response.status === 204 && dispatch(removeMenuItemSuccess(menu, item)))
    .catch(error => dispatch(setGeneralError(error)));
};

export const createDailyMenu = (menu, date) => dispatch => {
  return axiosInstance
    .put(`${MENUS}/${menu.id}/days/${date.toString()}`)
    .then(res => res?.data && dispatch(createDailyMenuSuccess(new PeriodMenu(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};

export const removeDailyMenu = (menu, date) => dispatch => {
  return axiosInstance
    .delete(`${MENUS}/${menu.id}/days/${date.toString()}`)
    .then(res => res?.data && dispatch(removeDailyMenuSuccess(new PeriodMenu(res.data))))
    .catch(error => dispatch(setGeneralError(error)));
};
