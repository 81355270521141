import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';

import { PrintButton } from '../../../../Buttons';
import { PrintableStickersReport } from '../../../Foodlord/Reports/StickersReport';
import { PrintableMenuReport } from './PrintableMenuReport';

import './MenuReportPrint.scss';

export default function ReportPrintButtons() {
  const printContainerRef = useRef();

  const [PrintComponent, setPrintComponent] = useState(null);

  const collapseContent = () => setPrintComponent(null);

  return (
    <>
      <ReactToPrint
        trigger={() => <PrintButton labelName="label.print-menu-report" />}
        content={() => printContainerRef.current}
        onBeforeGetContent={() => {
          setPrintComponent(PrintableMenuReport);
          return Promise.resolve();
        }}
        onAfterPrint={collapseContent} />

      <ReactToPrint
        trigger={() => <PrintButton labelName="label.print-stickers" />}
        content={() => printContainerRef.current}
        onBeforeGetContent={() => {
          setPrintComponent(PrintableStickersReport);
          return Promise.resolve();
        }}
        onAfterPrint={collapseContent} />

      <div className="hide-content">
        <div ref={printContainerRef}>
          {PrintComponent && <PrintComponent />}
        </div>
      </div>
    </>
  );
}
