import { findCategoryByIdOrGetDefault } from './categorySelectors';
import { isAllCategoriesSelected } from '../entitiesUtils/categoriesUtils';

const selectDishes = state => (state.dishes.data);

export const selectDishById = (state, dishId) => (
  selectDishes(state)
    .find(dish => dish.id === parseInt(dishId, 10)));

const selectDishesByCategory = (state, category) => (
  selectDishes(state)
    .filter(dish => dish.category.id === category.id)
);

const selectAppointedDishesByCategory = (state, category) => (
  selectDishesByCategory(state, category)
    .filter(dish => dish.isEveryday === false)
);

const selectEverydayDishesByCategory = (state, category) => (
  selectDishesByCategory(state, category)
    .filter(dish => dish.isEveryday === true)
);

const selectFilteredDishes = (state, filter) => {
  const category = findCategoryByIdOrGetDefault(state, filter);

  return isAllCategoriesSelected(category.id)
         ? selectDishes(state)
         : selectDishesByCategory(state, category);
};

const includes = (source, value) => source && (
  source.toLowerCase()
        .includes(value.toLowerCase())
);

export function selectDishesByCriteria(dishes, filterCriteria) {
  return dishes.filter(dish => {
    const { name, description } = dish;
    const matches = includes(name, filterCriteria) || includes(description, filterCriteria);

    return matches && dish;
  });
}

export {
  selectDishes,
  selectDishesByCategory,
  selectFilteredDishes,
  selectAppointedDishesByCategory,
  selectEverydayDishesByCategory,
};
