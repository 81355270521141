import {
  createDailyMenu,
  isMenuCompleted,
  isMenuContainsDate,
  isMenuForOneDayRange,
  removeDailyMenu
} from '../../../../../../../store/menu';

export const DAILY_ACTIONS_DIALOGS_CONFIG = {
  createDailyMenu: {
    title: 'toolbar.should-daily-menu-be-created',
    description: 'toolbar.you-are-going-to-create-daily-menu',
    action: (menu, date) => createDailyMenu(menu, date),
    label: 'label.create-daily-menu',
    variant: 'outlined',
    disabled: (menu, date) => !menu || isMenuContainsDate(menu, date) || isMenuCompleted(menu)
  },
  removeDailyMenu: {
    title: 'toolbar.should-this-day-be-deleted-from-menu',
    description: 'toolbar.you-are-going-to-delete-daily-menu',
    action: (menu, date) => removeDailyMenu(menu, date),
    label: 'label.delete-day',
    variant: 'outlined',
    disabled: (menu, date) => {
      return menu ?
             isMenuCompleted(menu) || isMenuForOneDayRange(menu) || !isMenuContainsDate(menu, date)
                  : true;
    },
  },
};
