import { connect } from 'react-redux';
import FoodlordCategoriesPage from './Categories';
import { fetchCategories } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';
import { isAnyOfEntitiesLoading, isLoadingEntitiesFailedDueToConnectionError } from '../../../../utils/selectors';
import { fetchDishes } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';

const mapStateToProps = state => ({
  // TODO move to selector
  categories: state.categories.data,
  isLoading: isAnyOfEntitiesLoading(state, 'dishes', 'categories'),
  isLoadingFailed: isLoadingEntitiesFailedDueToConnectionError(state, 'dishes', 'categories'),
});

const mapDispatchToProps = dispatch => ({
  load: () => {
    dispatch(clearGeneralError());
    dispatch(fetchCategories());
    dispatch(fetchDishes());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  FoodlordCategoriesPage,
);
