import { locationActionTypes } from '../actions/luncherActions/locationActions/locationActions';
import NotFoundPageConfig from '../routes/applicationPages/notFoundPageConfig';

const initialState = {
  currentPath: '',
  currentPageConfig: {
    link: '',
    component: () => {},
    title: '',
    icon: null,
    displayInSideMenu: false,
  },
  isNotFoundPage: false,
};

export default function locationReducer(state = initialState, action) {
  switch (action.type) {
    case locationActionTypes.SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload,
      };

    case locationActionTypes.SET_CURRENT_PAGE_CONFIG:
    case locationActionTypes.SET_NOT_FOUND_PAGE_CONFIG:
      return {
        ...state,
        currentPageConfig: action.payload,
        isNotFoundPage: action.payload.title === NotFoundPageConfig.title,
      };

    default:
      return state;
  }
}
