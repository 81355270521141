import React from 'react';
import { RowContainer } from './RowContainer';
import { EmptySticker } from './EmptySticker';

export function EmptyStickersRow() {
  return (
    <RowContainer>
      <EmptySticker />
    </RowContainer>
  );
}
