import LastReportContainer from '../../components/pages/Common/LastReportContainer';
import { MenuReport } from '../../components/pages/Common/MenuReport';

import MenuReportIconSvg from '../../images/menuIcons/menu-report-figma.svg';

import { commonRoutes } from '../common/commonRoutes';

const CommonPagesConfig = {
  [commonRoutes.MENU_REPORT]: {
    link: commonRoutes.MENU_REPORT,
    component: MenuReport,
    title: 'routes.menu-report',
    icon: null,
  },
  [commonRoutes.LAST_MENU_REPORT]: {
    link: commonRoutes.LAST_MENU_REPORT,
    component: LastReportContainer,
    title: 'routes.menu-report',
    icon: MenuReportIconSvg,
    displayInSideMenuReports: true,
  },
};

export default CommonPagesConfig;
