import React from 'react';
import { bool, string } from 'prop-types';

import { SvgIcon } from '@mui/material';

import SoupPlateImageSvg from '../../images/soup-plate.svg';
import RiceBowlImageSvg from '../../images/rice-bowl.svg';
import SaladImageSvg from '../../images/salad.svg';
import HalalFoodImageSvg from '../../images/halal-food.svg';
import IceCreamConeImageSvg from '../../images/ice-cream-cone.svg';
import CarbohydratesImageSvg from '../../images/carbohydrates.svg';
import KawaiiSteakImageSvg from '../../images/kawaii-steak.svg';
import KetchupImageSvg from '../../images/ketchup.svg';
import CabbageImageSvg from '../../images/cabbage.svg';

import GLOBALS from '../../constants/globals';

const DEFAULT_ICON_NAME = 'General';

const icons = {
  Soup: SoupPlateImageSvg,
  Main: RiceBowlImageSvg,
  Garnish: CarbohydratesImageSvg,
  Meat: KawaiiSteakImageSvg,
  Sauce: KetchupImageSvg,
  Salad: SaladImageSvg,
  Dessert: IceCreamConeImageSvg,
  Vegetables: CabbageImageSvg,
  General: HalalFoodImageSvg,
};

export default function CategoryIcon({ icon, isWhite }) {
  const Svg = icons[icon || DEFAULT_ICON_NAME];

  return (
    <SvgIcon component={Svg}
             viewBox={GLOBALS.images.viewBox.category}
             sx={{
               marginLeft: '0.3em',
               marginTop: '0.05em',
               height: '0.93em',
               '& g': {
                 fill: theme => isWhite ? theme.palette.common.white : theme.palette.common.black
               }
             }} />
  );
}

CategoryIcon.defaultProps = {
  icon: DEFAULT_ICON_NAME,
  isWhite: false,
};

CategoryIcon.propTypes = {
  icon: string,
  isWhite: bool,
};
