import { connect } from 'react-redux';

import { withWeekPickerPage } from '../../Common/WeekPickerPage';
import OrderPage from './Order';

import { clearGeneralError } from '../../../../actions/luncherActions/errorActions/errorActions';
import { fetchPeriodMenu, selectPeriodMenuForActiveDate } from '../../../../store/menu';
import {
  fetchPeriodOrders,
  selectIsOrderWithDepsLoading,
  selectIsOrderWithDepsLoadingFailed,
  selectOrderForActiveDate,
} from '../../../../store/order';
import { withActiveDate } from '../../../../utils/HOCs';
import { eaterPath } from '../../../../routes/eater/eaterRoutes';

const mapStateToProps = state => ({
  menu: selectPeriodMenuForActiveDate(state),
  order: selectOrderForActiveDate(state),
  isLoading: selectIsOrderWithDepsLoading(state),
  isLoadingFailed: selectIsOrderWithDepsLoadingFailed(state),
});

const mapDispatchToProps = dispatch => ({
  load: (dateRange, activeDate) => {
    dispatch(clearGeneralError());

    dispatch(fetchPeriodOrders(dateRange, activeDate));
    dispatch(fetchPeriodMenu(dateRange));
  }
});

export default withActiveDate(
  connect(mapStateToProps, mapDispatchToProps)(
    withWeekPickerPage(eaterPath.ORDER)(
      OrderPage,
    ),
  ),
);
