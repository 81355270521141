export const weekPickerStyles = {
  button: {
    '& .MuiButton-textSizeSmall': {
      padding: 0,
    }
  },
  popover: {
    marginTop: 1,
  },
  weekPickerPaperRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  calendarContainer: {
    padding: 2,
  },
  weekPickerArrow: {
    padding: '1px',
    fontSize: 'medium',
  },
};
