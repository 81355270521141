import React, { useEffect } from 'react';
import { bool, func, number, string, } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { Container, Paper } from '@mui/material';

import { AddButton } from '../../../Buttons';
import { LoadingSpinnerProvider } from '../../../LoadingSpinnerProvider';
import AddDishFormDialog from '../../../Forms/DishForm/DishFormContainers/AddDishFormContainer';
import EditDishFormDialog from '../../../Forms/DishForm/DishFormContainers/EditDishFormContainer';
import { DishesList } from '../../../Lists/DishesList';

import { foodlordRoutes } from '../../../../routes/foodlord/foodlordRoutes';
import { withConnectionErrorHandler } from '../../../../utils/HOCs';
import { themePresets } from '../../../../constants';

const styles = {
  flexContainer: {
    ...themePresets.flexColumnContainer,
    height: 'inherit',
  },
};

function DishesPage(props) {
  const { t: localize } = useTranslation();

  return (
    <Paper sx={styles.flexContainer}>
      <Container sx={styles.flexContainer}>
        <DishesList
          selectedCategoryId={props.selectedCategoryId} />

        <AddButton
          href={props.addNewDishUrl}
          label={localize('label.add-dish')} />
      </Container>
    </Paper>
  );
}

DishesPage.propTypes = {
  addNewDishUrl: string.isRequired,
  selectedCategoryId: number.isRequired,
};

function Dishes(props) {
  const {
    selectedCategoryId, dishListUrl, isLoading, addNewDishUrl, isValidDishId, load,
  } = props;

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LoadingSpinnerProvider isLoading={isLoading}>
      <>
        <DishesPage
          selectedCategoryId={selectedCategoryId}
          addNewDishUrl={addNewDishUrl} />

        <Switch>
          <Route
            exact
            path={foodlordRoutes.ADD_DISH}
            render={() => (
              <AddDishFormDialog
                onCloseRedirectUrl={dishListUrl}
                selectedCategoryId={selectedCategoryId} />
            )} />

          <Route
            exact
            path={foodlordRoutes.EDIT_DISH}
            render={({ match }) => (
              // TODO handle case with wrong category ID in Url
              isValidDishId(match.params.id) && (
                <EditDishFormDialog
                  onCloseRedirectUrl={dishListUrl}
                  selectedCategoryId={selectedCategoryId} />
              )
            )} />
        </Switch>
      </>
    </LoadingSpinnerProvider>
  );
}

Dishes.propTypes = {
  isLoading: bool.isRequired,
  load: func.isRequired,
  isValidDishId: func.isRequired,
  addNewDishUrl: string.isRequired,
  dishListUrl: string.isRequired,
  selectedCategoryId: number.isRequired,
};

export default withConnectionErrorHandler(Dishes);
