import { DailyOrder, DailyOrderDto } from './DailyOrder';
import { DateRange, DateRangeDto } from '../../DateRange';

import { sortDailyOrderItemsByPosition } from '../../../store/order';
import { getDateRangeFromEntity } from '../../../utils/dates';

export class PeriodOrderDto {
  id;
  /**
   * @type {string}
   */
  firstDate;
  /**
   * @type {string}
   */
  lastDate;
  /**
   * @type {DailyOrderDto[]}
   */
  dailyOrders;
  ordered;

  /**
   * @param periodOrder {PeriodOrder}
   */
  constructor(periodOrder) {
    Object.assign(this, {
      ...periodOrder,
      ...new DateRangeDto(getDateRangeFromEntity(periodOrder)),
      dailyOrders: periodOrder.dailyOrders.map(o => new DailyOrderDto(o))
    });
  }
}

/**
 * @namespace PeriodOrder
 * @property {number} PeriodOrder.id
 * @property {LocalDate} PeriodOrder.firstDate
 * @property {LocalDate} PeriodOrder.lastDate
 * @property {DailyOrder[]} PeriodOrder.dailyOrders
 * @property {boolean} PeriodOrder.ordered
 */
export class PeriodOrder {
  id;
  firstDate;
  lastDate;
  dailyOrders;
  ordered;

  /**
   * @param orderDto {PeriodOrderDto}
   */
  constructor(orderDto) {
    Object.assign(this, {
      ...orderDto,
      ...new DateRange(getDateRangeFromEntity(orderDto)),
      dailyOrders: sortDailyOrderItemsByPosition(orderDto.dailyOrders.map(o => new DailyOrder(o))),
    });
  }
}
