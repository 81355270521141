import { DateRange, DateRangeDto } from '../../DateRange';
import { MenuItem, MenuItemDto } from './MenuItem';
import { getDateRangeFromEntity } from '../../../utils/dates';

export class PeriodMenuDto {
  id;
  status;
  firstDate;
  lastDate;
  items;

  constructor(periodMenu) {
    Object.assign(this, {
      ...periodMenu,
      ...new DateRangeDto(getDateRangeFromEntity(periodMenu)),
      items: periodMenu.items.map(item => new MenuItemDto(item)),
    });
  }
}

/**
 * @namespace PeriodMenu
 * @property {number} PeriodMenu.id
 * @property {string} PeriodMenu.status
 * @property {LocalDate} PeriodMenu.firstDate
 * @property {LocalDate} PeriodMenu.lastDate
 * @property {MenuItem[]} PeriodMenu.items
 */
export class PeriodMenu {
  id;
  status;
  firstDate;
  lastDate;
  items;

  constructor(periodMenuDto) {
    Object.assign(this, {
      ...periodMenuDto,
      ...new DateRange(getDateRangeFromEntity(periodMenuDto)),
      items: periodMenuDto.items.map(item => new MenuItem(item)),
    });
  }
}
