import React from 'react';
import { bool, func, string } from 'prop-types';

import EditButton from '../../../Buttons/EditButton';
import ItemToolbar from '../../Templates/ItemToolbar';
import DeleteDishButton from '../../../Buttons/DeleteDishButton';
import Item from '../../Templates/Item';
import DishContent from '../Dish/DishContent';
import DishPrice from '../Dish/DishPrice';

import { dishType, refType } from '../../../../constants/types/types';

function EditableDishItem(props) {
  const {
    dish, deleteDish, editButtonUrl, newDishRef, isHighlighted,
  } = props;

  return (
    <Item id={`item-${dish.name}`} isHighlighted={isHighlighted} useOnHover forwardRef={newDishRef}>
      <DishContent name={dish.name} description={dish.description} isEveryday={dish.isEveryday} />
      <DishPrice price={dish.price} />
      <ItemToolbar>
        <EditButton href={editButtonUrl} />
        <DeleteDishButton onConfirm={deleteDish} />
      </ItemToolbar>
    </Item>
  );
}

EditableDishItem.propTypes = {
  isHighlighted: bool,
  newDishRef: refType,
  deleteDish: func.isRequired,
  dish: dishType.isRequired,
  editButtonUrl: string.isRequired,
};

EditableDishItem.defaultProps = {
  newDishRef: null,
  isHighlighted: false,
};

export default EditableDishItem;
