import { connect } from 'react-redux';
import ErrorNotifications from './ErrorNotifications';
import { clearGeneralError } from '../../../actions/luncherActions/errorActions/errorActions';

const mapStateToProps = state => {
  const error = state?.error?.status;
  return ({
    status: error ? error : '',
  });
};

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clearGeneralError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNotifications);
