import React from 'react';
import { func, string } from 'prop-types';

import { Grid, Typography, useMediaQuery } from '@mui/material';

export function PageTitle({ title }) {
  return (
    <Grid
      id="page-title-grid-root"
      item
      sm={7}
      md={6}>
      <Typography variant="h6" noWrap>
        {title}
      </Typography>
    </Grid>
  );
}

PageTitle.propTypes = {
  title: string.isRequired,
};

export function AppName({ onClick, appName }) {
  const matchesBigScreen = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <Grid
      id="app-name-grid-root"
      item
      sm={5}
      md={6}>
      {matchesBigScreen && (
        <Typography
          variant="h6"
          noWrap
          sx={{ cursor: 'pointer' }}
          onClick={onClick}>
          {appName}
        </Typography>
      )}
    </Grid>
  );
}

AppName.propTypes = {
  onClick: func.isRequired,
  appName: string.isRequired,
};
