import { LocalDate } from '@js-joda/core';

class DateValidationError extends Error {
  constructor(message) {
    super();
    this.message = message;
  }
}

const DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/; // yyyy-mm-dd

/**
 * Validates date from props.
 *
 * @param props {object}
 * @param props.match.params.date {string} Date string in format yyyy-mm-dd
 * @returns {boolean}
 */
export function isDateValid(props) {
  let isValid = true;

  try {
    if (!props || !props.match || !props.match.params || !props.match.params.date) {
      throw new DateValidationError('Date was not provided.');
    }

    const { date } = props.match.params;

    if (!date.match(DATE_REGEX)) {
      throw new DateValidationError('Date format is invalid. Expected format: \'yyyy-mm-dd\'.');
    }

    try {
      LocalDate.parse(date);
    } catch (e) {
      throw new DateValidationError('Date value is invalid. Cannot cast to LocalDate object.');
    }
  } catch (err) {
    if (process?.env?.NODE_ENV !== ('production' && 'test')) {
      console.error(err);
    }
    isValid = false;
  }

  return isValid;
}
