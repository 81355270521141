import { errorActionTypes } from '../../../actions/luncherActions/errorActions/errorActions';
import { orderActionTypes } from './orderActionTypes';

export const createPeriodOrderStart = () => ({
  type: orderActionTypes.CREATE_PERIOD_ORDER_START,
});

export const fetchPeriodOrdersStart = () => ({
  type: orderActionTypes.FETCH_PERIOD_ORDERS_START,
});

export const fetchOrdersSuccess = periodOrders => ({
  type: orderActionTypes.FETCH_PERIOD_ORDERS_SUCCESS,
  payload: periodOrders,
});

export const createPeriodOrderSuccess = periodOrder => ({
  type: orderActionTypes.CREATE_PERIOD_ORDER_SUCCESS,
  payload: periodOrder,
});

export const updatePeriodOrderSuccess = periodOrder => ({
  type: orderActionTypes.UPDATE_PERIOD_ORDER_SUCCESS,
  payload: periodOrder,
});

// ___DAILY_ORDER_______________________________________________________________________________________
export const setDraftDailyOrder = dailyOrder => ({
  type: orderActionTypes.SET_DRAFT_DAILY_ORDER,
  payload: dailyOrder,
});

export const updateDailyOrderSuccess = dailyOrder => ({
  type: orderActionTypes.UPDATE_DAILY_ORDER_SUCCESS,
  payload: dailyOrder,
});

export const periodOrderCreationError = error => {
  let errorData = error.response.data;
  let errorType;

  if (errorData.generalError) {
    errorType = orderActionTypes.CREATE_ORDER_ERROR;
    errorData = errorData.generalError;
  } else if (errorData.validationErrors) {
    errorType = orderActionTypes.VALIDATE_ORDER_ERROR;
  } else {
    errorType = errorActionTypes.SET_GENERAL_ERROR;
    errorData = '';
  }

  return {
    type: errorType,
    payload: errorData,
  };
};
