import { sideMenuActionTypes } from '../componentActions/sideMenuActionTypes';
import { GLOBALS } from '../../../constants';

const initialState = {
  isSideMenuOpen: JSON.parse(localStorage.getItem(GLOBALS.sideMenu.statusKey)) || false,
};

function saveSideMenuStateToLocalStorage(isSideMenuOpen) {
  localStorage.setItem(GLOBALS.sideMenu.statusKey, JSON.stringify(isSideMenuOpen));
}

export default function sideMenuReducer(state = initialState, action) {
  switch (action.type) {
    case sideMenuActionTypes.OPEN_SIDE_MENU:
      return {
        isSideMenuOpen: true,
      };

    case sideMenuActionTypes.CLOSE_SIDE_MENU:
      return {
        isSideMenuOpen: false,
      };

    case sideMenuActionTypes.SAVE_SIDE_MENU_STATE:
      saveSideMenuStateToLocalStorage(action.payload);

      return {
        ...state,
      };

    default:
      return state;
  }
}
