import React from 'react';
import { bool } from 'prop-types';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styles = {
  noDailyOrder: {
    marginTop: 3,
    cursor: 'default',
  },
};

export function NoDailyOrderMessage({ isHoliday }) {
  const { t: localize } = useTranslation();

  return (
    <Typography
      variant="subtitle1"
      color="textSecondary"
      align="center"
      sx={styles.noDailyOrder}>
      {localize(isHoliday ? 'order-day-is-holiday' : 'nothing-ordered')}
    </Typography>
  );
}

NoDailyOrderMessage.propTypes = {
  // TODO move isHoliday calc to the container
  isHoliday: bool,
};

NoDailyOrderMessage.defaultProps = {
  isHoliday: false,
};
