export class MenuReportItemDto {
  /**
   * @type {MenuReportEntry[]}
   */
  order;
  user;

}

export class MenuReportItem {
  /**
   * @type {MenuReportEntry[]}
   */
  order;
  user;
  index;

  constructor(item) {
    Object.assign(this, item);
  }
}
