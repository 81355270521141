import React from 'react';
import { number } from 'prop-types';
import { Avatar, Box, CardHeader, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { userType } from '../../../../../../constants';

const styles = {
  avatar: {
    backgroundColor: grey[100],
    margin: '0 0.5rem 0 0',
  },
  userName: {
    fontWeight: 600,
  },
  index: {
    fontWeight: 900,
    fontSize: '20px'
  },
  cardHeader: {
    paddingBottom: 1,
  },
  userDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }

};

export default function UserDailyOrderHeader(props) {
  const { userProfile, index } = props;

  const UserAvatar = (
    <Avatar
      aria-label="user-avatar"
      sx={styles.avatar}
      src={userProfile.photo} />
  );
  const UserDetails = (
    <Box sx={styles.userDetailsContainer}>
      <Typography sx={styles.userName}>
        {userProfile.name}
      </Typography>
      <Typography sx={styles.index}>
        {index}
      </Typography>
    </Box>
  );

  return (
    <CardHeader
      avatar={UserAvatar}
      title={UserDetails}
      sx={styles.cardHeader} />
  );
}

UserDailyOrderHeader.propTypes = {
  userProfile: userType.isRequired,
  index: number.isRequired,
};
