export const errorActionTypes = {
  SET_GENERAL_ERROR: '[ERROR] Set general error',
  CLEAR_GENERAL_ERROR: '[ERROR] Resolve general error',

  SET_LOG_IN_ERROR: '[LOGIN] User is not logged in',
  CLEAR_LOG_IN_ERROR: '[LOGIN] Reset to initial login state',
};

export const setGeneralError = error => ({
  type: errorActionTypes.SET_GENERAL_ERROR,
  payload: error,
});

export const clearGeneralError = () => ({
  type: errorActionTypes.CLEAR_GENERAL_ERROR,
});

export const setLoginError = error => ({
  type: errorActionTypes.SET_LOG_IN_ERROR,
  payload: error,
});

export const clearLoginError = () => ({
  type: errorActionTypes.CLEAR_LOG_IN_ERROR,
});
