import React from 'react';

import { IconButton, SvgIcon } from '@mui/material';

import { TooltipApplier } from '../../../Tooltip';

import EveryDayImageSvg from '../../../../images/recurring24.svg';
import GLOBALS from '../../../../constants/globals';

const styles = {
  iconButton: {
    marginLeft: '0.5em',
    display: 'flex',
    alignSelf: 'flex-start',
  },
  icon: {
    color: theme => theme.palette.primary.light,
    height: '1.2rem',
    width: '1.2rem',
  },
};

export default function EverydayDishTooltip() {
  return (
    <TooltipApplier
      title="label.everyday-dish"
      openTimeout={1000}
      closeTimeout={2000}
      shouldClose>
      <IconButton
        size="small"
        sx={styles.iconButton}>
        <SvgIcon
          sx={styles.icon}
          component={EveryDayImageSvg}
          viewBox={GLOBALS.images.viewBox.everyDay} />
      </IconButton>
    </TooltipApplier>
  );
}
