import { sortPeriodOrdersByLastDate } from './orderUtils';
import { orderActionTypes } from './orderActions/orderActionTypes';
import {
  _insertNewPeriodOrder,
  _insertUpdatedDailyOrderIntoPeriodOrder,
  _insertUpdatedPeriodOrder
} from './ordersReducerUtils';

/**
 * @namespace PeriodOrderState
 * @property {PeriodOrder || null} PeriodOrderState.data
 * @property {PeriodOrder[]} PeriodOrderState.dataArr
 * @property {boolean} PeriodOrderState.modified
 * @property {boolean} PeriodOrderState.isLoading
 */
const initialState = {
  dataArr: [],
  isLoading: false,
  modified: false,
};

/**
 * @param state {PeriodOrderState}
 * @param action {Object}
 */
export default function ordersReducer(state = initialState, action) {
  switch (action.type) {
    // ___IS_LOADING_______________________________________________________________________________________
    case orderActionTypes.FETCH_PERIOD_ORDERS_START:
    case orderActionTypes.CREATE_PERIOD_ORDER_START:
      return {
        ...state,
        isLoading: true,
      };

    // ___PERIOD_ORDER_______________________________________________________________________________________

    case orderActionTypes.FETCH_PERIOD_ORDERS_SUCCESS:
      return {
        ...state,
        // TODO don't override the data in the strore, but upsert it
        dataArr: sortPeriodOrdersByLastDate(action.payload),
        isLoading: false,
      };

    case orderActionTypes.CREATE_PERIOD_ORDER_SUCCESS:
      return {
        ...state,
        dataArr: _insertNewPeriodOrder(state, action.payload),
        modified: false,
        isLoading: false,
      };

    case orderActionTypes.UPDATE_PERIOD_ORDER_SUCCESS:
      return {
        ...state,
        dataArr: _insertUpdatedPeriodOrder(state, action.payload),
        modified: false,
      };

    // ___DAILY_ORDER_______________________________________________________________________________________

    case orderActionTypes.SET_DRAFT_DAILY_ORDER:
      return {
        ...state,
        dataArr: _insertUpdatedDailyOrderIntoPeriodOrder(state, action.payload),
        modified: true,
      };

    case orderActionTypes.UPDATE_DAILY_ORDER_SUCCESS:
      return {
        ...state,
        modified: true,
        dataArr: _insertUpdatedDailyOrderIntoPeriodOrder(state, action.payload),
      };

    // ___ERRORS_______________________________________________________________________________________
    case orderActionTypes.CREATE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case orderActionTypes.VALIDATE_ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
        modified: false,
      };

    default:
      return state;
  }
}
