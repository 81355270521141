import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createPeriodOrder, selectOrderForActiveDate } from '../../../../../store/order';
import { isMenuPublished } from '../../../../../store/menu';
import { selectIsNoGeneralError } from '../../../../../utils/selectors';

import { periodMenuType } from '../../../../../constants';
import { getDateRangeFromEntity } from '../../../../../utils/dates';

export function CreatePeriodOrder(props) {
  const { menu } = props;
  const dispatch = useDispatch();

  const isNoError = useSelector(selectIsNoGeneralError);
  const orderMatchingActiveDate = useSelector(selectOrderForActiveDate);

  useEffect(() => {
    if (isNoError && isMenuPublished(menu) && !orderMatchingActiveDate) {
      const dateRange = getDateRangeFromEntity(menu);
      dispatch(createPeriodOrder(dateRange));
    }
  }, [menu, isNoError, dispatch, orderMatchingActiveDate]);

  return null;
}

CreatePeriodOrder.propTypes = {
  menu: periodMenuType.isRequired,
};

