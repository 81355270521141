import { MenuItem, MenuItemDto } from '../Menu';

export class OrderItemDto {
  id;
  count;
  /**
   * @type {MenuItemDto}
   */
  menuItem;

  constructor(orderItem) {
    Object.assign(this, {
      ...orderItem,
      menuItem: new MenuItemDto(orderItem.menuItem),
    });
  }
}

/**
 * @namespace OrderItem
 * @property {number} OrderItem.id
 * @property {number} OrderItem.count
 * @property {MenuItem} OrderItem.menuItem
 */
export class OrderItem {
  id;
  count;
  /**
   * @type {MenuItem}
   */
  menuItem;

  constructor(orderItem) {
    Object.assign(this, {
      ...orderItem,
      menuItem: new MenuItem(orderItem.menuItem),
    });
  }
}
