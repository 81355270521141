import React from 'react';

import { MENU_ACTIONS_DIALOGS_CONFIG } from './MENU_ACTIONS_DIALOGS_CONFIG';
import { MenuAction } from '../common';

export function DeleteMenuAction() {
  return (
    <MenuAction {...MENU_ACTIONS_DIALOGS_CONFIG.deleteMenu} />
  );
}

