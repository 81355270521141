import React from 'react';
import { NoContentMessage } from '../../components/Messages';
import { withAlternative } from './withAlternative';

//  TODO move to alternativeConditions
const noReportAvailable = reportKey => props => !props[reportKey];
const noReportContentAvailable = reportKey => props => Object.entries(props[reportKey]).length === 0;

function NoMenuMessage() {
  return (<NoContentMessage message="no-menu-to-generate-report-from" />);
}

function NoOrdersMessage() {
  return (<NoContentMessage message="no-orders-to-generate-report-from" />);
}

export const withReportErrors = (Component, reportPropKey) => props => {
  const conditions = new Map([
                               [noReportAvailable(reportPropKey), NoMenuMessage],
                               [noReportContentAvailable(reportPropKey), NoOrdersMessage],
                             ]);

  return withAlternative(conditions)(Component)(props);
};

export default withReportErrors;
