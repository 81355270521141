import { connect } from 'react-redux';

import FoodlordRouter from './FoodlordRouter';
import { hasFoodlordRole } from '../../store/auth';

const mapStateToProps = state => ({
  isFoodlord: hasFoodlordRole(state),
});

export default connect(mapStateToProps)(FoodlordRouter);
