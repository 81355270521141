import { connect } from 'react-redux';

import DishForm from '../DishForm';

import { selectCategories } from '../../../../utils/selectors/categorySelectors';
import { findCategoryById } from '../../../../utils/entitiesUtils/categoriesUtils';

const mapStateToProps = state => ({
  categories: selectCategories(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  close: () => ownProps.history.push(ownProps.onCloseRedirectUrl),
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  getInitialValues: dish => {
    const { categories } = propsFromState;

    return ({
      ...dish,
      category: dish.category ? findCategoryById(categories, dish.category.id) : '',
      price: dish.price || '',
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(
  DishForm,
);
