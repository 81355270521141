export class Category {
  id;
  name;
  position;
  icon;

  constructor(category) {
    // TODO add validation
    Object.assign(this, category);
  }
}
