import { connect } from 'react-redux';
import EditableDishItem from './EditableDishItem';
import { deleteDish } from '../../../../actions/luncherActions/dishesActions/dishesActionsDispatcher';
import { generateUrlWithCategoryFilter } from '../../../../utils/UrlUtils/UrlUtils';
import { foodlordPath } from '../../../../routes/foodlord/foodlordRoutes';

const mapDispatchToProps = (dispatch, props) => ({
  deleteDish: () => dispatch(deleteDish(props.dish.id)),
  editButtonUrl: generateUrlWithCategoryFilter(
    props.selectedCategoryId,
    `${foodlordPath.EDIT_DISH}/${props.dish.id}`,
  ),
});

export default connect(null, mapDispatchToProps)(EditableDishItem);
