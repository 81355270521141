import { connect } from 'react-redux';
import SelectableDishItem from './SelectableDishItem';
import {
  getOrderItemForMatchingMenuItem,
  selectActivePeriodOrderIsOrdered,
  selectDailyOrderForActiveDate,
  updateDailyOrder,
} from '../../../../../../../store/order';
import { isPeriodMenuPurchased } from '../../../../../../../store/menu';

const mapStateToProps = (state, ownProps) => {
  const dailyOrder = selectDailyOrderForActiveDate(state);
  const orderItem = getOrderItemForMatchingMenuItem(dailyOrder, ownProps.menuItem);

  return {
    dailyOrder,
    isDishSelected: Boolean(orderItem),
    isDisabled: isPeriodMenuPurchased(state),
    ordered: selectActivePeriodOrderIsOrdered(state),
    count: orderItem ? orderItem.count : 0,
  };
};

function deselectDish(dailyOrder, menuItem) {
  return {
    ...dailyOrder,
    orderItems: dailyOrder.orderItems.filter(item => item.menuItem.id !== menuItem.id),
  };
}

const filterOrderItemsFromSameCategory = newItem => orderItem => {
  return orderItem.menuItem.food.category.id !== newItem.food.category.id;
};

const isCategoryIdInBlockedCategories = (firstItem, secondItem) => !firstItem.food.blockedCategories
                                                                             .map(category => category.id)
                                                                             .includes(secondItem.food.category.id);

const filterOrderItemsFromBlockedCategories = newItem => orderItem => (
  isCategoryIdInBlockedCategories(orderItem.menuItem, newItem)
  && isCategoryIdInBlockedCategories(newItem, orderItem.menuItem)
);

function selectDish(dailyOrder, newItem) {
  return {
    ...dailyOrder,
    orderItems: [
      ...dailyOrder.orderItems
                   .filter(filterOrderItemsFromSameCategory(newItem))
                   .filter(filterOrderItemsFromBlockedCategories(newItem)),
      {
        count: 1,
        menuItem: newItem,
      },
    ],
  };
}

function updateOrderItemCount(orderItems, menuItem, updatedCount) {
  return orderItems.map(orderItem => {
    return (orderItem.menuItem.id === menuItem.id) ? (
      {
        ...orderItem,
        count: updatedCount,
      }
    ) : orderItem;
  });
}

function decreaseDishCount(dailyOrder, menuItem, prevCount) {
  return {
    ...dailyOrder,
    orderItems: updateOrderItemCount(dailyOrder.orderItems, menuItem, prevCount - 1),
  };
}

function increaseDishCount(dailyOrder, menuItem, prevCount) {
  return {
    ...dailyOrder,
    orderItems: updateOrderItemCount(dailyOrder.orderItems, menuItem, prevCount + 1),
  };
}

const disablable = fn => (disabled, ...args) => (disabled || fn.apply(this, args));

const mapDispatchToProps = dispatch => ({
  handleDishSelection: (menuItem, isDishSelected, dailyOrder, ordered) => {
    const updatedDailyOrder = isDishSelected
                              ? deselectDish(dailyOrder, menuItem)
                              : selectDish(dailyOrder, menuItem);

    dispatch(updateDailyOrder(updatedDailyOrder, ordered));
  },

  handleDecreaseDishCounter: (menuItem, prevCount, dailyOrder, ordered) => {
    if (prevCount) {
      const updatedDailyOrder = prevCount === 1
                                ? deselectDish(dailyOrder, menuItem)
                                : decreaseDishCount(dailyOrder, menuItem, prevCount);

      dispatch(updateDailyOrder(updatedDailyOrder, ordered));
    }
  },

  handleIncreaseDishCounter: (menuItem, prevCount, dailyOrder, ordered) => {
    const updatedDailyOrder = prevCount === 0
                              ? selectDish(dailyOrder, menuItem)
                              : increaseDishCount(dailyOrder, menuItem, prevCount);

    dispatch(updateDailyOrder(updatedDailyOrder, ordered));
  },
});

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
  ...propsFromState,
  ...propsFromDispatch,
  ...ownProps,
  handleDishSelection: () => (disablable(propsFromDispatch.handleDishSelection)(
    propsFromState.isDisabled,
    ownProps.menuItem,
    propsFromState.isDishSelected,
    propsFromState.dailyOrder,
    propsFromState.ordered,
  )),
  handleDecreaseDishCounter: () => (disablable(propsFromDispatch.handleDecreaseDishCounter)(
    propsFromState.isDisabled,
    ownProps.menuItem,
    propsFromState.count,
    propsFromState.dailyOrder,
    propsFromState.ordered,
  )),
  handleIncreaseDishCounter: () => (disablable(propsFromDispatch.handleIncreaseDishCounter)(
    propsFromState.isDisabled,
    ownProps.menuItem,
    propsFromState.count,
    propsFromState.dailyOrder,
    propsFromState.ordered,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(SelectableDishItem);
