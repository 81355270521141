import React from 'react';
import { Router } from 'react-router-dom';

import { Layout } from './components/Layout';
import PageRoutes from './routes/Router';
import history from './history';

import './app.scss';

function App() {
  return (
    <Router history={history}>
      <Layout>
        <PageRoutes />
      </Layout>
    </Router>
  );
}

export default App;
