import { styled } from '@mui/material/styles';
import { Badge, Box } from '@mui/material';

export const StyledRootContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  cursor: 'default',
  fontVariant: 'all-small-caps',
  display: 'flex',
  alignItems: 'center',
}));

export const StyledIconFlagDecoration = styled(Box)(() => ({
  marginRight: '0.2em',
  width: '60px',
  height: '0',
  borderRight: '15px solid transparent',
  borderLeft: '15px solid #ff6800',
  borderTop: `16px solid #ff6800 `,
  borderBottom: '14px solid #ff6800 ',
}));

export const StyledIconFlagContainer = styled(Box)(() => ({
  position: 'relative',
  top: '-12px',
  left: '-12px',
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: theme.spacing(-8),
    top: theme.spacing(3.25),
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
