import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateCategory } from '../../../../actions/luncherActions/categoriesActions/categoriesActionsDispatcher';
import CategoryFormContainer from './CategoryFormContainer';

const mapStateToProps = (state, ownProps) => ({
  category: state.categories.data.find(
    category => category.id === parseInt(ownProps.match.params.id, 10),
  ),
  errors: state.categories.errors,
});

const mapDispatchToProps = {
  save: updateCategory,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    CategoryFormContainer,
  ),
);
