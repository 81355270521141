import {
  addCategorySuccess,
  deleteCategorySuccess,
  fetchCategoriesStart,
  fetchCategoriesSuccess,
  updateCategorySuccess,
} from './categoriesActions';

import axiosInstance from '../../../axiosInstance';
import { setGeneralError } from '../errorActions/errorActions';
import { setSuccessStatus } from '../successActions/successActions';

export const fetchCategories = () => dispatch => {
  dispatch(fetchCategoriesStart());
  return axiosInstance.get('/categories')
                      .then(response => response && dispatch(fetchCategoriesSuccess(response.data)))
                      .catch(error => dispatch(setGeneralError(error)));
};

export const addCategory = category => dispatch => axiosInstance.post('/categories', category)
                                                                .then(response => dispatch(
                                                                  addCategorySuccess(response.data)))
                                                                .then(() => dispatch(
                                                                  setSuccessStatus('category-created-successfully')))
                                                                .catch(error => {
                                                                  throw error.response.data;
                                                                });

export const updateCategory = category => dispatch => axiosInstance.put(`/categories/${category.id}`, category)
                                                                   .then(response => dispatch(
                                                                     updateCategorySuccess(response.data)))
                                                                   .then(() => dispatch(
                                                                     setSuccessStatus('category-updated-successfully')))
                                                                   .catch(error => {
                                                                     throw error.response.data;
                                                                   });

export const deleteCategory = id => dispatch => axiosInstance.delete(`/categories/${id}`)
                                                             .then(response => response && dispatch(
                                                               deleteCategorySuccess(id)))
                                                             .then(() => dispatch(
                                                               setSuccessStatus('category-deleted-successfully')))
                                                             .catch(error => dispatch(setGeneralError(error)));
