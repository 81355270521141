import React from 'react';
import { arrayOf, func, number } from 'prop-types';

import { FormControl, MenuItem, Select } from '@mui/material';

import { categoryType } from '../../../constants/types';

const styles = {
  selectInput: {
    alignSelf: 'flex-end',
    width: '20vh',
  },
};

function CategoryFilter(props) {
  return (
    <FormControl variant="standard" sx={styles.selectInput}>
      <Select
        value={props.selectedCategoryId}
        onChange={event => props.onCategorySelect(event.target.value)}>
        {
          props
            .categories
            .map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))
        }
      </Select>
    </FormControl>
  );
}

CategoryFilter.propTypes = {
  categories: arrayOf(categoryType).isRequired,
  selectedCategoryId: number.isRequired,
  onCategorySelect: func.isRequired,
};

export default CategoryFilter;
