import { compareObjectsByNameField } from '../../utils/comparators';
import { getReducedDishesNames } from './reportUtils';
import { selectMenuReportWithLoggedUserOnTop } from './reportSelectors';
import { MenuReportPrintItem } from '../../models/internal/Report';

const formDishes = order => {
  return Object
    .values(order)
    .map(userOrder => (userOrder.dishes.length ? getReducedDishesNames(userOrder.dishes) : 'nothing-ordered'));
};

export const selectMenuPrintReportData = state => {
  const menuReport = selectMenuReportWithLoggedUserOnTop(state);

  const reportData = {};

  Object
    .entries(menuReport)
    .forEach(([date, report]) => {
      reportData[date] = report
        .map(({ order, user, index }) => {
          return new MenuReportPrintItem({
                                           name: user.name,
                                           dishes: formDishes(order),
                                           index,
                                         });
        })
        .sort(compareObjectsByNameField);
    });

  return reportData;
};
