import { isConnectionError } from './errorSelector';

export const isAnyOfEntitiesLoading = (state, ...entityNames) => {
  const entitiesStates = Object.entries(state)
                               .filter(([reducerName]) => entityNames.includes(reducerName))
                               .map(([, state]) => state);

  return entitiesStates.some(entityState => entityState.isLoading);
};

export const isLoadingEntitiesFailedDueToConnectionError = (state, ...entityNames) => {
  return isConnectionError(state) && isAnyOfEntitiesLoading(state, ...entityNames);
};
