import { connect } from 'react-redux';
import AuthorizationController from './AuthorizationController';
import { clearGeneralError } from '../../actions/luncherActions/errorActions/errorActions';
import { fetchUserProfile, selectIsProfileLoading, selectProfile } from '../../store/auth';

const mapStateToProps = state => ({
  userProfile: selectProfile(state),
  isLoading: selectIsProfileLoading(state),
  // isLoadingFailed: isConnectionError(state), // TODO add loading failed only for authorization store
});

const mapDispatchToProps = dispatch => {
  const downloadUserInfo = () => {
    dispatch(clearGeneralError());
    dispatch(fetchUserProfile());
  };

  return {
    loadUserProfile: downloadUserInfo,
    load: downloadUserInfo,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationController);
