// import EmptyPage from '../../components/pages/Errors/EmptyPage';

import { DishesPage as FoodlordDishesPage } from '../../components/pages/Foodlord/Dishes';
import { LastPeriodMenuPage, PeriodMenuPage } from '../../components/pages/Foodlord/PeriodMenu';
import { CategoriesPage as FoodlordCategoriesPage } from '../../components/pages/Foodlord/Categories';

import LastReportContainer from '../../components/pages/Common/LastReportContainer';
import { VendorReport as VendorReportPage } from '../../components/pages/Foodlord/Reports/VendorReport';
// import { SlowpokeReportPage } from '../../components/pages/Foodlord/Reports/SlowpokeReport';
// import SlowpokeReportIconSvg from '../../images/menuIcons/slowpoke-report-figma.svg';
import VendorReportIconSvg from '../../images/menuIcons/vendor-report-figma.svg';
// import VendorsIconSvg from '../../images/menuIcons/vendors-figma.svg';
import CategoriesIconSvg from '../../images/menuIcons/categories-figma.svg';
import DishesIconSvg from '../../images/menuIcons/dishes-figma.svg';
import PeriodMenuIconSvg from '../../images/menuIcons/menu-figma.svg';

import { foodlordRoutes } from '../foodlord/foodlordRoutes';

const VendorReportPagesConfig = {
  [foodlordRoutes.VENDOR_REPORT]: {
    link: foodlordRoutes.VENDOR_REPORT,
    component: VendorReportPage,
    title: 'routes.vendor-report',
    icon: null,
  },
  [foodlordRoutes.LAST_VENDOR_REPORT]: {
    link: foodlordRoutes.LAST_VENDOR_REPORT,
    component: LastReportContainer,
    title: 'routes.vendor-report',
    icon: VendorReportIconSvg,
    displayInSideMenuReports: true,
  },
};

// const SlowpokeReportPagesConfig = {
//   [foodlordRoutes.SLOWPOKE_REPORT]: {
//     link: foodlordRoutes.SLOWPOKE_REPORT,
//     component: SlowpokeReportPage,
//     title: 'routes.slowpoke-report',
//     icon: null,
//   },
//   [foodlordRoutes.LAST_SLOWPOKE_REPORT]: {
//     link: foodlordRoutes.LAST_SLOWPOKE_REPORT,
//     component: LastReportContainer,
//     title: 'routes.slowpoke-report',
//     icon: SlowpokeReportIconSvg,
//     displayInSideMenuReports: true,
//   },
// };

const MenuPagesConfig = {
  [foodlordRoutes.MENU]: {
    link: foodlordRoutes.MENU,
    component: PeriodMenuPage,
    title: 'routes.menu',
    icon: null,
  },
  [foodlordRoutes.LAST_MENU]: {
    link: foodlordRoutes.LAST_MENU,
    component: LastPeriodMenuPage,
    title: 'routes.menu',
    icon: PeriodMenuIconSvg,
    displayInSideMenu: true,
  },
};

const FoodlordPagesConfig = {
  [foodlordRoutes.CATEGORIES_LIST]: {
    link: foodlordRoutes.CATEGORIES_LIST,
    component: FoodlordCategoriesPage,
    title: 'routes.categories',
    icon: CategoriesIconSvg,
    displayInSideMenu: true,
  },
  [foodlordRoutes.DISH_LIST]: {
    link: foodlordRoutes.DISH_LIST,
    component: FoodlordDishesPage,
    title: 'routes.dishes',
    icon: DishesIconSvg,
    displayInSideMenu: true,
  },
  // [foodlordRoutes.VENDOR_LIST]: {
  //   link: foodlordRoutes.VENDOR_LIST,
  //   component: EmptyPage,
  //   title: 'routes.vendors',
  //   icon: VendorsIconSvg,
  //   displayInSideMenu: true,
  // },
  ...MenuPagesConfig,
  ...VendorReportPagesConfig,
  // ...SlowpokeReportPagesConfig,
};

export default FoodlordPagesConfig;
