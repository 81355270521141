import React, { useState } from 'react';
import { func } from 'prop-types';

import { Form } from '../Common/Form';
import { FormDialog } from '../Common/FormDialog';
import CategoryFormContent from './CategoryFormContent';

import { categoryType } from '../../../constants/types';

// HTML form submit works when same ID is provided to <form> and <SubmitButton>
const FORM_ID = 'category-form';

function CategoryForm(props) {
  const {
    save, close, category, getInitialValues,
  } = props;

  const [isFormInvalid, setFormValidationStatus] = useState(true);

  return (
    <FormDialog
      title="label.category"
      formIdToSubmit={FORM_ID}
      onClose={close}
      isSubmitDisabled={isFormInvalid}>

      <Form
        FormContent={CategoryFormContent}
        save={save}
        close={close}
        initialValues={getInitialValues(category)}
        formContentProps={{
          formId: FORM_ID,
          setFormValidationStatus,
        }} />
    </FormDialog>
  );
}

CategoryForm.propTypes = {
  getInitialValues: func.isRequired,
  save: func.isRequired,
  close: func.isRequired,
  category: categoryType.isRequired,
};

export default CategoryForm;
