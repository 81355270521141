import React from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Icon, Typography } from '@mui/material';
import { orange } from '@mui/material/colors';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';

import { Divider } from '../../../../../../../Divider';

const styles = {
  statusTypography: {
    fontWeight: 400, width: 'inherit', fontSize: '1.65rem', color: orange[400],
  },
  statusIcon: {
    mb: 0.8, mr: 0.5, fontSize: '1.75rem', color: 'primaryLight.main',
  },
  statusContainer: {
    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
  },
  statusTopDivider: {
    height: '4px', pt: 2, pb: 1,
  },
  transparentStatusBottomDivider: {
    opacity: 0, mt: -4
  },
  statusDividerLabelTypography: {
    mt: -0.75
  }
};

const PERIOD_MENU_STATUS_TO_ICON_DICTIONARY = {
  DRAFT: ReportOutlinedIcon,
  PUBLISHED: DoneIcon,
  PURCHASED: DoneAllIcon,
};

export function MenuStatusWithIconAndLabel({ status, sx }) {
  const { t: localize } = useTranslation();
  const StatusIcon = PERIOD_MENU_STATUS_TO_ICON_DICTIONARY[status];

  return (
    <Box sx={{ ...styles.statusContainer, ...sx }}>
      <Icon sx={{ ...styles.statusIcon, ...sx }}>
        <StatusIcon fontSize="inherit" />
      </Icon>
      <Typography sx={styles.statusTypography}>
        {localize(`menu.statuses.${status}`)}
      </Typography>
    </Box>
  );
}

MenuStatusWithIconAndLabel.propTypes = { status: string.isRequired, sx: shape({}), };
MenuStatusWithIconAndLabel.defaultProps = { sx: {}, };

// ________________________________________________________________________________

export function StatusSubheaderUpperDivider() {
  const { t: localize } = useTranslation();

  return (
    <Divider sx={styles.statusTopDivider} textAlign="left">
      <Typography sx={styles.statusDividerLabelTypography} fontSize="x-small">
        {localize('menu.components.PeriodMenu.status')}
      </Typography>
    </Divider>
  );
}

export function TransparentStatusBottomDivider({ status }) {
  return (
    <Divider>
      <MenuStatusWithIconAndLabel status={status} sx={styles.transparentStatusBottomDivider} />
    </Divider>
  );
}

TransparentStatusBottomDivider.propTypes = { status: string.isRequired, };
