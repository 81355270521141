import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';

import { formatDateForWeekPicker } from '../../utils/dates';
import { dateType } from '../../constants/types';

const getLongDate = date => formatDateForWeekPicker(date, 'long');
const getNumberedDate = date => formatDateForWeekPicker(date, 'number');
const getFormattedDate = (date, matchesBigViewport) => (matchesBigViewport ? getLongDate(date) : getNumberedDate(date));

export function PickedDateRangeComponent(props) {
  const { firstDate, lastDate } = props;
  const matchesBigViewport = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const formattedFirstDate = getFormattedDate(firstDate, matchesBigViewport);
  const formattedLastDate = getFormattedDate(lastDate, matchesBigViewport);

  return (
    <Typography variant="h5">
      {`${(formattedFirstDate)} - ${(formattedLastDate)}`}
    </Typography>
  );
}

PickedDateRangeComponent.propTypes = {
  firstDate: dateType.isRequired,
  lastDate: dateType.isRequired,
};
