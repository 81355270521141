import { arrayOf, number, shape, string } from 'prop-types';
import { dishType, userType } from './types';

export const categoryOrderType = shape({
                                         categoryName: string.isRequired,
                                         categoryIcon: string,
                                         dishName: string.isRequired,
                                       });

export const userOrderedDishesType = arrayOf(categoryOrderType);

const userOrderType = shape({
                              category: string.isRequired,
                              dishes: arrayOf(dishType).isRequired,
                            });

const menuReportItemType = shape({
                                   order: arrayOf(userOrderType).isRequired,
                                   user: userType.isRequired,
                                   index: number
                                 });

export const menuReportType = shape({
                                      [string]: arrayOf(menuReportItemType),
                                    });

export const usersDailyOrdersType = arrayOf(menuReportItemType);

export const menuReportPrintItemType = shape({
                                               name: string,
                                               dishes: arrayOf(string),
                                               index: number,
                                             });

export const menuReportPrintType = shape({
                                           [string]: arrayOf(menuReportPrintItemType),
                                         });

export const singleStickerType = shape({
                                         dishName: string.isRequired,
                                         customerName: string.isRequired,
                                         date: string.isRequired,
                                         category: string.isRequired,
                                       });

export const stickersReportPrintType = arrayOf(arrayOf(singleStickerType).isRequired);
