import { connect } from 'react-redux';

import { NoDailyMenuMessage, NoPeriodMenuMessage } from '../../../../../Messages';

import { selectLastDraftPeriodMenu } from '../../../../../../store/menu';
import { noMenu, withAlternative } from '../../../../../../utils/HOCs';

const mapStateToProps = state => ({
  menu: selectLastDraftPeriodMenu(state),
});

export default connect(mapStateToProps)(
  withAlternative(noMenu, NoPeriodMenuMessage)(
    NoDailyMenuMessage
  )
);
