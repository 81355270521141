const commonRouteMask = ['/report/menu'];

const commonPath = {
  MENU_REPORT: '/report/menu',
};

const commonRoutes = {
  MENU_REPORT: `${commonPath.MENU_REPORT}/:date`,
  LAST_MENU_REPORT: `${commonPath.MENU_REPORT}`,
};

export {
  commonPath,
  commonRoutes,
  commonRouteMask,
};
