import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

function getInnerHeight() {
  return window.innerHeight;
}

export const OrderGridRootContainer = styled(Grid)(({ theme }) => {
  const innerHeight = getInnerHeight();
  return ({
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxHeight: `${innerHeight - 110}px`,
      flexWrap: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
      height: `calc(100vh - 122px)`,
    },
    flexDirection: 'row',
    height: '100vh',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexGrow: 1,
    minHeight: 0,
  });
});

export const DatePickerOrderGridContainer = styled(Grid)(({ theme }) => {
  const innerHeight = getInnerHeight();
  return ({
    [theme.breakpoints.down('md')]: {
      maxHeight: `${innerHeight - 180}px`,
    },
    height: 'inherit'
  });
});

export const OrderBillGridContainer = styled(Grid)(({ theme }) => {
  return ({
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
      maxHeight: '60px',
      boxSizing: 'border-box',
    },
    height: 'inherit',
  });
});
