import { alpha, Autocomplete, Button, InputBase, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeConstants } from '../../../constants';

export const autosuggestStylesAndComponents = {
  item: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  actionZone: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '40px',
  }
};

export const StyledOpenPopperButton = styled(Button)(({ theme }) => ({
    width: 'fit-content',
    textAlign: 'left',
    paddingBottom: theme.spacing(1),
    color: theme.palette.primaryLight?.main || themeConstants.primaryLight.main,
    fontWeight: 600,
    '&:hover,&:focus': {
      color: theme.palette.primaryLighter?.main || themeConstants.primaryLight.main,
      backgroundColor: 'transparent',
    },
    '& span': {
      width: '100%',
    },
  }
));

export const StyledAutosuggestPopper = styled(Popper)(({ theme }) => ({
  width: '90%',
  maxWidth: theme.spacing(100),
  border: '1px solid rgba(27,31,35,.15)',
  boxShadow: '0 3px 12px rgba(27,31,35,.15)',
  borderRadius: '3px',
  zIndex: 1,
  fontSize: '13px',
  color: '#586069',
  backgroundColor: '#f6f8fa',
}));

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    margin: 0,
  },
  '& .MuiAutocomplete-option': {
    minHeight: 'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(1),
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  '& .MuiAutocomplete-popperDisablePortal': {
    position: 'relative',
  }
}));

export const StyledAutocompleteInputBase = styled(InputBase)(({ theme }) => ({
  padding: '10px',
  width: '100%',
  borderBottom: '1px solid #dfe2e5',
  '& input': {
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: '1px solid #ced4da',
    fontSize: '14px',
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
