import { isMenuCompleted, isMenuPublished } from '../../../store/menu';

export const noMenu = props => !props.menu;

export const menuIsNotCreated = props => !props.isMenuCreated;

export const hasNoCategories = ({ categories }) => !categories.length;

export const noMenuAvailable = props => !props.menu || !isMenuCompleted(props.menu);

export const noOrder = props => !props.order;

const _isPublishedMenuAvailable = props => props.menu && isMenuPublished(props.menu);

export const noOrderButWithPublishedMenuAvailable = props => noOrder(props) && _isPublishedMenuAvailable(props);

export const noOrderItemsInDailyOrder = props => !Boolean(props.dailyOrder.orderItems.length);

export const noOrdersAvailable = ({ usersDailyOrders }) => usersDailyOrders.length === 0;

export const noDataToDisplay = ({ data }) => !data;
