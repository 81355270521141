import React from 'react';

import { Grid } from '@mui/material';

export function MessageText(props) {
  return (
    <Grid item>
      <div className="message">{props.children}</div>
    </Grid>
  );
}

export default MessageText;
